/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WelcomeModal, Translate } from 'components';
import { fromResource } from 'store/selectors';
import { currentDate, convertToDayJsObject } from 'utils/dateTimeUtils';
import { useHistory } from 'react-router-dom';

function WelcomeMessageModalContainer({ profile, resourceAvailability }) {
  const history = useHistory();

  const showWelcomeModal = history.location.state?.showWelcomeModal
    ? history.location.state.showWelcomeModal
    : false;

  const [checked, setChecked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [scheduleMessage, setScheduleMessage] = useState(null);

  const checkAvailablity = () => {
    const availabilities = resourceAvailability.filter(
      item =>
        currentDate().isBetween(
          convertToDayJsObject(item.DatetimeFrom),
          convertToDayJsObject(item.DatetimeTo),
          'minute',
        ) && item.AvailabilityType === 2,
    );

    const occupiedAvailabilities = resourceAvailability.filter(
      item =>
        currentDate().isBetween(
          convertToDayJsObject(item.DatetimeFrom),
          convertToDayJsObject(item.DatetimeTo),
          'minute',
        ) && item.AvailabilityType !== 2,
    );

    if (occupiedAvailabilities.length > 0) {
      setScheduleMessage(
        Translate({ content: 'welcome.unavailableBasedOnSchedule' }),
      );
    } else if (availabilities.length > 0) {
      setScheduleMessage(
        Translate({ content: 'welcome.availableBasedOnSchedule' }),
      );
    } else {
      setScheduleMessage(
        Translate({ content: 'welcome.unavailableBasedOnSchedule' }),
      );
    }
  };

  useEffect(() => {
    checkAvailablity();
  }, [resourceAvailability]);

  useEffect(() => {
    const LocalMessageStatus = JSON.parse(
      localStorage.getItem('LOCAL_WELCOME_MESSAGE_HIDE'),
    );
    if (!LocalMessageStatus && showWelcomeModal) {
      setVisible(true);
    }
  }, []);

  const redirectToDefaultAvailablity = () => {
    window.location.href = '/settings#default-availability';
    // history.push({
    //   pathname: '/settings#default-availability',
    // });
  };

  const clearHandler = () => {
    localStorage.setItem('LOCAL_WELCOME_MESSAGE_HIDE', checked);
    setVisible(false);
  };
  return (
    visible && (
      <WelcomeModal
        scheduleMessage={scheduleMessage}
        isVisible={visible}
        onRedirect={redirectToDefaultAvailablity}
        onConfirm={clearHandler}
        onClose={clearHandler}
        checked={checked}
        onCheck={() => setChecked(!checked)}
        // eslint-disable-next-line react/prop-types
        customerName={profile ? profile.FirstName : 'Användare'}
      />
    )
  );
}

WelcomeMessageModalContainer.propTypes = {
  resourceAvailability: PropTypes.arrayOf(PropTypes.shape({})),
  profile: PropTypes.shape({}),
};
WelcomeMessageModalContainer.defaultProps = {
  resourceAvailability: [],
  profile: null,
};

const mapStateToProps = state => {
  const userProfile = fromResource.getDetail(state, 'userProfile') || {};
  return {
    resourceAvailability:
      fromResource.getList(state, 'resourceAvailability') || [],
    profile: userProfile?.Profile,
  };
};
export default connect(mapStateToProps)(WelcomeMessageModalContainer);
