import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter } from 'react-router-dom';
import store from 'setupStore';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import { LOCAL_STORE_LANGUAGE } from 'constant';
import App from 'components/App';
import { ErrorListener, ConnectivityListener } from 'containers';
import { loadAirShipModules } from 'utils/pushNotificationUtils';
import reportWebVitals from './reportWebVitals';
import './index.scss';

localStorage.setItem(LOCAL_STORE_LANGUAGE, 'se');
loadAirShipModules(window, document);

dayjs.locale('sv');

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ToastProvider autoDismiss>
        {/* <ConnectivityListener /> */}
        <ErrorListener />
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ToastProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
