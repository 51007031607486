import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

const SettingMenuDropdown = props => {
  const { children, button, className, text } = props;
  const [showDropDown, setShowDropDown] = useState(false);

  const toggleMenu = () => {
    setShowDropDown(!showDropDown);
  };

  const onBlur = event => {
    const { currentTarget } = event;
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        // toggleMenu();
        setShowDropDown(false);
      }
    }, 250);
  };

  return (
    <div onBlur={onBlur} className={className}>
      <div
        role="button"
        tabIndex="0"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          toggleMenu();
        }}
        onKeyDown={e => (e.key === 'Enter' ? toggleMenu() : '')}
        aria-label={text}
        className="tv-setting-dropdown__button"
      >
        {button}
      </div>
      {showDropDown && children}
    </div>
  );
};

SettingMenuDropdown.propTypes = {
  children: PropTypes.node,
  button: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
};

SettingMenuDropdown.defaultProps = {
  children: null,
  button: null,
  className: 'tv-setting-dropdown__element',
  text: 'setting-dropdown',
};

export default SettingMenuDropdown;
