import { put, call, takeEvery, all } from 'redux-saga/effects';
import { getTokenExpireDay } from 'utils/dateTimeUtils';
import { timezoneBE, TOKEN_LIVE_TIME_IN_DAYS } from '../../constant';
import * as actions from './actions';

const getCurrentDomain = () => {
  const { protocol, hostname } = window.location;
  const port = window.location.port ? `:${window.location.port}` : '';

  return `${protocol}//${hostname}${port}`;
};

export function* createToken(
  api,
  { interpreterId, resourceIdentifier, timeStamp },
  { thunk },
) {
  try {
    const dateValidUntil = getTokenExpireDay(
      timezoneBE,
      TOKEN_LIVE_TIME_IN_DAYS,
      'YYYY-MM-DD[T]HH:mm:ss[Z]',
    );
    const reqData = {
      ResourceNumber: interpreterId,
      ResourceIdentifier: resourceIdentifier,
      TimeStamp: timeStamp,
      DatetimeValidUntil: dateValidUntil,
    };
    const response = yield call([api, api.post], 'createToken', reqData);
    yield put(actions.tfvLoginSuccess(response.data, reqData, thunk));
  } catch (e) {
    yield put(
      actions.tfvLoginFailure(
        e,
        { interpreterId, resourceIdentifier, timeStamp },
        thunk,
      ),
    );
  }
}

export function* authenticateResourceUser(
  api,
  { email, passwordHash, isRefresh },
  { thunk },
) {
  try {
    const detail = yield call([api, api.post], 'resourceUserAuthenticate', {
      ResourceEmailAddress: email,
      PasswordHash: passwordHash,
    });
    yield put(
      actions.tfvResourceAuthenticateSuccess(
        detail,
        { email, passwordHash },
        thunk,
      ),
    );
  } catch (e) {
    if (!isRefresh) {
      yield put(
        actions.tfvLoginFailure(e, { email, passwordHash }, thunk),
      );
    } else {
      window.location.href = '/login';
    }
  }
}

export function* authenticateResourceUserUsingId(
  api,
  { email, interpreterId },
  { thunk },
) {
  try {
    const detail = yield call(
      [api, api.post],
      'resourceUserAuthenticateUsingId',
      {
        EmailAddress: email,
        ResourceNumber: interpreterId,
        Url: getCurrentDomain(),
      },
    );
    yield put(
      actions.tfvLoginSuccess(detail, { email, interpreterId }, thunk),
    );
  } catch (e) {
    yield put(
      actions.tfvLoginFailure(e, { email, interpreterId }, thunk),
    );
  }
}

export function* watchResourceLogin(api, { payload, meta }) {
  yield call(createToken, api, payload, meta);
}

export function* watchResourceAuthenticate(api, { payload, meta }) {
  yield call(authenticateResourceUser, api, payload, meta);
}

export function* watchResourceAuthenticateUsingId(api, { payload, meta }) {
  yield call(authenticateResourceUserUsingId, api, payload, meta);
}

export default function* ({ api }) {
  yield takeEvery(
    actions.AUTHENTICATE_RESOURCE,
    watchResourceAuthenticate,
    api,
  );
  yield takeEvery(
    actions.AUTHENTICATE_RESOURCE_USING_ID,
    watchResourceAuthenticateUsingId,
    api,
  );
  yield takeEvery(actions.TFV_LOGIN_REQUEST, watchResourceLogin, api);
}
