/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'components';
import './style.scss';
import { convertToDayJsObject } from 'utils/dateTimeUtils';

const GeneralNotification = ({
  title,
  message,
  date,
  isNew,
  isPage,
  handleCardClick,
  item,
}) => {
  const onClick = () => {
    handleCardClick(
      item?.NotificationTypeIdentifier === 'Request'
        ? 'generalRequest'
        : item?.NotificationTypeIdentifier,
      item.WorkAssignmentIdentifiers,
      item.NotificationIdentifier,
    );
  };

  return (
    <div
      className="tv-general-notification__container"
      role="button"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={e => (e.key === 'Enter' ? onClick() : null)}
    >
      <div className="tv-general-notification__wrapper">
        <div className="tv-general-notification__title">
          {isPage && isNew && (
            <div className="tv-general-notification__new-label tv-general-notification__new-label-forpage">
              {Translate({ content: 'notification.new' })}
            </div>
          )}
          {title}
        </div>
        {!isPage && isNew && (
          <div className="tv-general-notification__new-label">
            {Translate({ content: 'notification.new' })}
          </div>
        )}
        {isPage && (
          <div className="tv-general-notification__date">
            {convertToDayJsObject(date).format('DD MMMM, HH:mm')}
          </div>
        )}
      </div>
      <div
        className={`tv-general-notification__message ${
          isPage && 'tv-general-notification__message-forpage'
        }`}
      >
        {message}
      </div>
      {!isPage && (
        <div className="tv-general-notification__date">
          {' '}
          {convertToDayJsObject(date).format('DD MMMM, HH:mm')}
        </div>
      )}
    </div>
  );
};

GeneralNotification.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  date: PropTypes.string,
  isNew: PropTypes.bool,
  isPage: PropTypes.bool,
  item: PropTypes.shape({
    WorkAssignmentIdentifiers: PropTypes.arrayOf(PropTypes.shape({})),
    NotificationIdentifier: PropTypes.string,
    NotificationTypeIdentifier: PropTypes.string,
  }),
  handleCardClick: PropTypes.func,
};
GeneralNotification.defaultProps = {
  title: '',
  message: '',
  date: '',
  isNew: false,
  isPage: false,
  item: {},
  handleCardClick: () => {},
};

export default GeneralNotification;
