/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { IconButton, ContactCard } from 'components';
import parse from 'html-react-parser';
import './style.scss';
import { useWorkAssignment } from 'hooks';
import moment from 'moment';
import faq from './faq';

const ContactPage = () => {
  const { searchWorkAssignment } = useWorkAssignment();
  const [showAccordian, setAccordianState] = useState({
    'rowOne-columnOne': false,
    'rowOne-columnTwo': false,
    'rowTwo-columnOne': false,
    'rowTwo-columnTwo': false,
    'rowThree-columnOne': false,
    'rowThree-columnTwo': false,
  });

  const handleShowAccordian = id => {
    setAccordianState({
      [id]: !showAccordian[id],
    });
  };

  const displayIcons = id => {
    if (showAccordian[id]) {
      return <IconButton iconName="chevron-up" aria-label="icon up" />;
    }
    return <IconButton iconName="chevron-down" aria-label="icon down" />;
  };

  useEffect(() => {
    document.title = `Kontakta oss - TFV Tolkportal`;
    const searchDateRange = {
      start: moment().subtract(1, 'months'),
      end: moment().add(1, 'months'),
    };
    searchWorkAssignment(false, searchDateRange, true);
  }, []);
  return (
    <>
      <div className="page_content">
        <div className="page-title">
          <div className="title">Kontakta oss</div>
          {/* <div className="contact_now_button_wrapper">
            <button type="button" className="contact_now_button">
              {Translate({
                content: 'contact.sendTFVAnEmail',
              })}
            </button>
          </div> */}
        </div>
        <div className="contact_card_wrapper">
          <img
            src="../../../../images/contact-cover.png"
            style={{
              height: '250px',
              width: '100%',
              marginTop: '15px',
              objectFit: 'cover',
            }}
            alt="contact cover"
          />

          <div className="contact_card_wrapper-details">
            <div>
              <div className="page-title__new">Telefon</div>
              <div className="page-section-body__new">0770-813 000</div>
            </div>
            <div>
              <div className="page-title__new">Postadress</div>
              <div className="page-section-body__new">Tolkförmedling Väst</div>
              <div className="page-section-body__new">Box 113 05</div>
              <div className="page-section-body__new">404 27 Göteborg​</div>
            </div>
            <div>
              <div className="page-title__new">Besöksadress</div>
              <div className="page-section-body__new">
                Johan på Gårdas gata 5A, 4 tr
              </div>
              <div className="page-section-body__new">412 50 Göteborg</div>
            </div>
            <div className="page-title__new">
              <a
                href="https://www.tolkformedlingvast.se/ovrigt/kontakta-oss.html"
                target="_blank"
                rel="noreferrer"
                aria-label="Övrig kontaktinformation"
              >
                Övrig kontaktinformation
              </a>
            </div>
          </div>

          {/* <div className="d-flex flex-column">
            <div className="d-flex w-100 flex-column flex-md-row">
              <ContactCard
                imageUrl="/images/stockholm-office.jpg"
                place="Stockholm"
                addressLine="Sveavägen 159 plan 3"
                addressCityPostCode="113 46 Stockholm"
                customerServiceNumber="08-120 800 05"
              />
              <ContactCard
                imageUrl="/images/Malmö-office.jpg"
                place="Malmö"
                addressLine="Grynbodgatan 14"
                addressCityPostCode="211 33 Malmö"
                customerServiceNumber="040-23 02 05"
              />
            </div>
          </div> */}
          <div className="faq_wrapper">
            <div className="page-title">Frågor och svar</div>
            <div className="question-and-answer">
              <a
                href="https://www.tolkformedlingvast.se/tolk/registrerad-tolk/vanliga-fragor-och-svar.html"
                target="_blank"
                rel="noreferrer"
                tabIndex={0}
                aria-label="Vanliga frågor och svar"
              >
                Vanliga frågor och svar
              </a>
            </div>
          </div>
          {/* <div className="faq_wrapper">
            {Object.keys(faq.faqData).map(row => (
              <div className="row" key={`${faq.faqData[row]}-${Math.random()}`}>
                {faq.faqData[row].map(column => (
                  <div className="col col-md-6 col-sm-12" key={column.id}>
                    <div className="drop_accordian">
                      <div className="question_content">
                        <div
                          className="heading"
                          onClick={() => handleShowAccordian(column.id)}
                          onKeyPress={() => handleShowAccordian(column.id)}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="single-question">
                            {column.question}
                          </div>
                          <div className="icon_wrapper">
                            {displayIcons(column.id)}
                          </div>
                        </div>
                        <div
                          className="body"
                          style={{
                            display: showAccordian[column.id] ? 'flex' : 'none',
                          }}
                        >
                          {column.answer &&
                            column.answer
                              .split('||')
                              .map(x => <p>{parse(x)}</p>)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <div className="page-footer">
        <div className="d-flex mt-auto">
          <img
            alt="tfv-black"
            width={205}
            height={25}
            src="images/footer-logo.png"
          />
        </div>
      </div>
    </>
  );
};

export default ContactPage;
