/* eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'components';
import './style.scss';

const ContentPanel = props => (
  <div className="tv-terms single">{props.children}</div>
);

const ContentPageWrap = props => (
  <div className="tv-terms__page-wrap">{props.children}</div>
);

const ContentPage = props => (
  <div className="tv-terms__page">{props.children}</div>
);

const ContentTitle = props => (
  <h4 className="tv-terms__heading1">{props.children}</h4>
);

const ContentHeading = props => (
  <p className="tv-terms__heading2">{props.children}</p>
);

const ContentParagraph = props => (
  <p className="tv-terms__paragraph">{props.children}</p>
);

const ContentSection = props => (
  <div className="tab-panel-section">{props.children}</div>
);

const ContentSubSection = props => (
  <div className="tab-panel-section__sub">{props.children}</div>
);

function Terms(props) {
  return <ContentPanel></ContentPanel>;
}

Terms.propTypes = {};

export default Terms;
