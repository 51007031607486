/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  useCalendar,
  filterEventsForTheDateRange,
  filterAvailabilityPerDay,
  extractSemesterDay,
} from 'context/CalendarContext';
import { WeekDay, CalendarTimeLine } from 'components';
import './style.scss';
import { currentDate } from 'utils/dateTimeUtils';
import { isIE } from 'utils/browserUtils';
import { scrollElementTop } from 'utils/miscUtil';

export default function DayCalendar({ singleDay = false }) {
  const {
    calendarDateRange: day,
    list,
    assignmentRequestList,
    onWorkAssignmentClick,
    onActiveSemesterAllocationClick,
    isAvailabilityFilterApplied,
    selectedCalendarType,
    activeAssignment,
    availabilityList,
    semesterAllocationList,
    onAvailabilityExceptionClick,
  } = useCalendar();
  const date = day.start;

  const [workAssignmentsList, setworkAssignmentsList] = useState([]);

  const dayCalendarRef = useRef();
  let autoScrollRef = useRef(null);

  useEffect(() => {
    setworkAssignmentsList([...(list || []), ...(assignmentRequestList || [])]);
  }, [list, assignmentRequestList]);

  useEffect(() => {
    const dayCalendarElement = dayCalendarRef?.current;
    if (dayCalendarElement) {
      const assignmentElement = dayCalendarElement.querySelector(
        '.tv-week-view-assignment-item__container',
      );
      const currentTimeElement = dayCalendarElement.querySelector(
        '.tv-calendar-timeline__curent-time',
      );
      autoScrollRef = setTimeout(() => {
        if (currentTimeElement) {
          scrollElementTop(
            currentTimeElement,
            currentTimeElement?.parentElement?.parentElement,
          );
        } else if (assignmentElement) {
          scrollElementTop(
            assignmentElement,
            assignmentElement?.parentElement?.parentElement,
          );
        }
      }, 500);
    }
    return () => {
      clearTimeout(autoScrollRef);
    };
  }, [day]);

  return (
    <div className="tv-day-calendar__container container-wrapper" ref={dayCalendarRef}>
      <DaysOfWeekHeader week={day} />
      <div className="tv-day-calendar__weekdays">
        <CalendarTimeLine />
        <WeekDay
          key={date.format('[weekday-]YYYYMMDD')}
          eventsForDay={filterEventsForTheDateRange(
            workAssignmentsList,
            date.startOf('day'),
            date.endOf('day'),
          )}
          onAvailabilityExceptionClick={onAvailabilityExceptionClick}
          onActiveSemesterAllocationClick={onActiveSemesterAllocationClick}
          activeAssignment={activeAssignment}
          selectedCalendarType={selectedCalendarType}
          isAvailabilityFilterApplied={isAvailabilityFilterApplied}
          onWorkAssignmentClick={onWorkAssignmentClick}
          location="day"
          availabilityList={filterAvailabilityPerDay(availabilityList, date)}
          semesterDay={extractSemesterDay(semesterAllocationList, date)}
          date={date}
          singleDay
        />
      </div>
    </div>
  );
}

function DaysOfWeekHeader({ week }) {
  const date = week.start;
  const dateString = date.locale('sv').format('dddd D MMMM');
  return (
    <div
      className={`tv-day-calendar__header ${
        isIE ? 'tv-day-calendar__header-ie-fix' : null
      }`}
    >
      <div className="tv-day-calendar-filling" />
      <div
        className={`tv-day-calendar__header-item ${
          currentDate().isSame(date, 'day') &&
          'tv-day-calendar__header-item--today'
        }`}
        key={`week-calender-${dateString}`}
      >
        {dateString}
      </div>
    </div>
  );
}
