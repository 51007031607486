/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Checkbox } from 'components';

import './style.scss';

function CheckboxCollapse({
  data,
  onContractGroupChange,
  onContractGroupArticleChange,
  checkArticleOfTypeDisable,
  onArticleChange,
}) {
  return (
    <div className="tv-checkbox-collapse__container">
      {data.map((item, index) => {
        const [collapseOpen, setcollapseOpen] = useState(false);
        return (
          <Collapse
            className={index % 2 === 1 && 'tv-checkbox-collapse-bng-1'}
            key={item.ContractGroupIdentifier}
            expand={collapseOpen}
            handleShowAccordion={() => setcollapseOpen(!collapseOpen)}
            header={
              <div className="d-flex">
                <div className="tv-checkbox-collapse__header-checkbox">
                  <Checkbox
                    text={item.ContractGroupName}
                    value={item.ContractGroupIdentifier}
                    checked={item.IsDesiredByResource || false}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setcollapseOpen(true);
                      onContractGroupChange(item);
                    }}
                  />
                </div>
                <div
                  className="tv-checkbox-collapse__header-press"
                  onClick={() => setcollapseOpen(!collapseOpen)}
                />
              </div>
            }
          >
            <div className="tv-checkbox-collapse__content">
              {item.ContractGroupArticlePreferences.map(subitem => (
                <Checkbox
                  key={subitem.ArticleIdentifier}
                  text={subitem.ArticleName}
                  disabled={!item.IsDesiredByResource}
                  value={subitem.ArticleIdentifier}
                  checked={
                    subitem.IsDesiredByResource &&
                    !checkArticleOfTypeDisable(subitem)
                  }
                  onClick={() => {
                    if (item.IsDesiredByResource) {
                      onContractGroupArticleChange(
                        item,
                        subitem.ArticleIdentifier,
                      );
                    } else {
                      onContractGroupArticleChange(
                        item,
                        subitem.ArticleIdentifier,
                        true,
                      );
                    }
                    if (
                      !subitem.IsDesiredByResource &&
                      checkArticleOfTypeDisable(subitem)
                    ) {
                      onArticleChange(subitem, true);
                    }
                  }}
                />
              ))}
            </div>
          </Collapse>
        );
      })}
    </div>
  );
}

CheckboxCollapse.propTypes = {
  data: PropTypes.shape([]),
  onContractGroupChange: PropTypes.func,
  onContractGroupArticleChange: PropTypes.func,
  checkArticleOfTypeDisable: PropTypes.func,
  onArticleChange: PropTypes.func,
};
CheckboxCollapse.defaultProps = {
  data: [],
  onContractGroupChange: () => {},
  onContractGroupArticleChange: () => {},
  checkArticleOfTypeDisable: () => {},
  onArticleChange: () => {},
};

export default CheckboxCollapse;
