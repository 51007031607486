/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WelcomeMessage, Translate } from 'components';
import { fromResource } from 'store/selectors';
import { localDayjs, convertToDayJsObject } from 'utils/dateTimeUtils';

function WelcomeMessageContainer({
  resourceAvailability,
  showScheduleAvailablity,
  profile,
}) {
  const [scheduleMessage, setScheduleMessage] = useState(null);

  const checkAvailablity = () => {
    if (showScheduleAvailablity) {
      const availabilities = resourceAvailability.filter(
        item =>
          localDayjs().isBetween(
            convertToDayJsObject(item.DatetimeFrom),
            convertToDayJsObject(item.DatetimeTo),
            'minute',
          ) &&
          (item.AvailabilityType === 2 || item.AvailabilityType === 4),
      );

      const occupiedAvailabilities = resourceAvailability.filter(
        item =>
          localDayjs().isBetween(
            convertToDayJsObject(item.DatetimeFrom),
            convertToDayJsObject(item.DatetimeTo),
            'minute',
          ) && item.AvailabilityType === 1,
      );
      if (occupiedAvailabilities.length > 0) {
        setScheduleMessage(
          Translate({ content: 'welcome.unavailableBasedOnSchedule' }),
        );
      } else if (availabilities.length > 0) {
        setScheduleMessage(
          Translate({ content: 'welcome.availableBasedOnSchedule' }),
        );
      } else {
        setScheduleMessage(
          Translate({ content: 'welcome.unavailableBasedOnSchedule' }),
        );
      }
    }
  };

  useEffect(() => {
    let getTime;
    if (showScheduleAvailablity) {
      checkAvailablity(resourceAvailability);

      getTime = setInterval(() => {
        checkAvailablity(resourceAvailability);
      }, 30000);
    }
    return () => {
      clearInterval(getTime);
    };
  }, [resourceAvailability]);

  // eslint-disable-next-line consistent-return

  return (
    <WelcomeMessage
      scheduleMessage={scheduleMessage}
      // eslint-disable-next-line react/prop-types
      customerName={profile ? profile.FirstName : 'Användare'}
    />
  );
}

WelcomeMessageContainer.propTypes = {
  resourceAvailability: PropTypes.arrayOf(PropTypes.shape({})),
  showScheduleAvailablity: PropTypes.bool,
  profile: PropTypes.shape({}),
};
WelcomeMessageContainer.defaultProps = {
  resourceAvailability: [],
  showScheduleAvailablity: true,
  profile: null,
};

const mapStateToProps = state => {
  const userProfile = fromResource.getDetail(state, 'userProfile') || {};
  return {
    resourceAvailability:
      fromResource.getList(state, 'resourceAvailability') || [],
    profile: userProfile?.Profile,
  };
};
export default connect(mapStateToProps)(WelcomeMessageContainer);
