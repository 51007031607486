import React from 'react';
import './style.scss';

function Loader() {
  return (
    <div className="tv-loading__loader-container">
      <div className="tv-loading__loader-cursor" />
    </div>
  );
}

export default Loader;
