/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { currentDate, extendedDayJs } from 'utils/dateTimeUtils';
import './style.scss';
import sv from 'date-fns/locale/sv';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('sv', sv);

const DateRangePicker = ({
  placeholder,
  onChange,
  className,
  searchDateRange,
}) => {
  const [dateRange, setDateRange] = useState([
    extendedDayJs(searchDateRange.start).toDate(),
    extendedDayJs(searchDateRange.end).toDate(),
  ]);
  const [startDate, endDate] = dateRange;
  const handleChange = event => {
    setDateRange(event);
    onChange(event, 'datePicker');
  };
  return (
    <div className={`tv-daterangepicker ${className}`}>
      <label htmlFor="datenum">Datum</label>
      <ReactDatePicker
        selectsRange
        id="datenum"
        dateFormat="dd MMMM"
        startDate={startDate}
        endDate={endDate}
        locale="sv"
        calendarStartDay={1}
        onFocus={e => e.target.blur()}
        // disabledKeyboardNavigation
        onChange={handleChange}
        placeholderText={placeholder}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  searchDateRange: PropTypes.shape({
    start: PropTypes.shape({}),
    end: PropTypes.shape({}),
  }),
};
DateRangePicker.defaultProps = {
  placeholder: '',
  className: '',
  searchDateRange: PropTypes.shape({
    start: currentDate().startOf('month').subtract(1, 'month'),
    end: currentDate().endOf('month').add(1, 'month'),
  }),
};

export default DateRangePicker;
