import React from 'react';
import { Modal, Translate, Button, Dropdown, Field } from 'components';
import PropTypes from 'prop-types';
import StarRating from 'components/molecules/StarRating';
import { timeSheetContentTypes } from 'constant';
import './style.scss';

const FeedbackModal = ({
  isVisible,
  onClose,
  item,
  onChange,
  onSumbit,
  generateOptions,
  reasonValue,
  feedbackStatus,
  showError,
  charCount,
}) => (
  <Modal
    mainTitle={Translate({ content: 'feedback.feedback' })}
    isVisible={isVisible}
    className="tv-assignment-time-report-modal__container"
    onClose={onClose}
  >
    <div className="feedback-content mt-4">
      <div className="feedback-content-title mb-3">Ge oss feedback</div>
      {/* <div className="feedback-content-para text-center">
        Hur gick tolkuppdraget?
      </div> */}
      <div className="feedback-content-para text-center">
        1-2 stjärnor utreds av TFV, 3-5 stjärnor är positivt.
      </div>
    </div>
    <div className="feedback-star mt-4">
      <StarRating
        defaultValue={item.RatingResource || null}
        viewMode={false}
        onChange={value => onChange(value, timeSheetContentTypes.RATING)}
        starFontSize="50px"
      />
    </div>
    {feedbackStatus.RatingResource && (
      <>
        <div className="d-flex mt-5">
          <div className="w-50 pr-3">
            <label htmlFor={Translate({ content: 'feedback.reason' })}>
              {Translate({ content: 'feedback.reason' })}
            </label>
            <Dropdown
              label={Translate({ content: 'feedback.reason' })}
              name="type"
              className={`filter-panel__assignment-type ${
                showError ? 'show-feedback-error' : ''
              }`}
              options={generateOptions()}
              onChange={value => onChange(value, 'reason')}
              value={reasonValue}
            />
            {showError ? (
              <div className="feedback-error-message">
                Orsak är obligatoriskt
              </div>
            ) : null}
          </div>
          <div className="w-50 pl-3">
            <label
              className="mb-0"
              htmlFor={Translate({ content: 'feedback.interpreterUser' })}
            >
              {Translate({ content: 'feedback.interpreterUser' })}
            </label>
            <Field
              value={`${
                item.ContactPerson?.ContactPersonTitle !== null &&
                item.ContactPerson?.ContactPersonTitle !== ''
                  ? item.ContactPerson?.ContactPersonTitle
                  : ''
              } ${' '}${item.ContactPerson.ContactPersonName}`}
              name={Translate({ content: 'feedback.interpreterUser' })}
              id={Translate({ content: 'feedback.interpreterUser' })}
              disabled="true"
            />
          </div>
        </div>
        <div className="mt-3">
          <label
            className="tv-assignment-time-report-edit__title"
            htmlFor="Comment"
          >
            {Translate({ content: 'feedback.comment' })}
          </label>
          <textarea
            placeholder=""
            id="Comment"
            name="Comment"
            className="tv-assignment-time-report-edit__field-comment"
            onChange={value => onChange(value, 'comment')}
            maxLength={900}
            aria-label={Translate({ content: 'feedback.comment' })}
          />
          {charCount > 0 && (
            <div style={{ color: '#74737b' }}>{`${charCount}/900`}</div>
          )}
        </div>
      </>
    )}

    <div className="tv-assignment-time-report-edit__actions mt-4">
      <Button
        label={Translate({ content: 'feedback.cancel' })}
        className="tv-assignment-time-report-edit__actions-button"
        palette="outline"
        onClick={onClose}
      />
      <Button
        label={Translate({ content: 'feedback.submit' })}
        className="tv-assignment-time-report-edit__actions-button"
        onClick={onSumbit}
      />
    </div>
  </Modal>
);

FeedbackModal.propTypes = {
  onChange: PropTypes.func,
  onSumbit: PropTypes.func,
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  item: PropTypes.shape({
    RatingResource: PropTypes.number,
    ContactPerson: {
      ContactPersonName: PropTypes.string,
    },
  }),
  generateOptions: PropTypes.func,
  reasonValue: PropTypes.shape({}),
  feedbackStatus: PropTypes.shape({ RatingResource: PropTypes.number }),
  showError: PropTypes.func,
  charCount: PropTypes.number,
};

FeedbackModal.defaultProps = {
  onChange: () => {},
  onSumbit: () => {},
  onClose: () => {},
  isVisible: false,
  item: { RatingResource: 0, ContactPerson: { ContactPersonName: '' } },
  generateOptions: () => {},
  reasonValue: {},
  feedbackStatus: { RatingResource: 0 },
  showError: false,
  charCount: 0,
};

export default FeedbackModal;
