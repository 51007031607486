/* eslint-disable vars-on-top */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Translate, SidebarAssignmentListItem } from 'components';
import { currentDate, dayJsDuration } from 'utils/dateTimeUtils';
import { checkOnGoingAssignment } from 'utils/miscUtil';
import { useCalendar } from 'context/CalendarContext';

function convertToMin(expireTime) {
  if (!expireTime) return null;

  return dayJsDuration(expireTime.diff(currentDate()));
}
function WeekViewAssignmentItem(props) {
  const {
    height,
    yCoordinate,
    zIndex,
    expiryTime,
    diffInMinutes,
    onWorkAssignmentClick,
    detail,
    onClick,
    isActive,
    index,
    location = 'week',
  } = props;
  const { activeAssignment } = useCalendar();
  const [statusColor, setStatusColor] = useState(detail.status);
  const [isActiveAssignment, setIsActiveAssignment] = useState(false);
  const timeExpiryRef = useRef(null);
  const assignmentElemetRef = useRef(null);
  const timeRangeString = `${detail.timeFrom.format(
    'HH:mm',
  )} - ${detail.timeTo.format('HH:mm')}`;

  useEffect(() => {
    let timeExpiryInterval = null;
    const timeExpiryElement = timeExpiryRef.current;
    const assignmentElement = assignmentElemetRef.current;
    const initialInterval = convertToMin(detail.expiryTime);
    if (initialInterval && initialInterval.asSeconds() <= 0) {
      assignmentElement.style.display = 'none';
    }
    if (detail.expiryTime && timeExpiryElement) {
      timeExpiryElement.innerText = initialInterval.format('HH:mm:ss');

      timeExpiryInterval = setInterval(() => {
        const newInterval = convertToMin(detail.expiryTime);
        if (timeExpiryRef?.current?.innerText) {
          timeExpiryRef.current.innerText = newInterval.format('HH:mm:ss');
        }
        if (newInterval.asMinutes() <= 0) {
          assignmentElement.style.display = 'none';
        }
      }, 1000);
    }
    return () => {
      clearInterval(timeExpiryInterval);
    };
  }, []);

  useEffect(() => {
    const updateStatusColor = () => {
      const isOnGoingStatus = checkOnGoingAssignment(detail);
      if (isOnGoingStatus) {
        setStatusColor('onGoing');
      } else {
        setStatusColor(detail.status);
      }
      setTimeout(updateStatusColor, 5000);
    };
    updateStatusColor();
  }, [detail]);

  useEffect(() => {
    if (activeAssignment) {
      if (activeAssignment.OrderNumber === detail.orderNumber) {
        setIsActiveAssignment(true);
      } else {
        setIsActiveAssignment(false);
      }
    } else {
      setIsActiveAssignment(false);
    }
  }, [activeAssignment]);

  return (
    <>
      <div
        style={{ height, top: yCoordinate, zIndex }}
        className={`tv-week-view-assignment-item__container tv-week-view-assignment-item__container--${statusColor} ${
          isActiveAssignment
            ? 'tv-week-view-assignment-item__container--selected'
            : ''
        }`}
        onClick={onClick}
        onKeyDown={e => (e.key === 'Enter' ? onClick(e) : null)}
        role="button"
        tabIndex={0}
        ref={assignmentElemetRef}
        data-assignment-id={detail.orderNumber}
      >
        <div className="tv-mobile--hide">
          <div className="tv-week-view-assignment-item__header">
            {detail.status !== 'inquiry' && (
              <i
                className="material-icons tv-week-view-assignment-item__sub-item__icon"
                aria-label={detail.type}
              >
                {detail.typeIcon}
              </i>
            )}

            <div
              className="tv-week-view-assignment-item__header__name"
              title={
                detail.status === 'inquiry'
                  ? Translate({ content: 'calendar.newRequest' })
                  : detail.client
              }
            >
              {detail.status === 'inquiry'
                ? Translate({ content: 'calendar.newRequest' })
                : detail.client}
            </div>

            {detail.status === 'inquiry' && (
              <div
                className="tv-week-view-assignment-item__header__countdown"
                ref={timeExpiryRef}
              >
                00:00
              </div>
            )}
            {detail.status !== 'inquiry' && diffInMinutes <= 15 && (
              <div className="tv-week-view-assignment-item__header__time">
                {timeRangeString}
              </div>
            )}
          </div>
          {diffInMinutes > 15 && (
            <div
              className={`tv-week-view-assignment-item__sub-container ${
                location === 'day' &&
                'tv-week-view-assignment-item__sub-container--day_calendar'
              }`}
            >
              {detail.status === 'inquiry' ? (
                <>
                  <div className="tv-week-view-assignment-item__sub-item">
                    <i className="material-icons tv-week-view-assignment-item__sub-item__icon">
                      {detail.typeIcon}
                    </i>
                    <span className="tv-week-view-assignment-item__sub-item__value--text">
                      {detail.client}
                    </span>
                  </div>

                  {(diffInMinutes > 30 || location === 'day') && (
                    <>
                      {' '}
                      <div className="tv-week-view-assignment-item__sub-item">
                        <i className="material-icons tv-week-view-assignment-item__sub-item__icon">
                          watch_later
                        </i>
                        <span className="tv-week-view-assignment-item__sub-item__value--text">
                          {timeRangeString}
                        </span>
                      </div>
                      <div className="tv-week-view-assignment-item__sub-item">
                        <i className="material-icons tv-week-view-assignment-item__sub-item__icon">
                          payment
                        </i>
                        <span className="tv-week-view-assignment-item__sub-item__value--text">
                          {`${detail.payment}kr`}
                        </span>
                      </div>
                    </>
                  )}
                </>
              ) : (
                diffInMinutes >= 30 && (
                  <div className="tv-week-view-assignment-item__sub-item">
                    <i className="material-icons tv-week-view-assignment-item__sub-item__icon">
                      watch_later
                    </i>
                    <span className="tv-week-view-assignment-item__sub-item__value--text">
                      {timeRangeString}
                    </span>
                  </div>
                )
              )}

              {/* {detail.status === 'inquiry' && diffInMinutes >= 60 && (
            <>
              <div className="tv-week-view-assignment-item__sub-item">
                <i className="material-icons tv-week-view-assignment-item__sub-item__icon" >payment</i>
                <span
                  className="tv-week-view-assignment-item__sub-item__value--text"
                  style={{ textTransform: 'lowercase' }}
                >
                  {detail.amount}kr/h
                </span>
              </div>
            </>
          )} */}
            </div>
          )}
        </div>
        <div className="tv-mobile--show">
          <SidebarAssignmentListItem {...props} fromWeekItem="true" />
        </div>
      </div>
    </>
  );
}

WeekViewAssignmentItem.propTypes = {
  height: PropTypes.string,
  yCoordinate: PropTypes.string,
  zIndex: PropTypes.number,
  diffInMinutes: PropTypes.number,
  detail: PropTypes.shape({}),
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

WeekViewAssignmentItem.defaultProps = {
  height: '0px',
  yCoordinate: '0px',
  diffInMinutes: 0,
  zIndex: 2,
  detail: null,
  onClick: () => {},
  isActive: false,
};

export default WeekViewAssignmentItem;
