/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate, Button, AttachedFile, StarRating } from 'components';
import {
  convertToDayJsObject,
  dayJsDuration,
  localDayjs,
} from 'utils/dateTimeUtils';
import { v4 as uuidv4 } from 'uuid';
import './style.scss';

const calcualateExpenseDetails = (expense = []) => {
  const { parkingExpense, otherExpense } = {
    parkingExpense: [],
    otherExpense: [],
  };
  expense?.forEach(x => {
    if (x.ExpenseType === 1) parkingExpense.push(x);
    else if (x.ExpenseType === 2) otherExpense.push(x);
  });
  return {
    parkingExpense,
    otherExpense,
  };
};

const convertSecondsToHumanize = timeInSeconds =>
  timeInSeconds
    ? localDayjs
        .duration(timeInSeconds || 0, 'seconds')
        .format(
          timeInSeconds >= 86400 ? 'D [d] H [tim] m [min]' : 'H [tim] m [min]',
        )
    : '0 tim 0 min';

const ContentRow = ({
  left,
  center,
  right,
  children,
  className,
  controlClass,
  childClass,
}) => (
  <>
    <div className={`tv-assignment-time-report-preview__wrapper ${className}`}>
      {left && (
        <div
          className={`tv-assignment-time-report-preview__control ${controlClass}`}
        >
          <label
            className="tv-assignment-time-report-preview__label"
            htmlFor="distanceInKm"
          >
            {left}
          </label>
        </div>
      )}
      <div className={`tv-assignment-time-report-preview__content-right  `}>
        {center && (
          <div
            className={`tv-assignment-time-report-preview__control ${controlClass}`}
          >
            <label htmlFor="distance">{center}</label>
          </div>
        )}
        {right && (
          <div
            className={`tv-assignment-time-report-preview__control  ${controlClass}`}
          >
            {right}
          </div>
        )}
      </div>
    </div>
    {children && <div className={childClass}>{children}</div>}
  </>
);

const AssignmentTimeReportPreview = ({
  onClose,
  onSubmit,
  isEdit,
  validationError,
  onSwitchMode,
  item,
}) => {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = `Tidrapport ${item.OrderNumber} - TFV Tolkportal`;
    return () => {
      document.title = originalTitle;
    };
  }, [onSwitchMode]);
  const From = convertToDayJsObject(item.TimeReport.DatetimeOriginalFrom);
  const To = convertToDayJsObject(item.TimeReport.DatetimeOriginalTo);
  const dateTimeString = `${From.format('HH:mm')} - ${To.format(
    'HH:mm, DD MMMM',
  )}`;
  const totalDistance =
    (item.TimeReport.DistanceToAssignment || item.TimeReport.TravelDistanceTo) +
    (item.TimeReport.DistanceFromAssignment ||
      item.TimeReport.TravelDistanceFrom);
  const duration = dayJsDuration(To.diff(From));
  const timeDifference = duration.format('H [tim] m [min]');
  const [travelTimeToLocation, travelTimeFromLocation] = [
    convertSecondsToHumanize(item.TimeReport?.TraveltimeToAssignment || 0),
    convertSecondsToHumanize(item.TimeReport?.TraveltimeFromAssignment || 0),
  ];
  const { otherExpense, parkingExpense } = calcualateExpenseDetails(
    item.Expenses,
  );

  return (
    <div className="tv-assignment-time-report-preview__content">
      <div className="tv-assignment-time-report-preview__content-area">
        <div className="tv-assignment-time-report-preview__title-area">
          <label className="tv-assignment-time-report-preview__title">
            {item.OrderNumber}, {item.Customer?.CustomerName}
          </label>
          {/* <div className="tv-assignment-time-report-edit__field-rating">
            <StarRating defaultValue={item.RatingResource || null} viewMode />
          </div> */}
          <label className="tv-mobile--show tv-assignment-time-report-preview__title-label">{`${From.format(
            'DD MMMM YYYY',
          )}, klockan ${From.format('HH:mm')}-${To.format('HH:mm')}`}</label>
        </div>

        <hr className="tv-assignment-time-report-preview__seperator tv-assignment-time-report-preview__seperator-top " />

        <>
          <ContentRow
            left="Tid"
            center={dateTimeString}
            className="tv-assignment-time-report-preview__title-area tv-assignment-time-report-preview__date-time"
          />
          <ContentRow
            left={Translate({
              content: 'timeReport.actualInterpretationTime',
            })}
            center={timeDifference}
            className="tv-assignment-time-report-preview__title-area tv-assignment-time-report-preview-row-mobile"
          />
          <ContentRow
            left={Translate({ content: 'timeReport.InterpreterUsers' })}
            center={`${
              item.ContactPerson?.ContactPersonTitle !== null &&
              item.ContactPerson?.ContactPersonTitle !== ''
                ? item.ContactPerson?.ContactPersonTitle
                : ''
            } ${' '} ${item.ContactPerson?.ContactPersonName}`}
            className="tv-assignment-time-report-preview__title-area tv-assignment-time-report-preview-row-mobile"
          />
          {/* {!item.IsPerformedRemotely && (
            <>
              <ContentRow
                left={Translate({ content: 'timeReport.rest' })}
                center={localDayjs
                  .duration(
                    item.TimeReport.DowntimeInSeconds ||
                      item.TimeReport.Downtime ||
                      0,
                    'seconds',
                  )
                  .format('H [tim] m [min]')}
                className="tv-assignment-time-report-preview__title-area tv-assignment-time-report-preview-row-mobile"
              />
            </>
          )} */}
          <hr className="tv-assignment-time-report-preview__seperator" />
          {/* {!item.IsPerformedRemotely && (
            <>
              <label className="tv-assignment-time-report-preview__title">
                {Translate({ content: 'timeReport.travelAndParking' })}
              </label>

              <ContentRow
                left={Translate({ content: 'timeReport.distanceInKm' })}
                center={`${totalDistance || 0} km`}
              />
              <ContentRow
                left={Translate({ content: 'timeReport.travelTimeToLocation' })}
                center={travelTimeToLocation}
                className="tv-assignment-time-report-preview__title-area tv-assignment-time-report-preview-row-mobile"
              />
              <ContentRow
                left={Translate({
                  content: 'timeReport.travelTimeFromLocation',
                })}
                center={travelTimeFromLocation}
                className="tv-assignment-time-report-preview__title-area tv-assignment-time-report-preview-row-mobile"
              />

              {parkingExpense.length > 0 && (
                <ContentRow
                  left={Translate({ content: 'timeReport.receipt' })}
                  childClass="tv-assignment-time-report-preview__attachments"
                >
                  {parkingExpense.map(x => (
                    <AttachedFile
                      key={uuidv4()}
                      file={x}
                      displayText={
                        x.AttachmentMetadata?.AttachmentFilename?.split('.')[0]
                      }
                    />
                  ))}
                </ContentRow>
              )}

              <hr className="tv-assignment-time-report-preview__seperator" />
            </>
          )} */}
          {/* <label className="tv-assignment-time-report-preview__title">
            {!item.IsPerformedRemotely
              ? Translate({ content: 'timeReport.otherExpensesAndCommense' })
              : Translate({ content: 'timeReport.commentSection' })}
          </label> */}
          {/* {!item.IsPerformedRemotely && otherExpense.length > 0 && (
            <label className="tv-assignment-time-report-preview__title">
              {Translate({ content: 'timeReport.otherExpensesAndCommense' })}
            </label>
          )} */}

          {/* {!item.IsPerformedRemotely && otherExpense.length > 0 && (
            <ContentRow
              left={Translate({ content: 'timeReport.receipt' })}
              childClass="tv-assignment-time-report-preview__attachments"
            >
              {otherExpense.map(x => (
                <AttachedFile
                  key={uuidv4()}
                  file={x}
                  displayText={
                    x.AttachmentMetadata?.AttachmentFilename?.split('.')[0]
                  }
                />
              ))}
            </ContentRow>
          )} */}
          {/* <ContentRow
            left={Translate({ content: 'timeReport.comment' })}
            className="tv-assignment-time-report-preview__comment-wrapper"
            childClass="tv-assignment-time-report-preview__comment-label"
          >
            <label className="tv-assignment-time-report-preview__control">
              {item.TimeReport.Comment ||
                item.TimeReport.TimeReportComment ||
                '-'}
            </label>
          </ContentRow> */}
        </>

        {!validationError.isValid && (
          <div className="tv-assignment-time-report-preview__errors">
            {validationError.errorMessage}
          </div>
        )}
      </div>
      <div className="tv-assignment-time-report-preview__actions">
        {isEdit ? (
          <>
            <Button
              label={Translate({ content: 'timeReport.goBack' })}
              className="tv-assignment-time-report-preview__actions-button"
              palette="outline"
              onClick={() => onSwitchMode('edit')}
            />
            <Button
              label={Translate({ content: 'timeReport.submit' })}
              className="tv-assignment-time-report-preview__actions-button"
              onClick={onSubmit}
            />
          </>
        ) : (
          <Button
            label={Translate({ content: 'timeReport.ok' })}
            className="tv-assignment-time-report-preview__actions-button"
            palette="primary"
            onClick={onClose}
          />
        )}
      </div>
    </div>
  );
};

AssignmentTimeReportPreview.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  validationError: PropTypes.shape({
    isValid: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),
  item: PropTypes.shape([]),
  onSwitchMode: PropTypes.func,
};

AssignmentTimeReportPreview.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  isEdit: false,
  validationError: {
    isValid: true,
    errorMessage: '',
  },
  item: [],
  onSwitchMode: () => {},
};

export default AssignmentTimeReportPreview;
