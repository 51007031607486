/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'components';

import('./style.scss');

function TableListItem({
  FileIdentifier,
  name,
  date,
  tabIndex,
  visibleDate,
  onDownloadClick,
}) {
  const backgroundClass = classNames({
    'tv-table-list-item-background-0': tabIndex % 2 === 0,
    'tv-table-list-item-background-1': tabIndex % 2 !== 0,
  });
  return (
    <div className={`tv-table-list-item__container ${backgroundClass}`}>
      <div className="tv-table-list-item_name">{name}</div>
      {visibleDate && <div className="tv-table-list-item_date">{date}</div>}
      <div
        role="button"
        tabIndex="0"
        onKeyPress={() => onDownloadClick({ FileIdentifier })}
        onClick={() => onDownloadClick({ FileIdentifier })}
        className="tv-table-list-item_download-button"
      >
        {Translate({ content: 'general.download' })}
      </div>
    </div>
  );
}

TableListItem.propTypes = {
  FileIdentifier: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  tabIndex: PropTypes.number,
  visibleDate: PropTypes.bool,
  onDownloadClick: PropTypes.func,
};
TableListItem.defaultProps = {
  FileIdentifier: '',
  name: '',
  date: '',
  tabIndex: 0,
  visibleDate: true,
  onDownloadClick: () => {},
};

export default TableListItem;
