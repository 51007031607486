/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  resourceDetailReadRequest,
  tfvLogout,
  resourceListReadRequest,
} from 'store/actions';
import { TopNavBar, MobileNavBar, SkipLinks } from 'components';
import { connect } from 'react-redux';
import { fromResource } from 'store/selectors';
import useWindowSize from 'hooks/useWindowSize';

import './style.scss';
import { currentDate, localDayjs as dayjs } from 'utils/dateTimeUtils';
import { fulfilled, clean } from 'redux-saga-thunk';
import {
  customEvent,
  loadAirShipModules,
  registerAirshipChannel,
  unregisterAirShipChannel,
} from 'utils/pushNotificationUtils';
import { LOCAL_STORE_INTERPRETER_NUMBER } from 'constant';
import { useHistory } from 'react-router-dom';
import { createUpdateWebSession } from 'utils/browserUtils';
import { sleepTime } from 'utils/miscUtil';
import showChat from 'utils/chatUtils';
import { WelcomeMessageModalContainer } from 'containers';

const MainLayout = ({
  children,
  getInterpreterProfile,
  isProfileLoaded,
  cleanState,
  profile,
  onLogout,
  getResourceAvailability,
  getInterpreterRequests,
  getAllNewNotifications,
  getSemesterAllocations,
  getResourceComplaintReason,
}) => {
  const navigationRef = useRef(null);
  const mainContentRef = useRef(null);
  const { isMobileView } = useWindowSize();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [displaySkipLinks, setDisplaySkipLinks] = useState(false);
  const [firstLinkFocused, setFirstLinkFocused] = useState(true);
  const [secondLinkFocused, setSecondLinkFocused] = useState(false);
  const [firstTabNavigation, setFirstTabNavigation] = useState(false);
  const [activeFocusedElement, setActiveFocusedElement] = useState(null);
  const [topNavigationFocus, setTopNavigationFocus] = useState(false);
  const [mainContentFocus, setMainContentFocus] = useState(false);
  const [firstTimeClicked, setFirstTimeClicked] = useState(false);

  useEffect(async () => {
    if (isProfileLoaded) {
      const interpreterNumber = localStorage.getItem(
        LOCAL_STORE_INTERPRETER_NUMBER,
      );
      if (interpreterNumber !== null) {
        cleanState('userProfileDetailRead');

        await createUpdateWebSession(interpreterNumber);
      }
    }
  }, [isProfileLoaded]);

  useEffect(async () => {
    await getInterpreterProfile();
    // await getResourceAvailability();
    await getInterpreterRequests();
    await getSemesterAllocations();
    await getResourceComplaintReason();
    const getRequestIntervalHandle = setInterval(() => {
      getInterpreterRequests();
      getAllNewNotifications(!isMobileView);
    }, 600000);
    return () => {
      clearInterval(getRequestIntervalHandle);
      // unregisterAirShipChannel();
    };
  }, []);

  useEffect(() => {
    getAllNewNotifications(!isMobileView);
  }, [isMobileView]);

  useEffect(() => {
    showChat();
  }, []);

  const handleLogout = async () => {
    const interpreterNumber = localStorage.getItem(
      LOCAL_STORE_INTERPRETER_NUMBER,
    );

    const list = await navigator.serviceWorker.getRegistrations();
    sleepTime(1000);
    if (list?.length > 0) {
      await unregisterAirShipChannel(interpreterNumber).then(() => {
        history.push('/login');
        onLogout();
      });
    } else {
      history.push('/login');
      onLogout();
    }
  };

  const handleFirstTabPress = event => {
    if (event.shiftKey && event.keyCode === 9 && displaySkipLinks === false) {
      const { activeElement } = document;
      if (
        activeElement.firstElementChild &&
        activeElement.firstElementChild.id === 'logo-image'
      ) {
        event.preventDefault();
        setDisplaySkipLinks(true);
        setSecondLinkFocused(true);
      }
    } else if (
      event.shiftKey &&
      event.keyCode === 9 &&
      displaySkipLinks === true
    ) {
      if (secondLinkFocused === true) {
        setFirstLinkFocused(true);
        setSecondLinkFocused(false);
      } else if (firstLinkFocused === true) {
        setDisplaySkipLinks(false);
        setFirstLinkFocused(false);
      }
    } else if (
      event.keyCode === 9 &&
      displaySkipLinks === false &&
      firstTabNavigation === false &&
      firstTimeClicked === false
    ) {
      event.preventDefault();
      setDisplaySkipLinks(true);
      setFirstTabNavigation(true);
    } else if (event.keyCode === 9 && displaySkipLinks === true) {
      if (firstLinkFocused === true) {
        setFirstLinkFocused(false);
        setSecondLinkFocused(true);
      } else if (secondLinkFocused === true) {
        setSecondLinkFocused(false);
        setDisplaySkipLinks(false);
      }
    }
  };

  const handleOutsideClick = event => {
    if (event.type === 'click') {
      if (activeFocusedElement !== null) {
        activeFocusedElement.style.outline = 'none';
        setActiveFocusedElement(null);
      }
      if (displaySkipLinks === true) {
        setDisplaySkipLinks(false);
        setFirstLinkFocused(false);
        setSecondLinkFocused(false);
      }
      setFirstTimeClicked(true);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleFirstTabPress);
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('keydown', handleFirstTabPress);
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  const handleFocusElementTabPress = event => {
    if (event.keyCode === 9 && activeFocusedElement !== null) {
      event.preventDefault();
      activeFocusedElement.style.outline = 'none';
      let focusableElements;
      if (topNavigationFocus) {
        focusableElements = activeFocusedElement.querySelectorAll(
          'button, input, select, textarea, div[tabindex="0"]',
        );
      } else if (mainContentFocus) {
        focusableElements = activeFocusedElement.querySelectorAll(
          'button, input, select, textarea, a',
        );
      }

      let firstVisibleElement;
      for (let i = 0; i < focusableElements.length; i++) {
        const element = focusableElements[i];
        const elementStyle = window.getComputedStyle(element);
        if (
          elementStyle.display !== 'none' &&
          elementStyle.visibility !== 'hidden'
        ) {
          firstVisibleElement = element;
          break;
        }
      }

      if (firstVisibleElement) {
        setTimeout(() => {
          firstVisibleElement.focus();
        });
        setActiveFocusedElement(null);
      }
    }
  };

  const hideSkipLinks = () => {
    setFirstLinkFocused(false);
    setSecondLinkFocused(false);
    setDisplaySkipLinks(false);
  };

  const handleEnterKeyPress = () => {
    let focusElemet = null;
    let outlineColor = null;
    if (firstLinkFocused) {
      focusElemet = navigationRef.current;
      outlineColor = 'red';
    } else if (secondLinkFocused) {
      focusElemet = mainContentRef.current;
      outlineColor = 'green';
    }
    if (focusElemet) {
      focusElemet.style.outline = `2px solid ${outlineColor}`;
      focusElemet.style.outlineOffset = '-4px';
      focusElemet.style.borderRadius = '12px';
      setActiveFocusedElement(focusElemet);
    }
    hideSkipLinks();
  };

  useEffect(() => {
    document.addEventListener('keydown', handleFocusElementTabPress);
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('keydown', handleFocusElementTabPress);
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [activeFocusedElement]);

  const handleTopNavigation = value => {
    setTopNavigationFocus(value);
  };

  const handleMainContentNavigation = value => {
    setMainContentFocus(value);
  };
  return (
    <>
      <WelcomeMessageModalContainer />
      {!isMobileView ? (
        <TopNavBar
          customerName={
            profile.FirstName
              ? `${profile.FirstName} ${profile.LastName}`
              : null
          }
          onLogout={handleLogout}
          navigationRef={navigationRef}
        />
      ) : (
        <MobileNavBar
          visible={visible}
          setVisible={setVisible}
          onLogout={handleLogout}
          customerName={
            profile.FirstName
              ? `${profile.FirstName} ${profile.LastName}`
              : null
          }
          navigationRef={navigationRef}
        />
      )}
      <div
        className={`tv-main_layout__container ${visible ? 'd-none' : ''}`}
        id="main-content"
        ref={mainContentRef}
      >
        {children}
      </div>
      {displaySkipLinks === true ? (
        <SkipLinks
          firstLinkFocused={firstLinkFocused}
          secondLinkFocused={secondLinkFocused}
          handleEnterKeyPress={handleEnterKeyPress}
          handleTopNavigation={handleTopNavigation}
          handleMainContentNavigation={handleMainContentNavigation}
        />
      ) : null}
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  getInterpreterProfile: PropTypes.func,
  profile: PropTypes.shape({}),
  onLogout: PropTypes.func,
  getResourceComplaintReason: PropTypes.func,
};
MainLayout.defaultProps = {
  children: null,
  profile: {},
  getInterpreterProfile: () => {},
  onLogout: () => {},
  getResourceComplaintReason: () => {},
};
const STANDARD_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const mapDispatchToProps = dispatch => ({
  getInterpreterProfile: () => {
    dispatch(resourceDetailReadRequest('userProfile'));
  },
  getResourceComplaintReason: () => {
    dispatch(resourceDetailReadRequest('getResourceComplaintReason'));
  },
  cleanState: thunk => {
    dispatch(clean(thunk));
  },
  getResourceAvailability: () => {
    const params = {
      DatetimeFrom: currentDate().format(STANDARD_DATE_TIME_FORMAT),
      DatetimeTo: currentDate().format(STANDARD_DATE_TIME_FORMAT),
    };
    dispatch(
      resourceListReadRequest(
        'resourceAvailability',
        'AvailabilityIntervals',
        params,
        true,
      ),
    );
  },
  onLogout: () => {
    dispatch(tfvLogout());
  },
  getSemesterAllocations: () => {
    // const date = dayjs(new Date()).format('YYYY-MM-DD');
    const params = {};
    dispatch(
      resourceListReadRequest(
        'semesterAllocations',
        'VacationDays',
        params,
        true,
      ),
    );
  },
  getInterpreterRequests: () => {
    dispatch(
      resourceListReadRequest('assignmentRequest', 'ResourceRequests', {
        ResourceRequestStatus: 2,
      }),
      true,
    );
  },
  getAllNewNotifications: DoOnlyIncludeUnreadNotifications => {
    dispatch(
      resourceDetailReadRequest('searchAllNotifications', {
        DatetimeSentFrom: currentDate()
          .subtract(3, 'days')
          .format('YYYY-MM-DD'),
        DoOnlyIncludeUnreadNotifications: false,
        OrderIdentifier: null,
        WorkAssignmentIdentifier: null,
      }),
      true,
    );
  },
});
const mapStateToProps = state => {
  const user = fromResource.getDetail(state, 'userProfile') || {};
  return {
    profile: user.Profile,
    isProfileLoaded: fulfilled(state, 'userProfileDetailRead'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
