import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resourceCreateRequest } from 'store/actions';
import { pending, fulfilled, clean } from 'redux-saga-thunk';
import { useWorkAssignment } from 'hooks';

export const apis = {
  SUBMIT_TIME_REPORT: 'submitTimeReport',
  RATE_WORK_ASSIGNMENTS: 'rateWorkAssignment',
  WORK_ASSIGNMENTS_EXPENSES: 'updateWorkAssignmentExpenses',
};

const useTimeSheetReport = (workAssignment = {}) => {
  const dispatch = useDispatch();

  const {
    searchSingleWorkAssignment,
    singleAssignmentRetrivePending,
  } = useWorkAssignment();

  const sendTimeSheetData = timeReport => {
    dispatch(resourceCreateRequest(apis.SUBMIT_TIME_REPORT, timeReport));
  };

  const sendRatingData = rate => {
    dispatch(resourceCreateRequest(apis.RATE_WORK_ASSIGNMENTS, rate));
  };

  const sendExpenseData = expensesToUpdate => {
    dispatch(
      resourceCreateRequest(apis.WORK_ASSIGNMENTS_EXPENSES, expensesToUpdate),
    );
  };

  const [timeReoprtRequestFulfilled, setTimeReoprtRequestFulfilled] = useState(
    false,
  );

  const sendTimeSheetDataFulfilled = useSelector(state =>
    fulfilled(state, `${apis.SUBMIT_TIME_REPORT}Create`),
  );

  const sendTimeSheetDataPending = useSelector(state =>
    pending(state, `${apis.SUBMIT_TIME_REPORT}Create`),
  );

  const sendRatingDataFulfilled = useSelector(state =>
    fulfilled(state, `${apis.RATE_WORK_ASSIGNMENTS}Create`),
  );

  const sendRatingDataPending = useSelector(state =>
    pending(state, `${apis.RATE_WORK_ASSIGNMENTS}Create`),
  );

  const sendExpenseDataFulfilled = useSelector(state =>
    fulfilled(state, `${apis.WORK_ASSIGNMENTS_EXPENSES}Create`),
  );

  const sendExpenseDataPending = useSelector(state =>
    pending(state, `${apis.WORK_ASSIGNMENTS_EXPENSES}Create`),
  );

  useEffect(() => {
    if (
      (sendTimeSheetDataFulfilled ||
        sendRatingDataFulfilled ||
        sendExpenseDataFulfilled) &&
      workAssignment.WorkAssignmentIdentifier
    ) {
      searchSingleWorkAssignment(workAssignment.WorkAssignmentIdentifier);
      setTimeReoprtRequestFulfilled(true);
    }
    return () => {
      dispatch(clean(`${apis.SUBMIT_TIME_REPORT}Create`));
      dispatch(clean(`${apis.RATE_WORK_ASSIGNMENTS}Create`));
      dispatch(clean(`${apis.WORK_ASSIGNMENTS_EXPENSES}Create`));
    };
  }, [
    sendTimeSheetDataFulfilled,
    sendRatingDataFulfilled,
    sendExpenseDataFulfilled,
  ]);

  return {
    sendExpenseData,
    sendRatingData,
    sendTimeSheetData,
    timeReoprtRequestFulfilled,
    timeReoprtRequestPending:
      sendTimeSheetDataPending ||
      sendRatingDataPending ||
      sendExpenseDataPending ||
      singleAssignmentRetrivePending,
  };
};

export default useTimeSheetReport;
