/* eslint-disable arrow-body-style */
import { LOCAL_STORE_TOKEN } from 'constant';

export const initialState = {
  email: null,
  loginInfo: null,
  token: localStorage.getItem(LOCAL_STORE_TOKEN) || null,
};

export const getEmail = (state = initialState) =>
  state.email || initialState.email;

export const getLoginInfo = (state = initialState) =>
  state.loginInfo || initialState.loginInfo;

export const getToken = (state = initialState) => {
  return (
    state.token || localStorage.getItem(LOCAL_STORE_TOKEN) || initialState.token
  );
};
