// eslint-disable-next-line import/prefer-default-export
export const downloadFile = (contentType, base64Content, fileName) => {
  const linkSource = `data:${contentType};base64,${base64Content}`;

  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  // IE10+ : (has Blob, but not a[download] or URL)
  if (navigator.msSaveBlob) {
    const bytes = atob(base64Content);
    const array = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < bytes.length; i++) array.push(bytes.charCodeAt(i));
    navigator.msSaveBlob(
      new Blob([new Uint8Array(array)], { mime: 'text/plain' }),
      fileName,
    );
  }
};
