import utf8 from 'crypto-js/enc-utf8';
import hex from 'crypto-js/enc-hex';
import sha256 from 'crypto-js/sha256';
import {
  pushNotificationAppKey,
  pushNotificationToken,
  pushNotificationVapidPublicKey,
  pushNotificationWebPushId,
} from 'config';
import { localDayjs } from 'utils/dateTimeUtils';
import { LOCAL_STORE_INTERPRETER_NUMBER } from 'constant';
import { sleepTime } from 'utils/miscUtil';
/* eslint-disable */

const config = {
  e: 'https://aswpsdkus.com/notify/v1/ua-sdk.min.js',
  t: 'UA',
  c: {
    vapidPublicKey: pushNotificationVapidPublicKey,
    appKey: pushNotificationAppKey,
    token: pushNotificationToken,
    websitePushId: pushNotificationWebPushId,
  },
};

export async function registerAirshipChannel(interpreterId, cb) {
  const sdk = await UA;
  const plugin = await sdk.plugins.load(
    'html-prompt',
    'https://aswpsdkeu.com/notify/v1/ua-html-prompt.min.js',
    {
      askAgainDelay: 1209600,
      type: 'alert',
      stylesheet: '/pushstyle.css',
      logo: '/images/logo-mobile.png',
      i18n: {
        sv: {
          title: 'Tillåt wepplatsen Tolkportalen att skicka notiser',
          message: '',
          accept: 'Acceptera',
          deny: 'Nej tack',
        },
        en: {
          title: 'Tillåt wepplatsen Tolkportalen att skicka notiser',
          message: '',
          accept: 'Acceptera',
          deny: 'Nej tack',
        },
      },
    },
  );
  await plugin.prompt();
  const channel = await sdk.getChannel();
  channel.namedUser.set(createNamedUserHash(interpreterId));
  await sleepTime(2000);

  await customEvent('log_in', {});
  await sleepTime(2000);
  await cb();
  return 1;
}

export async function unregisterAirShipChannel(interpreterId) {
  return UA.then(async sdk => {
    if (sdk.channel) {
      const airshipCustomEvent = await new sdk.CustomEvent('log_out', 0, {
        ...preDefined,
        user_id: interpreterId,
      }).track();
      sdk.channel.optOut();
    }
  }).catch(console.log);
}

export function loadAirShipModules(n, r, interpreterId) {
  const { e, t, c } = config;
  var i,
    o = 'Promise' in n,
    u = {
      then: function () {
        return u;
      },
      catch: function (n) {
        return n(new Error('Airship SDK Error: Unsupported browser')), u;
      },
    },
    s = o
      ? new Promise(function (n, r) {
          i = function (e, t) {
            e ? r(e) : n(t);
          };
        })
      : u;
  (s._async_setup = function (n) {
    if (o)
      try {
        i(null, n(c));
      } catch (n) {
        i(n);
      }
  }),
    (n[t] = s);
  var a = r.createElement('script');
  (a.src = e),
    (a.async = !0),
    (a.id = '_uasdk'),
    (a.rel = t),
    r.head.appendChild(a);
  a.onload = () => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        // navigator.serviceWorker.register('/push-worker.js').then(
        //   function (registration) {
        //     // Registration was successful
        //     console.log(
        //       'ServiceWorker registration successful with scope: ',
        //       registration.scope,
        //     );
        //   },
        //   function (err) {
        //     // registration failed :(
        //     console.log('ServiceWorker registration failed: ', err);
        //   },
        // );
      });
    }
  };
}

export function createNamedUserHash(userId) {
  const textData = utf8.parse(userId);
  const hash = sha256(textData);
  return hex.stringify(hash).toUpperCase();
}
const preDefined = {
  application: 'tolkaportal', // ("tolka_app"|"tolkaportal"|"kund_portal")

  device: extractDeviceInfo(),

  app_version: '2.2.2', // portal or app version

  user_id: '', // user id
};

function extractDeviceInfo() {
  // get working browser info
  const extractBrowserType = () => {
    if (navigator.userAgent.indexOf('Firefox') > -1) return 'Firefox';
    if (
      /Chrome/.test(navigator.userAgent) &&
      /Google Inc/.test(navigator.vendor)
    )
      return 'Chrome';
    if (
      /Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor)
    )
      return 'Safari';
    if (/Opera/.test(navigator.userAgent) && /Opera/.test(navigator.vendor))
      return 'Opera';
    if (
      navigator.userAgent.indexOf('MSIE') > -1 &&
      /Trident/.test(navigator.userAgent)
    )
      return 'Internet Explorer';

    return 'Unknown';
  };
  const extractOS = () => {
    const ua = navigator.userAgent;
    // get OS info
    if (ua.indexOf('Win') != -1) return 'Windows';
    if (ua.indexOf('Mac') != -1) return 'Macintosh';
    if (ua.indexOf('Android') != -1) return 'Android OS';
    if (ua.indexOf('Linux') != -1) return 'Linux OS';
    if (ua.indexOf('like Mac') != -1) return 'iOS';
    return 'Unknown';
  };
  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  };
  const extractDeviceModel = () => {
    const ua = navigator.userAgent.toString();
    const regex = /\((.*);(.*);(.*);*.*\)/;
    const match = regex.exec(ua);
    if (match) {
      return match[2];
    }
    if (ua.indexOf('iPad') > -1) return 'iPad';
    if (ua.indexOf('iPhone') > -1) return 'iPhone';
    if (ua.indexOf('iPod') > -1) return 'iPod';
    if (ua.indexOf('Kindle') > -1) return 'Kindle';
    if (ua.indexOf('BlackBerry') > -1) return 'BlackBerry';
    if (ua.indexOf('PlayBook') > -1) return 'PlayBook';
    if (ua.indexOf('Android') > -1) return 'Android';
    if (ua.indexOf('Windows Phone') > -1) return 'Windows Phone';
    if (ua.indexOf('Windows') > -1) return 'Windows';
    if (ua.indexOf('Macintosh') > -1) return 'Macintosh';
    if (ua.indexOf('Linux') > -1) return 'Linux';
    return 'Unknown';
  };

  const browser = {
    device_type: getDeviceType(),
    browser: extractBrowserType(),
    device_os: extractOS(),
    device_model: extractDeviceModel(),
    user_agent: navigator.userAgent,
  };
  return browser;
}

export async function customEvent(eventName, eventData) {
  try {
    const sdk = await UA;
    const interpreterNumber = localStorage.getItem(
      LOCAL_STORE_INTERPRETER_NUMBER,
    );
    const airshipCustomEvent = await new sdk.CustomEvent(eventName, 0, {
      ...preDefined,
      user_id: interpreterNumber,
      ...eventData,
    }).track();
    console.log('airshipCustomEvent', airshipCustomEvent);
  } catch (error) {
    console.log('customEvent error', error);
  }
}
