/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useReducer, useCallback } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import {
  convertToDayJsObject,
  convertToSWDayJsObject,
  currentSWTime,
  localDayjs as dayJS,
} from 'utils/dateTimeUtils';
import { useWorkAssignment } from 'hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { resourceDetailReadRequest } from 'store/actions';
import { fromResource } from 'store/selectors';
import { modalTypes } from 'constant';
import { Notification, AssignmentRequestModal } from 'components';
import { notificationHandler } from 'utils/miscUtil';
import { fulfilled } from 'redux-saga-thunk';
import AssignmentListContext, {
  assignmentListReducer,
  assignmentListInitialState,
  sortAssignmentRequestList,
} from 'context/AssignmentListContext';

function NotificationContainer({
  isPage,
  className,
  allSearchedNotifications,
  searchAllNewNotifications,
  isNotificationStatusChanged,
}) {
  const [notificationRequests, setNotificationRequests] = useState([]);
  const [generalNotifications, setGeneralNotification] = useState([]);
  const [
    generalNotificationsDropDown,
    setGeneralNotificationDropDown,
  ] = useState([]);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [assignmentRequestAction, setAssignmentRequestAction] = useState({});
  const [selectedPushNotification, setSelectedPushNotification] = useState(
    null,
  );
  const [
    visibleNotificationDropdown,
    setVisibleNotificationDropdown,
  ] = useState(false);

  const [state, dispatch] = useReducer(
    assignmentListReducer,
    assignmentListInitialState,
  );

  const {
    visibleAssignmentRequestList,
    dateTimeSortedAssignmentRequests,
  } = state;

  const {
    replyToAssignmentRequest,
    assignmentRequestFulfilled,
    assignmentRequestList,
    removeRequestReplied,
    cleanReplyToRequest,
    setNotificationStatus,
    isLoading,
  } = useWorkAssignment();

  const history = useHistory();
  const { location } = history;

  useEffect(() => {
    document.title =  `Notiser - TFV Tolkportal`;
  }, []);
  useEffect(async () => {
    if (isPage) {
      if (location.search) {
        const notificationId = notificationHandler(location);
        await searchAllNewNotifications(notificationId);
      } else {
        await searchAllNewNotifications();
      }
    }
  }, []);

  useEffect(() => {
    dispatch([
      'setVisibleAssignmentRequestList',
      sortAssignmentRequestList(
        assignmentRequestList.filter(
          x =>
            convertToSWDayJsObject(x.DatetimeExpiration).diff(
              currentSWTime(),
              'seconds',
            ) > 0,
        ),
        dateTimeSortedAssignmentRequests,
      ),
    ]);
  }, [assignmentRequestList]);

  useEffect(() => {
    if (isNotificationStatusChanged) {
      searchAllNewNotifications();
    }
  }, [isNotificationStatusChanged]);

  useEffect(() => {
    if (
      location.search &&
      isPage &&
      allSearchedNotifications?.Notifications?.length > 0
    ) {
      const notificationId = notificationHandler(location);
      const matchNotification = allSearchedNotifications.Notifications.find(
        x => x.NotificationIdentifier === notificationId,
      );
      if (matchNotification) {
        const identifier = matchNotification?.NotificationTypeIdentifier;
        const workAssignmentIDs =
          matchNotification?.WorkAssignmentIdentifiers || [];

        // eslint-disable-next-line no-use-before-define
        if (matchNotification?.NotificationTypeIdentifier !== '') {
          handleCardClick(
            identifier,
            matchNotification?.NotificationTypeIdentifier !== 'Request'
              ? workAssignmentIDs
              : workAssignmentIDs[0].WorkAssignmentIdentifier,
            notificationId,
          );
        } else {
          setSelectedPushNotification(matchNotification);
        }
      } else if (allSearchedNotifications.Notifications.length > 0) {
        history.replace();
      }
    }
  }, [allSearchedNotifications]);

  const extractRequestMatchingTheNotification = (
    notificationWorkAssignmentId,
    notificationIdentifier,
  ) => {
    if (isEmpty(visibleAssignmentRequestList) || !notificationWorkAssignmentId)
      return null;
    const matchingRequest = visibleAssignmentRequestList.filter(
      assignmentRequest =>
        notificationWorkAssignmentId ===
        assignmentRequest.WorkAssignmentReference.WorkAssignmentIdentifier,
    );

    if (matchingRequest.length === 0) return null;
    const assignmentRequest = matchingRequest[0];
    return {
      ...assignmentRequest,
      OrderStatus: 'inquiry',
      ArticleName: assignmentRequest.WorkAssignmentReference.ArticleName,
      DatetimeExpiration: assignmentRequest.DatetimeExpiration,
      DatetimeOrderFrom:
        assignmentRequest.WorkAssignmentReference.DatetimeOrderFrom,
      DatetimeOrderTo:
        assignmentRequest.WorkAssignmentReference.DatetimeOrderTo,
      Skill: assignmentRequest.WorkAssignmentReference.Skill,
      Customer: {
        CustomerName:
          assignmentRequest.WorkAssignmentReference.Customer.CustomerName,
      },
      ResourceRequestIdentifier: assignmentRequest.ResourceRequestIdentifier,
      WorkAssignmentIdentifier:
        assignmentRequest.WorkAssignmentReference.WorkAssignmentIdentifier,
      NotificationIdentifier: notificationIdentifier,
    };
  };

  const getAssignmentRequestFromNotification = notifications => {
    const assignmentRequests = [];
    const generalNotificationsSub = [];
    if (!isEmpty(notifications)) {
      notifications
        .sort((a, b) => dayJS(b.DatetimeSent).diff(a.DatetimeSent))
        .map(item => {
          if (
            item.NotificationTypeIdentifier &&
            item.NotificationTypeIdentifier === 'Request'
          ) {
            const workAssignmentIDs = item.WorkAssignmentIdentifiers;
            workAssignmentIDs.forEach(workAssignmentID => {
              const assignmentRequest = extractRequestMatchingTheNotification(
                workAssignmentID.WorkAssignmentIdentifier,
                item.NotificationIdentifier,
              );
              if (assignmentRequest) {
                assignmentRequests.push(assignmentRequest);
              } else {
                generalNotificationsSub.push({ ...item });
              }
            });
          } else {
            generalNotificationsSub.push({
              ...item,
            });
          }
          return null;
        });
    }

    const uniqueRequests = assignmentRequests.reduce((unique, o) => {
      if (
        !unique.some(
          request =>
            request.WorkAssignmentIdentifier === o.WorkAssignmentIdentifier,
        )
      ) {
        unique.push(o);
      }
      return unique;
    }, []);

    const limitedNotifications =
      generalNotificationsSub.length > 30
        ? generalNotificationsSub.slice(0, 30)
        : generalNotificationsSub;
    setGeneralNotificationDropDown(limitedNotifications);

    if (!isPage) {
      setGeneralNotification([]);
      setNotificationRequests(uniqueRequests);
    } else {
      setGeneralNotification(generalNotificationsSub);
      setNotificationRequests(uniqueRequests);
    }
  };

  useEffect(() => {
    getAssignmentRequestFromNotification(
      allSearchedNotifications.Notifications,
    );
  }, [allSearchedNotifications, visibleAssignmentRequestList]);

  useEffect(() => {
    if (assignmentRequestFulfilled) {
      removeRequestReplied(assignmentRequestAction.requestId);
      setAssignmentRequestAction({});
      cleanReplyToRequest();
    }
  }, [assignmentRequestFulfilled]);

  const assignmentRequestHandler = (
    action,
    requestId,
    workAssignmentId,
    notificationIdentifier,
  ) => {
    if (action) {
      setShowApproveModal(!showApproveModal);
    } else {
      setShowRejectModal(!showRejectModal);
    }
    setAssignmentRequestAction({
      action,
      requestId,
      workAssignmentId,
      notificationIdentifier,
    });
  };

  const handleAssignmentRequestModalActions = (action, modalType) => {
    const query = {
      IsAcceptingRequest: assignmentRequestAction.action,
      ResourceRequestIdentifier: assignmentRequestAction.requestId,
    };

    if (modalType === modalTypes.APPROVE) {
      if (action === modalTypes.APPROVE) {
        replyToAssignmentRequest(query);
        setShowApproveModal(!showApproveModal);
      } else {
        setShowApproveModal(!showApproveModal);
      }
    } else if (action === modalTypes.APPROVE) {
      setShowRejectModal(!showRejectModal);
    } else {
      replyToAssignmentRequest(query);
      setShowRejectModal(!showRejectModal);
    }
  };

  const renderAssignmentRequestModals = () => {
    if (showApproveModal) {
      return (
        <AssignmentRequestModal
          isVisible={showApproveModal}
          modalType={modalTypes.APPROVE}
          onClose={() => setShowApproveModal(!showApproveModal)}
          title="Godkänn allmänna villkor"
          showCheckBox
          rejectButtonLabel="Avbryt"
          acceptanceButtonLabel="Acceptera uppdraget"
          onClick={action => {
            handleAssignmentRequestModalActions(action, modalTypes.APPROVE);
          }}
        >
          <p>
            Genom att acceptera uppdraget godkänner du våra allmänna villkor och
            riktlinjer för tolkuppdrag
          </p>
        </AssignmentRequestModal>
      );
    }
    if (showRejectModal) {
      return (
        <AssignmentRequestModal
          isVisible={showRejectModal}
          modalType={modalTypes.REJECT}
          onClose={() => setShowRejectModal(!showRejectModal)}
          title="Neka förfrågan"
          acceptanceButtonLabel="Neka förfrågan"
          rejectButtonLabel="Avbryt"
          onClick={action => {
            handleAssignmentRequestModalActions(action, modalTypes.REJECT);
          }}
        >
          <p>
            Är du säker på att du vill neka uppdraget? Om du nekar många
            förfrågningar så kan det komma att påverka hur många framtida
            förfrågningar du får
          </p>
        </AssignmentRequestModal>
      );
    }
    return null;
  };

  const handleCardClick = (identifier, workAssignmentIDs, notificationId) => {
    switch (identifier) {
      case 'BirthdayGreeting':
        break;

      case 'Request':
        history.push({
          pathname: '/my-assignments',
          state: { workAssignmentId: workAssignmentIDs, status: 'inquiry' },
        });
        break;

      case 'Cancellation':
        history.push({
          pathname: '/my-assignments',
          state: {
            workAssignmentId: workAssignmentIDs[0].WorkAssignmentIdentifier,
            status: 'Avbokad',
          },
        });
        break;

      case 'TimeReport':
        history.push({
          pathname: '/my-assignments',
          state: {
            workAssignmentId: '',
            status: 'Utförd',
          },
        });
        break;

      case 'generalRequest':
        history.push({
          pathname: '/my-assignments',
          state: {
            workAssignmentId: workAssignmentIDs[0].WorkAssignmentIdentifier,
            status: 'generalRequest',
          },
        });
        break;

      default:
        history.push({
          pathname: '/my-assignments',
          state: {
            workAssignmentId: workAssignmentIDs[0].WorkAssignmentIdentifier,
            status: 'Avbokad',
          },
        });
        break;
    }

    setNotificationStatus(notificationId);
  };

  const redirectToNotification = () => {
    setVisibleNotificationDropdown(false);
    history.push('/notifications');
  };

  const onShowInCalendar = notificationIdentifier => {
    setNotificationStatus(notificationIdentifier);
  };
  return (
    <>
      {renderAssignmentRequestModals()}
      <Notification
        generalNotifications={generalNotifications}
        generalNotificationsDropDown={generalNotificationsDropDown}
        requests={notificationRequests}
        isPage={isPage}
        className={className}
        onAccept={assignmentRequestHandler}
        onReject={assignmentRequestHandler}
        handleCardClick={handleCardClick}
        notificationCount={
          generalNotificationsDropDown.filter(item => !item.IsRead).length +
          notificationRequests.length
        }
        isLoading={isLoading}
        visibleNotificationDropdown={visibleNotificationDropdown}
        setVisibleNotificationDropdown={setVisibleNotificationDropdown}
        redirectToNotificationPage={redirectToNotification}
        onShowInCalendar={onShowInCalendar}
      />
    </>
  );
}

NotificationContainer.propTypes = {
  allSearchedNotifications: PropTypes.shape({
    Notifications: PropTypes.shape([]),
    NumberOfUnreadNotifications: PropTypes.number,
  }),
  isPage: PropTypes.bool,
  className: PropTypes.shape({
    iconClass: PropTypes.string,
    PageClass: PropTypes.string,
  }),
  searchAllNewNotifications: PropTypes.func,
  isNotificationStatusChanged: PropTypes.bool,
};
NotificationContainer.defaultProps = {
  allSearchedNotifications: {
    Notifications: [],
    NumberOfUnreadNotifications: 0,
  },
  isPage: false,
  className: {
    iconClass: '',
    pageClass: '',
  },
  searchAllNewNotifications: () => {},
  isNotificationStatusChanged: false,
};

const mapStateToProps = state => ({
  allNotifications: fromResource.getList(state, 'allNotifications'),
  allSearchedNotifications:
    fromResource.getDetail(state, 'searchAllNotifications') || {},
  isNotificationStatusChanged: fulfilled(
    state,
    'SetNotificationReadStatusCreate',
  ),
});

const mapDispatchToProps = dispatch => ({
  searchAllNewNotifications: notificationId => {
    dispatch(
      resourceDetailReadRequest('searchAllNotifications', {
        DatetimeSentFrom: null,
        DoOnlyIncludeUnreadNotifications: false,
        OrderIdentifier: null,
        WorkAssignmentIdentifier: null,
        NotificationIdentifier: notificationId,
      }),
      true,
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationContainer);
