import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DefaultAvailablity, Spinner } from 'components';
import { fulfilled, rejected, pending, clean } from 'redux-saga-thunk';
import {
  resourceListReadRequest,
  resourceDeleteRequest,
  resourceCreateRequest,
} from 'store/resource/actions';
import { fromResource } from 'store/selectors';
import { localDayjs as dayjs } from 'utils/dateTimeUtils';
// eslint-disable-next-line camelcase
const INITIAL_DEFAUlT_AVAILABILITIES = [
  {
    keyId: 'monday',
    name: 'monday',
    text: 'Måndag',
    state: 'inactive',
    showCopyTimeToAll: true,
  },
  { name: 'tuesday', keyId: 'tuesday', text: 'Tisdag', state: 'inactive' },
  { name: 'wednesday', keyId: 'wednesday', text: 'Onsdag', state: 'inactive' },
  { name: 'thursday', keyId: 'thursday', text: 'Torsdag', state: 'inactive' },
  { name: 'friday', keyId: 'friday', text: 'Fredag', state: 'inactive' },
  { name: 'saturday', keyId: 'saturday', text: 'Lördag', state: 'inactive' },
  { name: 'sunday', keyId: 'sunday', text: 'Söndag', state: 'inactive' },
];

const mapCRMAvailabilityObjectTodate = crmObject => {
  const { TimeTo, TimeFrom, isAvailable } =
    crmObject?.AvailabilityTimes && crmObject?.AvailabilityTimes.length > 0
      ? crmObject.AvailabilityTimes[0]
      : { TimeTo: null, TimeFrom: null, isAvailable: true };

  const mappedAvailability = {
    name: '',
    startTime: TimeFrom ? dayjs(TimeFrom, 'HH:mm') : null,
    endTime: TimeTo ? dayjs(TimeTo, 'HH:mm') : null,
    isAvailable: isAvailable || true,
    SettingIdentifier: crmObject?.SettingIdentifier || null,
    state: 'active',
  };

  if (crmObject.IsFriday) {
    mappedAvailability.name = 'friday';
  } else if (crmObject.IsMonday) {
    mappedAvailability.name = 'monday';
  } else if (crmObject.IsSaturday) {
    mappedAvailability.name = 'saturday';
  } else if (crmObject.IsSunday) {
    mappedAvailability.name = 'sunday';
  } else if (crmObject.IsThursday) {
    mappedAvailability.name = 'thursday';
  } else if (crmObject.IsTuesday) {
    mappedAvailability.name = 'tuesday';
  } else if (crmObject.IsWednesday) {
    mappedAvailability.name = 'wednesday';
  }
  return mappedAvailability;
};

const findMatchingDayfromList = (dayName, list) => {
  const filtered = list.filter(x => x.name === dayName);
  return filtered.length > 0 ? filtered[0] : null;
};

const mapCRMObjectToLocalState = (crmList = []) => {
  const mappedCrmList = crmList.map(x => mapCRMAvailabilityObjectTodate(x));
  const modifiedDefaultAvailabilities = INITIAL_DEFAUlT_AVAILABILITIES.map(
    x => {
      const findMatchingDay = findMatchingDayfromList(x.name, mappedCrmList);
      if (findMatchingDay) {
        return {
          ...x,
          isValid: true,
          ...findMatchingDay,
          keyId: `${x.name}-${dayjs().format('HH:mm')}`,
        };
      }
      return {
        ...x,
        isValid: true,
        keyId: `${x.name}-${dayjs().format('HH:mm')}`,
        startTime: dayjs('08:00', 'HH:mm'),
        endTime: dayjs('17:00', 'HH:mm'),
      };
    },
  );
  return modifiedDefaultAvailabilities;
};

const mapDayToCrmformat = day => {
  const returnObj = {
    IsFriday: false,
    IsMonday: false,
    IsSaturday: false,
    IsSunday: false,
    IsThursday: false,
    IsTuesday: false,
    IsWednesday: false,
  };
  switch (day) {
    case 'monday':
      returnObj.IsMonday = true;
      break;
    case 'tuesday':
      returnObj.IsTuesday = true;
      break;
    case 'wednesday':
      returnObj.IsWednesday = true;
      break;
    case 'thursday':
      returnObj.IsThursday = true;
      break;
    case 'friday':
      returnObj.IsFriday = true;
      break;
    case 'saturday':
      returnObj.IsSaturday = true;
      break;
    case 'sunday':
      returnObj.IsSunday = true;
      break;
    default:
      break;
  }
  return returnObj;
};

const processlocalAvailabilitiesToCrmObj = localState => {
  const mappedObj = localState.map(x => ({
    SettingIdentifier: x.SettingIdentifier || null,
    AvailabilityTimesInput: [
      {
        IsAvailable: true,
        TimeFrom: x.startTime.format('HH:mm'),
        TimeTo: x.endTime.format('HH:mm'),
      },
    ],
    IsUnavailableAllDay: false,
    ...mapDayToCrmformat(x.name),
  }));
  return { AvailabilitySettingsInput: mappedObj };
};

const DefaultAvailablityContainer = ({
  defaultAvailabilities,
  isReadPending,
  isUpdatePending,
  isDeletePending,
  isUpdateFulfilled,
  isDeleteFulfilled,
  onGetDefaultAvailability,
  onGetDefaultAvailabilityBackground,
  onDeleteAvailability,
  onCreateUpdateAvailability,
  onCleanState,
}) => {
  const [localDefaultAvailability, setLocalDefaultAvailability] = useState(
    INITIAL_DEFAUlT_AVAILABILITIES,
  );

  useEffect(() => {
    document.title = `Schemalagd tillgänglighet - TFV Tolkportal`;
    if (defaultAvailabilities.length === 0) {
      onGetDefaultAvailability();
    }
  }, []);

  useEffect(() => {
    setLocalDefaultAvailability(
      mapCRMObjectToLocalState(defaultAvailabilities || []),
    );
  }, [defaultAvailabilities]);

  useEffect(() => {
    if (isUpdateFulfilled) {
      onGetDefaultAvailability();
      onCleanState('updateDefaultAvailabilitySettingCreate');
    }
  }, [isUpdateFulfilled]);

  useEffect(() => {
    if (isDeleteFulfilled) {
      onCleanState('deleteAvailabilitySettingDelete');
    }
  }, [isDeleteFulfilled]);

  const handleAvailabilityChange = ({ day, start, end, isValid }) => {
    const modifiedAvailabilities = localDefaultAvailability.map(x =>
      x.name === day
        ? {
            ...x,
            startTime: start,
            endTime: end,
            isValid,
          }
        : x,
    );
    setLocalDefaultAvailability(modifiedAvailabilities);
  };

  const handleCopyTimeToAll = ({ start, end, isValid }) => {
    const modifiedAvailabilities = localDefaultAvailability.map(x => ({
      ...x,
      startTime: start,
      endTime: end,
      isValid,
      state: 'active',
    }));
    setLocalDefaultAvailability(modifiedAvailabilities);
  };

  const handleAddAvailability = day => {
    const modifiedAvailabilities = localDefaultAvailability.map(x =>
      x.name === day
        ? {
            ...x,
            state: 'active',
          }
        : x,
    );
    setLocalDefaultAvailability(modifiedAvailabilities);
  };

  const handleDelete = (day, identifier) => {
    const modifiedAvailabilities = localDefaultAvailability.map(x =>
      x.name === day
        ? {
            ...x,
            state: 'inactive',
            SettingIdentifier: null,
          }
        : x,
    );
    setLocalDefaultAvailability(modifiedAvailabilities);
    if (identifier) {
      onDeleteAvailability({ SettingIdentifier: identifier });
    }
  };

  const handleSaveAvailabilities = () => {
    const validActiveItems = localDefaultAvailability.filter(
      x => x.state === 'active' && x.isValid,
    );
    if (validActiveItems.length > 0) {
      onCreateUpdateAvailability(
        processlocalAvailabilitiesToCrmObj(validActiveItems),
      );
    }
  };

  return (
    <div>
      <DefaultAvailablity
        data={localDefaultAvailability}
        onChange={handleAvailabilityChange}
        onAddAvailability={handleAddAvailability}
        onDelete={handleDelete}
        onCopyTimeToAll={handleCopyTimeToAll}
        onSave={handleSaveAvailabilities}
      />
      {(isReadPending || isUpdatePending || isDeletePending) && <Spinner />}
    </div>
  );
};

DefaultAvailablityContainer.propTypes = {
  defaultAvailabilities: PropTypes.arrayOf(PropTypes.shape({})),
  isReadPending: PropTypes.bool,
  isUpdatePending: PropTypes.bool,
  isDeletePending: PropTypes.bool,
  isUpdateFulfilled: PropTypes.bool,
  isDeleteFulfilled: PropTypes.bool,
  onCleanState: PropTypes.func,
  onGetDefaultAvailability: PropTypes.func,
  onGetDefaultAvailabilityBackground: PropTypes.func,
  onDeleteAvailability: PropTypes.func,
  onCreateUpdateAvailability: PropTypes.func,
};

DefaultAvailablityContainer.defaultProps = {
  defaultAvailabilities: [],
  isReadPending: false,
  isUpdatePending: false,
  isDeletePending: false,
  isUpdateFulfilled: false,
  isDeleteFulfilled: false,
  onCleanState: () => {},
  onGetDefaultAvailability: () => {},
  onGetDefaultAvailabilityBackground: () => {},
  onDeleteAvailability: () => {},
  onCreateUpdateAvailability: () => {},
};

const mapDispatchToProps = dispatch => ({
  onGetDefaultAvailability: params => {
    dispatch(
      resourceListReadRequest(
        'getAvailabilitySetting',
        'DefaultAvailabilitySettings',
        params,
        true,
      ),
    );
  },
  onGetDefaultAvailabilityBackground: params => {
    dispatch(
      resourceListReadRequest(
        'getAvailabilitySettingBackground',
        'DefaultAvailabilitySettings',
        params,
        true,
      ),
    );
  },
  onDeleteAvailability: params => {
    dispatch(resourceDeleteRequest('deleteAvailabilitySetting', params));
  },
  onCreateUpdateAvailability: params => {
    dispatch(resourceCreateRequest('updateDefaultAvailabilitySetting', params));
  },
  onCleanState: resource => {
    dispatch(clean(resource));
  },
});

const mapStateToProps = state => {
  const defaultAvailabilities =
    fromResource.getList(state, 'getAvailabilitySetting') || [];

  return {
    isReadPending: pending(state, 'getAvailabilitySettingListRead'),
    isUpdatePending: pending(state, 'updateDefaultAvailabilitySettingCreate'),
    isUpdateFulfilled: fulfilled(
      state,
      'updateDefaultAvailabilitySettingCreate',
    ),
    isDeletePending: pending(state, 'deleteAvailabilitySettingDelete'),
    isDeleteFulfilled: fulfilled(state, 'deleteAvailabilitySettingDelete'),
    defaultAvailabilities,
    isUpdateFailed: rejected(state, 'updateDefaultAvailabilitySettingCreate'),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefaultAvailablityContainer);
