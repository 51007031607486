/* eslint-disable no-nested-ternary */
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Field, Button, Translate } from 'components';
import './style.scss';

const ForgotPasswordForm = ({ onFormSubmit }) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm();
  const onSubmit = data => onFormSubmit(data);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="tv-login-form__container"
    >
      <div className="tv-login-form__header">
        <Translate content="login.signInToContinue" />
      </div>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Field
            type="email"
            aria-label="Email"
            onChange={onChange}
            onBlur={onBlur}
            name="email"
            value={value}
            placeholder={Translate({ content: 'general.email' })}
            label={Translate({ content: 'general.email' })}
            autoFocus
            error={
              errors.email
                ? errors?.email?.type === 'required'
                  ? Translate({
                      content: 'validator.isRequired',
                      params: { name: Translate({ content: 'general.email' }) },
                    })
                  : errors.email.message
                : null
            }
          />
        )}
        type="email"
        name="email"
        rules={{
          required: true,
          pattern: {
            value: /\S+@\S+.\S+/,
            message: Translate({
              content: 'validator.isNotValid',
              params: { name: Translate({ content: 'general.email' }) },
            }),
          },
        }}
      />
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Field
            onChange={onChange}
            name="interpreterId"
            aria-label="Interpreter id"
            onBlur={onBlur}
            value={value}
            placeholder={Translate({ content: 'general.interpreterId' })}
            label={Translate({ content: 'general.interpreterId' })}
            error={
              errors.interpreterId
                ? errors?.interpreterId?.type === 'required'
                  ? Translate({
                      content: 'validator.isRequired',
                      params: {
                        name: Translate({ content: 'general.interpreterId' }),
                      },
                    })
                  : errors.interpreterId.message
                : null
            }
          />
        )}
        type="text"
        name="interpreterId"
        rules={{
          required: true,
          pattern: {
            value: /^[0-9]+$/,
            message: Translate({
              content: 'validator.isNotValid',
              params: {
                name: Translate({ content: 'general.interpreterId' }),
              },
            }),
          },
        }}
      />
      <Button type="submit" label="Skicka" />
    </form>
  );
};

ForgotPasswordForm.propTypes = {
  onFormSubmit: PropTypes.func,
};
ForgotPasswordForm.defaultProps = {
  onFormSubmit: () => {},
};

export default ForgotPasswordForm;
