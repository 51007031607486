import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconLink,
  LogoLink,
  NavigationLink,
  ProfileLink,
  Translate,
  SettingMenuDropdown,
  Notification,
  ReleaseMessage,
} from 'components';
import { AvailabilityNowContainer, NotificationContainer } from 'containers';
import './style.scss';
import { useWorkAssignment } from 'hooks';

const TopNavBar = props => {
  const { workAssignmentList, assignmentRequestList } = useWorkAssignment();
  const { customerName, onLogout, navigationRef } = props;
  const dropMenuNL = useRef(null);
  const dropMenuEL = useRef(null);
  const [showTranslation, setShowTranslation] = useState(false);
  const [showAssignmentsRequests, setShowAssignmentsRequests] = useState(false);

  useEffect(() => {
    const haveTranslationOrders = workAssignmentList.filter(
      x => x.IsTranslation === true,
    );
    const haveAssignments = workAssignmentList.filter(
      i => i.IsTranslation === false,
    );
    if (haveTranslationOrders.length > 0) setShowTranslation(true);
    if (haveAssignments.length > 0 || assignmentRequestList.length > 0)
      setShowAssignmentsRequests(true);
  }, [workAssignmentList]);
  return (
    <div
      className="tv-topnavbar__container d-sm-none d-md-block d-none d-sm-block"
      id="navigation"
      ref={navigationRef}
    >
      <div className="d-flex tv-topnavbar__flex">
        <div className="mr-auto mr-5">
          <LogoLink />
        </div>
        <ReleaseMessage />

        <div className="mr-4">
          <AvailabilityNowContainer />
        </div>
        <div>
          <div className="navigation tv-navigationlink">
            <div className="navbar-expand">
              <div className="navbar-nav justify-content-end">
                <NavigationLink
                  exact
                  to="/"
                  className="nav-item"
                  text={Translate({ content: 'navigation.calendar' })}
                />
                {showTranslation && (
                  <NavigationLink
                    exact
                    to="/translations"
                    className="nav-item"
                    text={Translate({ content: 'navigation.translation' })}
                  />
                )}
                {showAssignmentsRequests && (
                  <NavigationLink
                    to="/my-assignments"
                    className="nav-item"
                    text={Translate({
                      content: 'navigation.assignmentAndInquiries',
                    })}
                  />
                )}
                <NavigationLink
                  to="/contact-and-faq"
                  className="nav-item"
                  text={Translate({ content: 'navigation.contact' })}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <NotificationContainer
            key="NotificationContainerMenu"
            className={{ iconClass: 'tv-topnavbar__noti-icon' }}
            innerRef={dropMenuNL}
          />
        </div>
        <div>
          <SettingMenuDropdown
            button={<ProfileLink />}
            innerRef={dropMenuEL}
            text={`Hej ${customerName || 'Användare'}`}
          >
            <div className="tv-avatar-list__menu">
              <div
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--customer-name"
                tabIndex="-1"
              >
                {`Hej ${customerName || 'Användare'}`}
              </div>

              <NavigationLink
                to="/settings"
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--settings"
                text="Min profil"
              />
              <NavigationLink
                onClick={onLogout}
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--logout"
                text="Logga ut"
              />
            </div>
          </SettingMenuDropdown>
        </div>
      </div>
    </div>
  );
};

TopNavBar.propTypes = {
  customerName: PropTypes.string,
  onLogout: PropTypes.func,
  navigationRef: PropTypes.shape({}),
};

TopNavBar.defaultProps = {
  customerName: '',
  onLogout: () => {},
  navigationRef: {},
};

export default TopNavBar;
