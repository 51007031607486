import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { currentDate } from 'utils/dateTimeUtils';
// import { IconButton } from 'components'

const CurrentTime = ({ className }) => {
  const timeRef = useRef();
  useEffect(() => {
    const getTime = setInterval(() => {
      timeRef.current.innerText = currentDate().format('HH:mm');
    }, 60000);
    return () => {
      clearInterval(getTime);
    };
  }, []);
  return (
    <>
      <div className={`mt-2 ${className}`}>
        <i className="fa fa-clock mr-2" />
        {/* <IconButton iconName="clock" className="mr-2"/> */}
        <span ref={timeRef}>{currentDate().format('HH:mm')}</span>
      </div>
    </>
  );
};
CurrentTime.propTypes = {
  className: PropTypes.string,
};
CurrentTime.defaultProps = {
  className: '',
};

export default CurrentTime;
