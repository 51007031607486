import { put, call, takeEvery } from 'redux-saga/effects';
import { getErrorMessage, tfvLog } from 'utils/miscUtil';
import * as actions from './actions';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* displayErrorOnTimer(payload) {
  try {
    const determinedError = payload ? getErrorMessage(payload) : null;
    yield put(actions.errorAddToStore({ errorMessage: determinedError }));
    yield delay(500);
    yield put(actions.errorRemoveFromStore({ errorMessage: determinedError }));
  } catch (e) {
    tfvLog('not able to determine the error message');
  }
}

export function* watchErrorReciver({ payload }) {
  yield call(displayErrorOnTimer, payload);
}

export default function* () {
  yield takeEvery(actions.ERROR_RECEIVE, watchErrorReciver);
}
