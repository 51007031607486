/* eslint-disable*/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'components';
import './style.scss';

const ContentPanel = props => (
  <div className="tv-general-condition single">{props.children}</div>
);

const ContentPageWrap = props => (
  <div className="tv-general-condition__page-wrap">{props.children}</div>
);

const ContentPage = props => (
  <div className="tv-general-condition__page">{props.children}</div>
);

const ContentTitle = props => (
  <h4 className="tv-general-condition__heading1">{props.children}</h4>
);

const ContentHeading = props => (
  <p className="tv-general-condition__heading2">{props.children}</p>
);

const ContentHeading2 = props => (
  <p className={`tv-general-condition__heading__new ${props.className}`}>
    {props.children}
  </p>
);

const ContentParagraph = props => (
  <p className="tv-general-condition__paragraph">{props.children}</p>
);

const ContentParagraph2 = props => (
  <p>
    <a
      className={`tv-general-condition__paragraph__new ${props.className}`}
      href={props.link}
      target="_blank"
    >
      {props.children}
    </a>
  </p>
);

const ContentSection = props => (
  <div className="tab-panel-section">{props.children}</div>
);

const ContentSubSection = props => (
  <div className="tab-panel-section__sub">{props.children}</div>
);

function GeneralCondition(props) {
  useEffect(() => {
    document.title = `Allmänna villkor - TFV Tolkportal`;
  }, []);
  return (
    <div className="tv-general-condition">
      <div className="tv-general-condition__title">
        {Translate({ content: 'settings.generalCondition' })}
      </div>
      <ContentPanel>
        <ContentPageWrap>
          <ContentPage>
            <ContentSection>
              <ContentHeading2>
                Genom att acceptera uppdrag godkänner du våra allmänna villkor
                och riktlinjer.
              </ContentHeading2>
            </ContentSection>

            <ContentSection>
              <ContentHeading2 className="tv-general-condition__heading__new-2 mt-5">
                Tolk
              </ContentHeading2>
              <ContentParagraph2 link="https://www.tolkformedlingvast.se/tolk/registrerad-tolk.html">
                Registrerad tolk
              </ContentParagraph2>
              <ContentParagraph2 link="https://www.tolkformedlingvast.se/tolk/registrerad-tolk/yrkesetiska-regler.html">
                Yrkesetiska regler
              </ContentParagraph2>
              <ContentParagraph2 link="https://www.tolkformedlingvast.se/tolk/registrerad-tolk/forfragningar-om-uppdrag.html">
                Förfrågningar om uppdrag
              </ContentParagraph2>
              <ContentParagraph2 link="https://www.tolkformedlingvast.se/tolk/registrerad-tolk/utfora-uppdrag.html">
                Utföra uppdrag
              </ContentParagraph2>
              <ContentParagraph2 link="https://www.tolkformedlingvast.se/tolk/registrerad-tolk/efter-utfort-uppdrag.html">
                Efter utfört uppdrag
              </ContentParagraph2>
              <ContentParagraph2 link="https://www.tolkformedlingvast.se/tolk/arvoden.html">
                Arvoden
              </ContentParagraph2>
            </ContentSection>

            <ContentSection>
              <ContentHeading2 className="tv-general-condition__heading__new-2 mt-5">
                Översättare
              </ContentHeading2>
              <ContentParagraph2 link="https://www.tolkformedlingvast.se/oversattare/registrerad-oversattare.html">
                Registrerad översättare
              </ContentParagraph2>
              <ContentParagraph2 link="https://www.tolkformedlingvast.se/oversattare/registrerad-oversattare/yrkesetiska-regler.html">
                Yrkesetiska regler
              </ContentParagraph2>
              <ContentParagraph2 link="https://www.tolkformedlingvast.se/oversattare/registrerad-oversattare/forfragningar-om-uppdrag.html">
                Förfrågningar om uppdrag
              </ContentParagraph2>
              <ContentParagraph2 link="https://www.tolkformedlingvast.se/oversattare/registrerad-oversattare/utfora-uppdrag.html">
                Utföra uppdrag
              </ContentParagraph2>
              <ContentParagraph2 link="https://www.tolkformedlingvast.se/oversattare/registrerad-oversattare/efter-utfort-uppdrag.html">
                Efter utfört uppdrag
              </ContentParagraph2>
              <ContentParagraph2 link="https://www.tolkformedlingvast.se/oversattare/arvoden.html">
                Arvoden
              </ContentParagraph2>
            </ContentSection>
          </ContentPage>
        </ContentPageWrap>
      </ContentPanel>
    </div>
  );
}

GeneralCondition.propTypes = {};

export default GeneralCondition;
