/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  filterEventsForTheDateRange,
  useCalendar,
  extractSemesterDay,
} from 'context/CalendarContext';
import { daysOfWeek } from 'constant';
import { MonthDay } from 'components';
import './style.scss';

function DaysOfWeekHeader({ days = [] }) {
  return (
    <tr className="tv-month-calendar__header">
      {days.map(day => (
        <th className="" key={`month-calender-${day}`}>
          {day}
        </th>
      ))}
    </tr>
  );
}

function MonthWeek({ row, month }) {
  const {
    list: workAssignments,
    assignmentRequestList,
    handleAssignmentPillClick,
    selectedCalendarMonthPill,
    semesterAllocationList,
    onActiveSemesterAllocationClick,
  } = useCalendar();
  const [workAssignmentsList, setworkAssignmentsList] = useState([
    ...workAssignments,
    ...assignmentRequestList,
  ]);

  useEffect(() => {
    setworkAssignmentsList([...workAssignments, ...assignmentRequestList]);
  }, [workAssignments, assignmentRequestList]);
  return (
    <tr>
      {[...Array(7).keys()].map(colIndex => {
        const date = month.start.add(row, 'week').add(colIndex, 'day');
        return (
          <MonthDay
            key={`${date.format('YYYYMMMMDD')}`}
            date={month.start.add(row, 'week').add(colIndex, 'day')}
            col={colIndex}
            eventsForDay={filterEventsForTheDateRange(
              workAssignmentsList,
              date.startOf('day'),
              date.endOf('day'),
            )}
            isActive={
              selectedCalendarMonthPill?.date
                ? date.isSame(selectedCalendarMonthPill.date, 'day')
                : false
            }
            handleAssignmentPillClick={handleAssignmentPillClick}
            selectedCalendarMonthPill={selectedCalendarMonthPill}
            semesterDay={extractSemesterDay(semesterAllocationList, date)}
            onActiveSemesterAllocationClick={onActiveSemesterAllocationClick}
            row={row}
          />
        );
      })}
    </tr>
  );
}

function MonthCalendar() {
  const { calendarDateRange } = useCalendar();
  const noOfWeeks =
    calendarDateRange.end.diff(calendarDateRange.start, 'week') + 1;
  return (
    <>
      <table className="tv-month-calendar__table container-wrapper">
        <thead>
          <DaysOfWeekHeader days={daysOfWeek.se} />
        </thead>
        <tbody>
          {[...Array(noOfWeeks).keys()].map(rowIndex => (
            <MonthWeek
              key={`MonthWeeks-${calendarDateRange.start.format(
                'MMMM',
              )}-${rowIndex}`}
              row={rowIndex}
              month={calendarDateRange}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}

export default MonthCalendar;
