import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const StarRating = ({
  onChange,
  outOf,
  defaultValue,
  viewMode,
  starFontSize,
}) => {
  const setOutof = () => {
    const outOfVal = outOf || 5;
    const starsList = [];

    for (let i = 0; i < outOfVal; i += 1) {
      starsList.push(i + 1);
    }
    return starsList;
  };

  const [stars, setStars] = useState(setOutof);
  const [rating, setRating] = useState(defaultValue);
  const [hovered, setHovered] = useState(0);
  const [selectedIcon, setSelectedIcon] = useState(
    <i
      className="material-icons material-icons-outlined"
      style={{ fontSize: starFontSize }}
    >
      star
    </i>,
  );

  const [deselectedIcon, setDeselectedIcon] = useState(
    <i
      className="material-icons material-icons-outlined"
      style={{ fontSize: starFontSize }}
    >
      star_outline
    </i>,
  );

  useEffect(() => {
    setRating(defaultValue);
  }, [defaultValue]);

  const changeRating = newRating => {
    if (!viewMode) {
      setRating(newRating);
      onChange(newRating);
    }
  };

  const hoverRating = value => {
    if (!viewMode) {
      setHovered(value);
    }
  };

  const handleKeyDown = event => {
    if ((event.key === 'ArrowLeft' || event.key === 'ArrowDown') && !viewMode) {
      if (hovered > 1) {
        setHovered(prevHovered => prevHovered - 1);

        changeRating(hovered - 1);
      }
    }

    if ((event.key === 'ArrowRight' || event.key === 'ArrowUp') && !viewMode) {
      setHovered(prevHovered =>
        prevHovered < stars.length ? prevHovered + 1 : prevHovered,
      );

      changeRating(hovered + 1);
    }
  };

  const onRatingHandler = star =>
    hovered >= star ? selectedIcon : deselectedIcon;
  return (
    <div
      className="rating tv-star-rating__icon"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      aria-label="1-2 stjärnor utreds av TFV, 3-5 stjärnor är positivt."
      role="button"
    >
      {stars.map(star => (
        <span
          key={star}
          tabIndex={-1}
          onClick={() => {
            changeRating(star);
          }}
          onMouseEnter={() => {
            hoverRating(star);
          }}
          onMouseLeave={() => {
            hoverRating(0);
          }}
          onKeyPress={() => {
            changeRating(star);
          }}
          role="button"
          style={viewMode === true ? { cursor: 'default' } : {}}
        >
          {rating < star ? onRatingHandler(star) : selectedIcon}
        </span>
      ))}
    </div>
  );
};

StarRating.propTypes = {
  onChange: PropTypes.func,
  outOf: PropTypes.number,
  defaultValue: PropTypes.number,
  viewMode: PropTypes.bool,
  starFontSize: PropTypes.string,
};

StarRating.defaultProps = {
  onChange: () => {},
  outOf: 5,
  defaultValue: 0,
  viewMode: true,
  starFontSize: '',
};

export default StarRating;
