import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TabList } from 'components';
import { useHistory } from 'react-router';

function TabContainer({ tabs, defaultTab, children }) {
  const [activeTab, setActiveTab] = useState(defaultTab);
  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);
  const history = useHistory();
  return (
    <div>
      <TabList
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={tab => {
          setActiveTab(tab.name);
          history.push(tab.slugName);
        }}
      >
        {children(activeTab, setActiveTab)}
      </TabList>
    </div>
  );
}

TabContainer.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.func,
  defaultTab: PropTypes.string,
};
TabContainer.defaultProps = {
  tabs: [],
  children: () => {},
  defaultTab: '',
};

export default TabContainer;
