import React from 'react';
import PropTypes from 'prop-types';
import { TableListItem } from 'components';
import { convertToDayJsObject } from 'utils/dateTimeUtils';

function TableList({ list, visibleDate, onDownloadClick }) {
  return (
    <div>
      {list &&
        list.map((item, index) => (
          <TableListItem
            onDownloadClick={onDownloadClick}
            FileIdentifier={item.FileIdentifier}
            name={
              item.FileDescription == null
                ? item.FileName
                : item.FileDescription
            }
            date={convertToDayJsObject(item.FileCreatedDateTime).format(
              'YYYY-MM-DD',
            )}
            visibleDate={visibleDate}
            tabIndex={index}
          />
        ))}
    </div>
  );
}

TableList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  visibleDate: PropTypes.bool,
  onDownloadClick: PropTypes.func,
};

TableList.defaultProps = {
  list: [],
  visibleDate: true,
  onDownloadClick: () => {},
};

export default TableList;
