module.exports = {
  faqData: {
    rowOne: [
      {
        id: 'rowOne-columnOne',
        question: 'Hur lägger jag mig tillgänglig för att få uppdrag?',
        answer: `Tolkförmedling Väst tillsätter uppdrag efter högsta tillgängliga kompetens. För att få förfrågningar måste du lägga dig tillgänglig. Det är mycket viktigt att den tillgänglighet du anger är aktuell. 
          || Tillgänglighet kan du ange på två olika sätt:  
          || 1. Här hanterar du din Schemalagda tillgänglighet. Schemalagd tillgänglighet passar dig som arbetar i större utsträckning för Tolkförmedling Väst. 
          || Du lägger in den tid du önskar arbetar en eller flera dagar i veckan, alltså den tid som du är tillgänglig för uppdrag. Exempelvis mån-fre 07:00-18:00.Den tid du lägger in här gäller sedan tillsvidare som din Schemalagda tillgänglighet.
          || Kortare pass där du är upptagen hanterar du sedan i Tillgänglig/Upptagen.
          || 2. Enkel Tillgänglighet, som du hittar under "Tillgänglig/Upptagen" på startsidan, passar istället dig som önskar lägga dig tillgänglig korta eller långa pass dag för dag, lite här och var.
          `,
      },
      {
        id: 'rowOne-columnTwo',
        question: 'Vilken är Tolkförmedling Västs tillsättningspolicy?',
        answer: ` TFV har en tydlig policy att alltid tillsätta uppdraget med den högsta tillgängliga kompetensen för sammanhanget i kombination med anmäld tillgänglighet.
          || 1. Högsta tillgängliga kompetens
          || 2. Avstånd vid platsuppdrag
          || 3. Ranking baserat på ett visst antal parametrar
          || En grundförutsättning för att få uppdrag från TFV är att man anmält tillgänglighet.
        `,
      },
    ],
    rowTwo: [
      {
        id: 'rowTwo-columnOne',
        question: 'Varför försvann min förfrågan innan jag hade svarat?',
        answer: `På varje förfrågan ser du svarstiden som gäller för att acceptera eller neka uppdraget. Det är viktigt att du accepterar eller nekar så fort du kan. Om du inte har svarat när tiden går ut försvinner uppdraget från dig och går vidare till nästa tolk. Svarstiden varierar beroende på vilken tid på dygnet det är.

        ||Om kund avbokar ett uppdrag som ligger som en förfrågan kommer också uppdraget att försvinna från dig.`,
      },
      {
        id: 'rowTwo-columnTwo',
        question: 'Ska jag rapportera mina utförda uppdrag?',
        answer: `Nej, du ska inte rapportera dina utföra uppdrag. Den enda gången du behöver rapportera ett uppdrag är om uppdraget blev längre än bokad tid, då rapporterar du faktisk tolktid.`,
      },
    ],
    rowThree: [
      {
        id: 'rowThree-columnOne',
        question: 'Vad händer om kunden avbokar uppdraget jag åtagit mig?',
        answer: `Uppdrag som avbokas av kund inom 24 timmar till utförandets start ersätts med fullt arvode. 
          || Uppdrag som avbokas mer än 24 timmar innan utförandets start arvoderas inte.`,
      },
      {
        id: 'rowThree-columnTwo',
        question: 'Hur ser jag mitt arvode?',
        answer:
          `Du ser ditt arvode under "Min profil" och "Arvode"`,
      },
    ],
    rowFour: [
      {
        id: 'rowFour-columnOne',
        question: 'Vem kontaktar jag gällande arvodesfrågor?',
        answer: `Du når TFV gällande arvodesfrågor på telefon 0770-813 000, knappval 3 mellan 10.00-16.00 eller via <a style="text-decoration:underline;" href="mailto:kontakt@tolkformedlingvast.se">kontakt@tolkformedlingvast.se</a>
          `,
      },
      {
        id: 'rowFour-columnTwo',
        question: 'Vem kontaktar jag gällande synpunktsfrågor?',
        answer: 'Du når TFV gällande synpunktsfrågor på telefon 0770-813 000, knappval 2 mellan 10:00- 16:00 eller via <a style="text-decoration:underline;" href="mailto:kontakt@tolkformedlingvast.se">kontakt@tolkformedlingvast.se</a>',
      },
    ],
    rowFive: [
      {
        id: 'rowFive-columnOne',
        question: 'När betalas arvode ut?',
        answer:
          'Arvode betalas ut en månad i efterskott. Exempel, arvode för de uppdrag som du utfört innan den sista vardagen varje månad betalas ut den 27:e månaden efter.',
      },
      {
        id: 'rowFive-columnTwo',
        question: 'Jag är intresserad av att fortbilda mig inom yrket, var hittar jag information om det?',
        answer:
          'Du kan läsa mer om detta på Tolkförmedling Västs webbsida, klicka <a style="text-decoration:underline;" href="https://www.tolkformedlingvast.se/tolk/utbildningar.html">här</a>',
      },
    ],
  },
};
