import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { currentDate, dayJsDuration, currentSWTime, convertToSWDayJsObject } from 'utils/dateTimeUtils';
import { useWorkAssignment } from 'hooks';

const convertToMin = expireTime => {
  if (isEmpty(expireTime)) return null;
  return dayJsDuration(convertToSWDayJsObject(expireTime).diff(currentSWTime()));
};

const AssignmentCountdown = ({ expiryTime, className }) => {
  const { getInterpreterRequests } = useWorkAssignment();
  const timeExpiryRef = useRef(null);

  useEffect(() => {
    let timeExpiryInterval = null;
    const timeExpiryElement = timeExpiryRef.current;
    const initialInterval = convertToMin(expiryTime);
    if (!isEmpty(expiryTime) && timeExpiryElement) {
      timeExpiryElement.innerText = initialInterval.format('HH:mm:ss');

      timeExpiryInterval = setInterval(() => {
        const newInterval = convertToMin(expiryTime);
        if (newInterval.asMilliseconds() === 0) {
          getInterpreterRequests();
        } else if (
          newInterval.asMilliseconds() > 0 &&
          timeExpiryRef?.current?.innerText
        ) {
          timeExpiryRef.current.innerText = newInterval.format('HH:mm:ss');
        } else if (timeExpiryRef?.current?.innerText) {
          timeExpiryRef.current.innerText = '00:00:00';
        }
      }, 1000);
    }
    return () => {
      clearInterval(timeExpiryInterval);
    };
  }, []);
  return (
    <div className={className}>
      <div ref={timeExpiryRef}>00:00</div>
    </div>
  );
};

AssignmentCountdown.propTypes = {
  expiryTime: PropTypes.shape({}),
  className: PropTypes.string,
};
AssignmentCountdown.defaultProps = {
  expiryTime: {},
  className: '',
};

export default AssignmentCountdown;
