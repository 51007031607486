import React from 'react';
import './style.scss';

const Logo = () => (
  <div className="tv-logo" id="logo-image">
    <img
      className="tv-logo__img tv-mobile--hide"
      src="../../images/logo.png"
      alt="logo"
    />
    <img
      className="tv-logo__img-mobile tv-mobile--show"
      src="../../images/logo-mobile.png"
      alt="logo"
    />
  </div>
);

export default Logo;
