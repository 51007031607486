/* eslint-disable no-else-return */
import { useContext, useEffect } from 'react';
import { ReactReduxContext, useSelector } from 'react-redux';
import { resourceListReadRequest } from 'store/actions';
import { fromResource } from 'store/selectors';
import { fulfilled, pending, rejected, clean } from 'redux-saga-thunk';
import { useToasts } from 'react-toast-notifications';

function useGetAttachment(resource = 'GetAttachment') {
  const { store } = useContext(ReactReduxContext);
  const { dispatch } = store;
  const { addToast } = useToasts();

  const downloadedFile = useSelector(state =>
    fromResource.getList(state, resource),
  );

  const isDownloadsuccess = useSelector(state =>
    fulfilled(state, `${resource}ListRead`),
  );

  const isDownloadfailed = useSelector(state =>
    rejected(state, `${resource}ListRead`),
  );
  const isDownloadpending = useSelector(state =>
    pending(state, `${resource}ListRead`),
  );

  const GetAttachment = params => {
    if (!isDownloadpending) {
      dispatch(resourceListReadRequest(resource, 'Attachment', params, true));
    }
  };

  return {
    GetAttachment,
    downloadedFile,
    isDownloadsuccess,
    isDownloadfailed,
    addToast,
    dispatch,
  };
}

export default useGetAttachment;
