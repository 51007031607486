import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  LogoLink,
  Avatar,
  NavigationLink,
  Translate,
  SettingMenuDropdown,
} from 'components';
import './style.scss';
import { AvailabilityNowContainer, NotificationContainer } from 'containers';
import { useWorkAssignment } from 'hooks';

const MobileNavBar = ({ visible, setVisible, customerName, onLogout }) => {
  const { workAssignmentList, assignmentRequestList } = useWorkAssignment();
  const [showTranslation, setShowTranslation] = useState(false);
  const [showAssignmentsRequests, setShowAssignmentsRequests] = useState(false);

  const onClickNavItem = () => {
    setVisible(false);
  };
  const setNavLinkIsActive = () => {};
  const dropMenuEL = useRef(null);

  useEffect(() => {
    const haveTranslationOrders = workAssignmentList.filter(
      x => x.IsTranslation === true,
    );
    const haveAssignments = workAssignmentList.filter(
      i => i.IsTranslation === false,
    );
    if (haveTranslationOrders.length > 0) setShowTranslation(true);
    if (haveAssignments.length > 0 || assignmentRequestList.length > 0)
      setShowAssignmentsRequests(true);
  }, [workAssignmentList]);

  return (
    <div>
      <div className={`tv-mobile-navigation  d-block d-sm-none `}>
        <div className="tv-mobile-navigation__flex d-flex pt-1 w-100 d-flex">
          <div className="tv-left-pannel__logo mr-auto">
            <LogoLink to="/" />
          </div>
          {!visible && (
            <>
              <div className="tv-mobile-navigation__available-container">
                <AvailabilityNowContainer />
              </div>
              <NotificationContainer />
            </>
          )}
          <SettingMenuDropdown
            button={<>{visible ? null : <Avatar />}</>}
            innerRef={dropMenuEL}
          >
            <div className="tv-avatar-list__menu">
              <div
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--customer-name"
                tabIndex="-1"
              >
                {`Hej ${customerName || 'Användare'}`}
              </div>

              <NavigationLink
                to="/settings"
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--settings"
                text="Min profil"
              />
              {/* <NavigationLink
                to="/releases-page"
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--settings"
                text="Nyheter"
              /> */}
              <NavigationLink
                onClick={onLogout}
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--logout"
                text="Logga ut"
              />
            </div>
          </SettingMenuDropdown>
          <span className="float-right nav-active tv-mobile-navigation__nav-bar">
            <button
              type="button"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setVisible(!visible);
              }}
              className="tv-mobile-navigation__menubtn"
              aria-label={visible ? 'stäng knapp' : 'menyknappen'}
            >
              {/* <i
                className={visible ? 'fa fa-times' : 'fa fa-bars'}
                aria-hidden="true"
              /> */}
              <i
                className="material-icons material-icons-outlined"
                aria-hidden="true"
              >
                {visible ? 'close' : 'menu'}
              </i>
              <i className="fa " aria-hidden="true" />
            </button>
          </span>
        </div>
        <div
          className={
            visible
              ? 'tv-mobile-navigation_menu_area d-flex flex-column h-100 justify-content-center text-center w-100'
              : 'd-none'
          }
        >
          <NavigationLink
            text={Translate({ content: 'navigation.calendar' })}
            to="/"
            onClick={onClickNavItem}
            isActive={setNavLinkIsActive}
            exact
            activeClassName="nav-active"
            className="nav-item-mobile"
          />
          {showTranslation && (
            <NavigationLink
              text={Translate({ content: 'navigation.translation' })}
              to="/translations"
              onClick={onClickNavItem}
              isActive={setNavLinkIsActive}
              exact
              activeClassName="nav-active"
              className="nav-item-mobile"
            />
          )}
          {showAssignmentsRequests && (
            <NavigationLink
              text={Translate({ content: 'navigation.assignmentAndInquiries' })}
              to="/my-assignments"
              onClick={onClickNavItem}
              isActive={setNavLinkIsActive}
              exact
              activeClassName="nav-active"
              className="nav-item-mobile"
            />
          )}
          <NavigationLink
            text={Translate({ content: 'navigation.contact' })}
            to="/contact-and-faq"
            onClick={onClickNavItem}
            isActive={setNavLinkIsActive}
            exact
            activeClassName="nav-active"
            className="nav-item-mobile"
          />
        </div>
      </div>
    </div>
  );
};

MobileNavBar.propTypes = {
  visible: PropTypes.bool,
  customerName: PropTypes.string,
  setVisible: PropTypes.func,
  onLogout: PropTypes.func,
};
MobileNavBar.defaultProps = {
  visible: false,
  customerName: null,
  onLogout: () => {},
  setVisible: () => {},
};
export default MobileNavBar;
