import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components';
import './style.scss';

function CalendarRangeNavigator({ text, onForwardClick, onBackwardClick }) {
  return (
    <div className="tv-calendar_range_navigator">
      <IconButton
        aria-label="tillbakaknapp"
        label="back button"
        iconName="chevron-left"
        onClick={onBackwardClick}
        className="tv-calendar_range_navigator__icon back_button"
      />
      <span>{text}</span>
      <IconButton
        aria-label="nästa knapp"
        label="next button"
        iconName="chevron-right"
        onClick={onForwardClick}
        className="tv-calendar_range_navigator__icon next_button"
      />
    </div>
  );
}

CalendarRangeNavigator.propTypes = {
  text: PropTypes.string,
  onForwardClick: PropTypes.func,
  onBackwardClick: PropTypes.func,
};
CalendarRangeNavigator.defaultProps = {
  text: '',
  onForwardClick: () => {},
  onBackwardClick: () => {},
};

export default CalendarRangeNavigator;
