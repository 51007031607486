/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { ForgotPasswordContainer } from 'containers';
import { NavigationLink } from 'components';

function ForgotPasswordPage(props) {
  return (
    <>
      <ForgotPasswordContainer location={props.location} />
      <NavigationLink
        to="/login"
        className="tv-authentication-page__Link"
        text="Återgå till inloggning"
      />
    </>
  );
}

ForgotPasswordPage.propTypes = {};

export default ForgotPasswordPage;
