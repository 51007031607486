import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import './style.scss';
import { propTypes } from 'react-bootstrap/esm/Image';

const DatePicker = ({
  onChange,
  selectedDate,
  className,
  disabled,
  dateController,
  id,
  ...rest
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const checkDatePikcerAvailable = () => {
    const datePickerController = document.querySelector(`.${dateController}`);
    const datePickerContainer = datePickerController.querySelector(
      '.tv-datepicker',
    );
    return datePickerContainer;
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      const datePickerContainer = checkDatePikcerAvailable();
      if (datePickerContainer) {
        const inputElement = datePickerContainer.querySelector(`input#${id}`);

        if (inputElement) {
          const activeElement = document.activeElement.tagName;
          setTimeout(() => {
            if (activeElement === 'INPUT' || activeElement === 'DIV') {
              inputElement.focus();
              const datePickerTabLoop = datePickerContainer.querySelector(
                '.react-datepicker__tab-loop',
              );
              if (datePickerTabLoop) {
                if (datePickerOpen) {
                  datePickerTabLoop.style.visibility = 'hidden';
                } else {
                  datePickerTabLoop.style.visibility = 'visible';
                }
              }
            }
          }, 100);
        }
      }
    }
  };

  const onFocus = () => {
    setDatePickerOpen(!datePickerOpen);
    const datePickerContainer = checkDatePikcerAvailable();
    if (datePickerContainer) {
      const inputElement = datePickerContainer.querySelector(`input#${id}`);

      if (inputElement) {
        const datePickerTabLoop = datePickerContainer.querySelector(
          '.react-datepicker__tab-loop',
        );
        if (datePickerTabLoop) {
          datePickerTabLoop.style.visibility = 'visible';
        }
      }
    }
  };

  return (
    <div className={`tv-datepicker ${className}`}>
      <ReactDatePicker
        id={id}
        tabIndex={0}
        dateFormat="yyyy-MM-dd"
        selected={selectedDate}
        onChange={onChange}
        locale="sv"
        calendarStartDay={1}
        readOnly={disabled}
        ariaLabel={Math.random()}
        // onCalendarClose={onKeyDown}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        {...rest}
      />
      <span className="tv-datepicker__icon material-icons">&#xe935;</span>
    </div>
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  selectedDate: PropTypes.shape({}),
  disabled: PropTypes.bool,
  dateController: PropTypes.string,
  id: PropTypes.string,
};

DatePicker.defaultProps = {
  onChange: () => {},
  className: '',
  selectedDate: new Date(),
  disabled: false,
  dateController: '',
  id: 'datenum',
};

export default DatePicker;
