/* eslint-disable no-else-return */
import { tfvLog } from 'utils/miscUtil';
import { currentDate } from 'utils/dateTimeUtils';
import {
  errorCodeMap,
  LOCAL_STORE_RESOURCE_LOGIN_INFO,
  LOCAL_STORE_EMAIL,
  LOCAL_STORE_PASSWORDHASH,
  LOCAL_STORE_TOKEN,
} from 'constant';
import axios from 'axios';
import store from 'setupStore';
import { tfvResourceAuthenticate } from 'store/authenticate/actions';

const resourceAPIStore = {
  resourceUserAuthenticate: 'ValidateResourcePassword',
  setPassword: 'SetResourcePassword',
  resourceUserAuthenticateUsingId: 'AuthenticateResourceUser',
  createToken: 'CreateAuthenticationToken/',
  calendarAssignment: 'SearchWorkAssignments',
  searchSingleWorkAssignment: 'SearchWorkAssignments',
  assignmentRequest: 'GetResourceRequests',
  userProfile: 'GetResourceProfile',
  resourceAvailability: 'GetResourceAvailability',
  exceptionAvailabilityUpdate: 'CreateUpdateDefaultAvailabilityException',
  feeListSearchResource: 'SearchResourceFiles',
  compensationSearchResources: 'SearchResourceFiles',
  GetFile: 'GetFile',
  getAttachments: 'GetAttachment',
  updateDefaultAvailabilitySetting: 'CreateUpdateDefaultAvailabilitySetting',
  deleteAvailabilitySetting: 'DeleteDefaultAvailabilitySetting',
  getAvailabilitySetting: 'GetDefaultAvailabilitySettings',
  getAvailabilitySettingBackground: 'GetDefaultAvailabilitySettings',
  updatePreferencesSetting: 'UpdateResourcePreferences',
  getPreferencesSetting: 'GetResourcePreferences',
  replyToResourceRequest: 'ReplyToResourceRequest',
  assignmentVisibility: 'SetWorkAssignmentVisibility',
  submitTimeReport: 'SubmitTimeReport',
  updateWorkAssignmentExpenses: 'UpdateWorkAssignmentExpenses',
  rateWorkAssignment: 'RateWorkAssignment',
  ceateAutoAssignOrderAvailability: 'CreateAutoAssignOrderAvailability',
  deleteAutoAssignOrderAvailability: 'DeleteAutoAssignOrderAvailability',
  exceptionAvailabilityDelete: 'DeleteDefaultAvailabilityException',
  allNotifications: 'GetNotifications',
  searchAllNotifications: 'GetNotifications',
  autoAssignOpeningHours: 'GetAutoAssignOrderOpeningHours',
  createSemesterAllocation: 'RegisterVacationDays',
  semesterAllocations: 'GetVacationDays',
  updateSemesterAllocation: 'UpdateVacationDays',
  deleteSemesterAllocation: 'DeleteVacationDays',
  createResourceDeviation: 'CreateResourceDeviation',
  getResourceComplaintReason: 'GetResourceComplaintReason',
  cancelWorkAssignment: 'CancelWorkAssignment'
};

export const mapResourceToAPIEdnPoint = resource =>
  resourceAPIStore[resource] || resource;

const ignoreErrorNotifyRequests = ['GetTimeReportSignature'];

let isRefreshing = false;
const refreshSubscribers = [];

const subscribeTokenRefresh = cb => {
  refreshSubscribers.push(cb);
};

const onRefreshed = () => {
  refreshSubscribers.forEach(cb => cb());
};

export const responseInterceptor = async response => {
  // Because multisoft api always return status 200 even when response error
  // But axios library base on response status code to decide error or not
  // Therefore we can not catch error on `catch` block on pattern axios.get(...).then(...).catch(...)
  // So we base on response.data.Errors of Multisoft to decide response is error or not
  let hideErrorMessage = false
  if (response.data.Errors) {
    response.data.Errors.forEach(element => {
      if (element.ErrorCode === 1062) {
        hideErrorMessage = true
      }
    });
    if(hideErrorMessage) return response;
    const { config } = response;
    const originalRequest = config;
    if (errorCodeMap[response.data.Errors[0].ErrorCode] === 'invalidToken') {
      if (!isRefreshing) {
        isRefreshing = true;
        const email = localStorage.getItem(LOCAL_STORE_EMAIL);
        const passwordHash = localStorage.getItem(LOCAL_STORE_PASSWORDHASH);
        const loginInfo = email || null;
        if (!passwordHash || !loginInfo) {
          localStorage.removeItem('LOCAL_STORE_TOKEN');
          window.location.href = '/login';
        }
        await store.dispatch(
          tfvResourceAuthenticate({
            email,
            passwordHash,
            isRefresh: true,
          }),
        );
        isRefreshing = false;
        setTimeout(() => {
          onRefreshed();
        }, 0);
      }
      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh(() => {
          const initToken = localStorage.getItem(LOCAL_STORE_TOKEN);
          originalRequest.data = JSON.parse(originalRequest.data);
          originalRequest.data.Token = initToken;
          originalRequest.data = JSON.stringify(originalRequest.data);
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    } else {
      response.data.Errors.forEach(element => {
        const isIgnoreNotify =
          ignoreErrorNotifyRequests.filter(
            r => originalRequest.url.indexOf(r) !== -1,
          ).length > 0;
        // if (!isIgnoreNotify) toast.error(element.ErrorMessage);
      });
      return Promise.reject(response.data.Errors[0]);
    }
  }
  return response;
};

export const errorInterceptor = error => {
  // toast.error(Translate({ content: 'error.commonError' }));
  tfvLog('error interceptor');
  const sendError = {
    ErrorCode: error.response.status,
    ErrorMessage: error.message,
  };
  Promise.reject(sendError);
};
