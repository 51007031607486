import {
  LOCAL_STORE_EMAIL,
  LOCAL_STORE_PASSWORDHASH,
  LOCAL_STORE_INTERPRETER_NUMBER,
  LOCAL_STORE_RESOURCE_LOGIN_INFO,
  LOCAL_STORE_TOKEN,
  LOCAL_STORE_LANGUAGE,
} from 'constant';
import { initialState } from './selectors';
import {
  TFV_LOGIN_SUCCESS,
  TFV_LOGOUT,
  AUTHENTICATE_RESOURCE_SUCCESS,
  TFV_LOGIN_FAILURE,
  TFV_LOGIN_REQUEST,
} from './actions';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTHENTICATE_RESOURCE_SUCCESS:
      localStorage.setItem(LOCAL_STORE_EMAIL, payload.email);
      localStorage.setItem(LOCAL_STORE_PASSWORDHASH, payload.passwordHash);
      localStorage.setItem(LOCAL_STORE_TOKEN, payload?.data?.Token);
      localStorage.setItem(
        LOCAL_STORE_INTERPRETER_NUMBER,
        payload?.data?.ResourceNumber,
      );
      return {
        ...state,
        email: payload,
        token: payload?.data?.Token,
      };
    case TFV_LOGIN_REQUEST:
      localStorage.setItem(
        LOCAL_STORE_RESOURCE_LOGIN_INFO,
        JSON.stringify(payload),
      );
      return {
        ...state,
        loginInfo: payload,
      };
    case TFV_LOGIN_SUCCESS:
      localStorage.setItem(LOCAL_STORE_TOKEN, payload.AccessToken);
      return {
        ...state,
        token: payload.AccessToken,
      };
    case TFV_LOGOUT:
      localStorage.clear();
      localStorage.setItem(LOCAL_STORE_LANGUAGE, 'se');
      return {
        ...state,
        email: initialState.email,
        token: null,
        loginInfo: initialState.loginInfo,
      };
    case TFV_LOGIN_FAILURE:
      return {
        ...state,
        email: initialState.email,
        loginInfo: initialState.loginInfo,
      };
    default:
      return state;
  }
};
