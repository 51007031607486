import React, { useEffect, useState } from 'react';
import { bool } from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Translate } from 'components';
import { isEmpty } from 'lodash';
import messages from './releaseMessages.json';

import './style.scss';

const propTypes = {
  showMessage: bool,
  expand: bool,
};

const defaultProps = {
  showMessage: false,
  expand: false,
};
const isMessageDisabled = () => {
  let returnValue = false;
  if (!isEmpty(localStorage.getItem('releaseMessageDisabled'))) {
    const disabled = dayjs(localStorage.getItem('releaseMessageDisabled'));
    returnValue = !!(disabled === 'true' || disabled);
  }
  return returnValue;
};
const checkIfMessageshoulddisplay = expiryDate => {
  if (isEmpty(localStorage.getItem('showMessageValue')))
    return dayjs(expiryDate, 'YYYY-MM-DD').diff(dayjs(), 'days') >= 0;
  if (
    !isEmpty(localStorage.getItem('showMessageValue')) &&
    dayjs(expiryDate, 'YYYY-MM-DD').diff(dayjs(), 'days') >= 0
  ) {
    return true;
  } 
    return false
};

const setMessageDisabled = () => {
  localStorage.setItem('releaseMessageDisabled', true);
};

// No of days to enable dismiss
const enableDismissDays = -2;

const ReleaseMessage = ({ showMessage, expand }) => {
  const { expiryDate, summary: releaseText } =
    !isEmpty(messages) && messages[messages.length - 1];

  const [showMessageValue, setShowMessageValue] = useState(
    checkIfMessageshoulddisplay(expiryDate),
  );

  const [disableMessageForToday, setDisableMessage] = useState(
    isMessageDisabled(),
  );

  useEffect(() => {
    setDisableMessage(isMessageDisabled());
  }, []);

  if (isEmpty(messages)) {
    localStorage.setItem('showMessageValue', false);
  } else {
    localStorage.setItem('showMessageValue', showMessageValue);
  }

  const releaseMessageViewDate = isEmpty(
    localStorage.getItem('releaseMessageViewDate'),
  )
    ? dayjs().format('YYYY-MM-DD')
    : localStorage.getItem('releaseMessageViewDate');

  const messageList = releaseText ? releaseText.split('|') : [];

  if (dayjs(expiryDate, 'YYYY-MM-DD').diff(dayjs(), 'days') >= 0) {
    localStorage.setItem(
      'releaseMessageViewDate',
      dayjs().format('YYYY-MM-DD'),
    );
  }

  const tempClose = () => {
    setDisableMessage(true);
    setMessageDisabled();
  };
  return (
    <div
      className="release-message-cont"
      style={{
        display: showMessageValue && !disableMessageForToday ? 'flex' : 'none',
      }}
    >
      <div className="body d-flex">
        <i
          className="release-message-cont-alert"
          style={{
            background: "url('/images/bell.jpg') no-repeat center",
          }}
        />
        <div className="d-flex w-100 ">
          <>
            {messageList.map((item, i) => (
              <div key={item.id} className="release-summary">
                {item}{' '}
                <Link
                  className="link-style tv-mobile--show"
                  to="/releases-page"
                >
                  <Translate content="releaseMessage.learnMore" />
                </Link>
              </div>
            ))}
          </>
          <div className="release-actions tv-mobile--hide">
            <Link className="link-style " to="/releases-page">
              <Translate content="releaseMessage.learnMore" />
            </Link>
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <i
          className="release-message-cont-close fa fa-times"
          role="button"
          onKeyPress={() => {}}
          tabIndex={0}
          onClick={() => {
            if (
              dayjs(releaseMessageViewDate, 'YYYY-MM-DD').diff(
                dayjs(),
                'days',
              ) <= enableDismissDays
            ) {
              setShowMessageValue(false);
            } else tempClose();
          }}
        />
      </div>
    </div>
  );
};

ReleaseMessage.propTypes = propTypes;
ReleaseMessage.defaultProps = defaultProps;

export default ReleaseMessage;
