import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import('./style.scss')

function TabContent({ activeTab, name, className ,children }) {
    const tabContentClass = classNames({
        'tv-tab-content':true,
        'tv-tab-content-active':activeTab===name,      
    })

    return (
        <div className={`${className} ${tabContentClass}`}>
            {activeTab===name?children:null}
        </div>
    )
}

TabContent.propTypes = {
    name: PropTypes.string,
    activeTab: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
}
TabContent.defaultProps = {
    name: "",
    activeTab: "",
    children: "",
    className: "",
}

export default TabContent