import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'components';

import './style.scss';

function AssignmentContactModal({
  isVisible,
  onOverlayClick,
  onClick,
  onClose,
}) {
  return (
    <Modal
      className="tv-assignment-contact-modal__container"
      hideHeader
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      isVisible={isVisible}
    >
      <h2 className="tv-assignment-contact-modal__title">
        Kontakta TFV
      </h2>
      <p>Ring tolklinjen på</p>

      <p className="tv-assignment-contact-modal__contact-number">
        08 - 120 800 05
      </p>
      <Button
        onClick={onClick}
        palette="outline"
        className="tv-assignment-contact-modal__button"
        label="Avbryt"
      />
    </Modal>
  );
}

AssignmentContactModal.propTypes = {
  isVisible: PropTypes.bool,
  onOverlayClick: PropTypes.func,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};
AssignmentContactModal.defaultProps = {
  isVisible: false,
  onOverlayClick: () => {},
  onClose: () => {},
  onClick: () => {},
};

export default AssignmentContactModal;
