export const ERROR_RECEIVE = 'ERROR_RECEIVE';
export const ERROR_REMOVE_STORE = 'ERROR_REMOVE_STORE';
export const ERROR_ADD_STORE = 'ERROR_ADD_STORE';

export const errorsReceive = error => ({
  type: ERROR_RECEIVE,
  payload: error,
});

export const errorAddToStore = error => ({
  type: ERROR_ADD_STORE,
  payload: error,
});

export const errorRemoveFromStore = error => ({
  type: ERROR_REMOVE_STORE,
  payload: error,
});
