/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import './style.scss';
import { localDayjs as dayjs } from 'utils/dateTimeUtils';
import sv from 'date-fns/locale/sv';

registerLocale('sv', sv);

const CalendarNavigator = ({
  onSelectDate,
  range,
  selectedCalendar,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  useEffect(() => {
    const newSelectDate = range?.highlightedDay || dayjs();
    setSelectedDate(newSelectDate.toDate());
  }, [selectedCalendar, range]);

  useEffect(() => {
    const datepickerDOM = document.querySelectorAll('.react-datepicker');
    if (datepickerDOM.length > 0) {
      const previouseMonthDOM = datepickerDOM[0].children[1];
      const nextMonthDOM = datepickerDOM[0].children[2];
      if (previouseMonthDOM) {
        previouseMonthDOM.setAttribute('aria-label', 'förra månaden');
      }
      if (nextMonthDOM) {
        nextMonthDOM.setAttribute('aria-label', 'nästa månad');
      }
    }
  }, []);

  return (
    <div className="tv-calendar-nav">
      <ReactDatePicker
        dateFormat="yyyy-MM-dd"
        onChange={date => {
          onSelectDate(date);
          setSelectedDate(date);
        }}
        locale="sv"
        // disabledKeyboardNavigation
        // startDate={range.start.toDate()}
        // endDate={range.end.toDate()}
        calendarStartDay={1}
        selected={selectedDate}
        {...rest}
        inline
      />
    </div>
  );
};

CalendarNavigator.propTypes = {
  onSelectDate: PropTypes.func,
  range: PropTypes.shape({}),
  selectedCalendar: PropTypes.string,
};

CalendarNavigator.defaultProps = {
  onSelectDate: () => {},
  range: null,
  selectedCalendar: 'week',
};

export default CalendarNavigator;
