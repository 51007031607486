import React, { useEffect, useState } from 'react';
import {
  TabContainer,
  TabContent,
  TermsAndCondition,
  GeneralCondition,
} from 'components';
import {
  CompensationContainer,
  FeeListContainer,
  DefaultAvailablityContainer,
  PreferenceSettingContainer,
  ProfileContainer,
  SemesterAllocationContainer,
} from 'containers';
import { useLocation } from 'react-router';
import './style.scss';
import { useWorkAssignment } from 'hooks';
import moment from 'moment';

const SettingPage = () => {
  const { searchWorkAssignment } = useWorkAssignment();
  const tabs = [
    { name: 'Min profil', label: 'Min profil', slugName: '#profile' },
    { name: 'Ersättning', label: 'Arvode', slugName: '#payout' },
    // {
    //   name: 'Uppdragsväljaren',
    //   label: 'Uppdragsväljaren',
    //   slugName: '#preferences',
    // },
    {
      name: 'Schemalagd tillgänglighet',
      label: 'Schemalagd tillgänglighet',
      slugName: '#default-availability',
    },
    {
      name: 'Semester',
      label: 'Längre ledighet',
      slugName: '#semesters-allocation',
    },
    // { name: 'Arvodeslistor', label: 'Arvodeslistor', slugName: '#fees' },
    {
      name: 'Behandling av personuppgifter',
      label: 'Behandling av personuppgifter',
      slugName: '#term-and-condition',
    },
    {
      name: 'Allmänna villkor för Tolkuppdrag',
      label: 'Allmänna villkor',
      slugName: '#general-condition',
    },
  ];

  const [defaultTab, setDefaultTab] = useState('Min profil');

  const { hash: slug } = useLocation();

  useEffect(() => {
    document.title = `Kontoinställningar - TFV Tolkportal`;
    const searchDateRange = {
      start: moment().subtract(1, 'months'),
      end: moment().add(1, 'months'),
    };
    searchWorkAssignment(false, searchDateRange, true);
    const selectedTab = tabs.filter(x => x.slugName === slug);
    setDefaultTab(selectedTab.length > 0 ? selectedTab[0].name : 'Min profil');
  }, []);

  return (
    <div className="tv-setting__container">
      <TabContainer tabs={tabs} defaultTab={defaultTab}>
        {activeTab => (
          <>
            <TabContent name="Min profil" activeTab={activeTab} className="">
              <ProfileContainer />
            </TabContent>
            <TabContent name="Ersättning" activeTab={activeTab}>
              <CompensationContainer />
            </TabContent>
            {/* <TabContent name="Uppdragsväljaren" activeTab={activeTab}>
              <PreferenceSettingContainer />
            </TabContent> */}
            <TabContent name="Schemalagd tillgänglighet" activeTab={activeTab}>
              <DefaultAvailablityContainer />
            </TabContent>
            <TabContent name="Semester" activeTab={activeTab}>
              <SemesterAllocationContainer />
            </TabContent>
            {/* <TabContent name="Arvodeslistor" activeTab={activeTab}>
              <FeeListContainer />
            </TabContent> */}
            <TabContent
              name="Behandling av personuppgifter"
              activeTab={activeTab}
            >
              <TermsAndCondition />
            </TabContent>
            <TabContent
              name="Allmänna villkor för Tolkuppdrag"
              activeTab={activeTab}
            >
              <GeneralCondition />
            </TabContent>
          </>
        )}
      </TabContainer>
    </div>
  );
};

SettingPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
};

SettingPage.defaultProps = {};

export default SettingPage;
