import React, { useRef, useEffect } from 'react';
import { status } from 'constant';
import './style.scss';
import { useWorkAssignment } from 'hooks';
import moment from 'moment';

const ReleasesPage = () => {
  const { searchWorkAssignment } = useWorkAssignment();
  const pageDescription = () => (
    <div className="wrapper">
      <div className="row">
        <div className=" col col-md-12 d-flex ">
          <div className="icon">👉</div>
          <p>
            Här nedan kan du ta del av aktuella nyheter. TFV arbetar för att
            förenkla våra tolkar och kunders dagliga arbete och göra det mer
            effektivt, med hjälp av automatiserade och förbättrade processer. Vi
            uppskattar er feedback så att vi tillsammans kan ge en bättre
            användarupplevelse. Har du synpunkter på något du önskar kunde
            förbättras, var god kontakta oss.
            <a href="/contact-and-faq" aria-label="contact">
              {' '}
              Här
            </a>{' '}
            hittar du svar på vanliga frågor samt kontaktuppgifter till oss.{' '}
          </p>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const searchDateRange = {
      start: moment().subtract(1, 'months'),
      end: moment().add(1, 'months'),
    };
    searchWorkAssignment(false, searchDateRange, true);
  }, []);
  return (
    <div className="release-news__container">
      <div className="page-title">Nyheter</div>
      {pageDescription()}
      <StaticContent />
    </div>
  );
};

export default ReleasesPage;

const StaticContent = () => (
  <>
    <div className="wrapper">
      <div className="container container-styled divder">
        <div className="row">
          <div className="col col-md-12">
            <div className="date">2021-12-09</div>
          </div>
        </div>
        <div className="row">
          <div className="col col-md-12">
            <div className="title" id="tolkaDirekt-info">
              Nu lanserar vi verktyget TolkaDirekt för tolkar
            </div>
          </div>
        </div>
        <div>
          <p>
            TolkaDirekt handlar om automatisk tillsättning på mer eller mindre
            akuta telefonuppdrag från kund. Att lägga sig tillgänglig för
            TolkaDirekt innebär att man automatiskt kommer att tillsättas när en
            efterfrågan från kund matchar den tid som du har lagt dig som
            TolkaDirekt-tillgänglig.
          </p>
          <p>
            Våra kunder kan via TFV kundportal välja att söka tolk i ett flöde
            som vi kallar TolkDirekt. De väljer starttid direkt eller inom ett
            par timmar, samt längd på uppdraget.
          </p>
          <p>
            Om du matchar en bokning från kund så kommer du att tillsättas på
            uppdraget. Du får ingen förfrågan utan tillsätts direkt. Du får en
            notis och ett sms så fort du blivit tillsatt. Uppdraget kan vara då
            vara när som helst inom det tidsspann som du har satt dig som
            TolkaDirekt-tillgänglig.
          </p>
          <p>
            Samtidigt som vi lanserar TolkaDirekt för tolkar så lanserar vi
            TolkDirekt på kundsidan. Detta gör att det kan ta en tid innan
            kunderna verkligen börjar använda tjänsten på sin sida och
            efterfrågan/volym byggs upp, men det är ändå viktigt att du är med
            från början och lägger dig tillgänglig för TolkaDirekt. Med tiden så
            är tanken att TolkaDirekt ska kunna generera många uppdrag för
            tolkar som använder verktyget.
          </p>
          <p>
            TolkaDirekt tar inte hänsyn till val gjorda i Uppdragsväljaren, utan
            TolkaDirekt innebär telefonuppdrag som kan komma från alla våra
            typer av kunder och avtal.
          </p>

          <p>
            TolkaDirekt-tillgänglighet kan du lägga över både Upptagen tid och
            uppdrag som du redan har från oss. Systemet kommer att känna av
            detta så du kan aldrig tillsättas på ett uppdrag när du ligger
            Upptagen eller har ett annat planerat uppdrag.
          </p>
          <p>
            Initialt så kommer TolkaDirekt gå att använda vardagar 07:30-19:00.
            Detta kommer på sikt att utökas.{' '}
          </p>
          <div className="mb-2 tv-title-text">
            Så här använder du TolkaDirekt:
          </div>
          <ol className="title-text-list">
            <li>
              Du hittar knappen för *TolkaDirekt i både app och portal. Klicka
              på den för att aktivera.
            </li>
            <li>
              Välj den sluttid som ska gälla. Klicka på Aktivera/Bekräfta{' '}
            </li>
            <li>
              Du är nu aktiverad för TolkaDirekt. Du kan se att du är aktiverad
              tydligt i både app och portal genom den pulserande symbolen. Du
              kan även se ditt aktiverade pass i kalendern. Du kan nu när som
              helst under din TolkaDirekt-tillgänglighet bli tillsatt på ett
              uppdrag.
            </li>
          </ol>
          <p>
            För att avsluta din tillgänglighet så klickar du på
            TolkaDirekt-knappen igen.
          </p>
          <div className="mb-2 tv-title-text">
            Viktigaste delarna att vara införstådd med gällande TolkaDirekt:
          </div>
          <b>
            <ul className="title-text-list">
              <li>
                <u>
                  Du kommer att tillsättas automatiskt och får ingen förfrågan.
                </u>
              </li>
              <li>
                TolkaDirekt ska bara användas när man befinner sig i en miljö
                som uppfyller kraven för telefontolkning, då man när som helst
                kan bli tilldelad ett uppdrag.
              </li>
              <li>
                Uppdrag kan komma från vilken av våra kunder eller vilket avtal
                som helst. Val gjorda i Uppdragsväljaren har ingen inverkan när
                det handlar om uppdrag genom TolkaDirekt.
              </li>
              <li>
                Tänk på att genast avsluta din TolkaDirekt-tillgänglighet om du
                på ett eller annat sätt blivit upptagen under den tid som du
                angett.
              </li>
              <li>
                I en första lansering så kommer TolkaDirekt endast att fungera
                på vardagar 07:30-19:00.{' '}
              </li>
            </ul>
          </b>
          <p>
            *OBS! För att bli aktiverad första gången och kunna börja använda
            TolkaDirekt så behöver du ringa till oss på
            <nobr className="nobr-text"> 08-120 800 05.</nobr>
            Detta kan du göra när som helst mellan klockan 08:00-17:00. Vi
            kommer då att ställa några frågor för att säkerställa att du är
            införstådd med innebörden av TolkaDirekt. När du har gjort detta så
            aktiveras du av oss och knappen för TolkaDirekt dyker upp i portal
            och app.
          </p>
        </div>
      </div>
      <div className="container container-styled divder">
        <div className="row">
          <div className="col col-md-12">
            <div className="date">2021-10-06</div>
          </div>
        </div>
        <div className="row">
          <div className="col col-md-12">
            <div className="title" id="notiser-info">
              Notiser
            </div>
          </div>
        </div>
        <div>
          <p>
            Nu lanserar vi notiser i app och webbläsare. Vi skickar i dagsläget
            ut notiser vid förfrågan, vid avbokning, vid ändring och som
            påminnelse om man har uppdrag att rapportera.
          </p>
          <p>
            Ni väljer själva om ni vill acceptera att app eller portal skickar
            notiser. Stänga av notiser kan man också självklart göra när som
            helst. För att App och Tolkportal ska fungera fullt ut och för att
            ni inte ska missa något viktigt så rekommenderar vi alla användare
            att tillåta notiser. Notiser kan också längre fram komma att helt
            eller delvis ersätta sms-utskick.
          </p>
        </div>
      </div>
      <div className="container container-styled">
        <div className="row">
          <div className="col col-md-12">
            <div className="date">2021-08-26</div>
          </div>
        </div>
        <div className="row">
          <div className="col col-md-12">
            <div className="title">
              Välkommen till nya tolkportalen och appen!
            </div>
          </div>
        </div>
        <div>
          <p>
            Tolkportalen och appen Tolka har fått en helt ny design och flera
            nya funktioner. Vi hoppas att du verkligen ska gilla det du ser och
            att nya funktioner underlättar ditt arbete. Det är alltid viktigt
            att du ser till att din webbläsare är uppdaterad med senaste
            versionen, likaså att din mobiltelefon är uppdaterad med senaste
            IOS- eller Android-versionen - Detta för att appar och portaler ska
            fungera fullt ut. Du kommer att känna igen dig i mycket men här
            kommer en förklaring av det som är nytt i tolkportalen.
          </p>
          <p>
            <b>Startsidan</b> är en <b>Kalender</b> där du kan överblicka dina
            uppdrag i en månads-, vecko-, eller dag-vy. Du kan filtrera uppdrag
            efter status. Här hittar du också knappen för att hantera enkel
            Tillgänglighet eller Upptagen tid.{' '}
            <b>Under Uppdrag och förfrågningar </b>
            hittar du alla aktuella förfrågningar. Du kan se svarstiden som
            gäller för att besvara förfrågan. På förfrågan ser du nu även en
            preliminär ersättning för uppdraget. Detta är bara en preliminär
            ersättning som baseras på avtalet och den bokade tiden. Denna
            ersättning kan komma att ändras, bland annat av den rapporterade
            tiden. På sidan <b>Under Uppdrag och förfrågningar </b> kan du också
            se alla dina uppdrag och filtrera på datumperiod, typ och status.
            Under <b>Min profil</b> i högra hörnet hittar du sidor som
            exempelvis
            <b> Arvodeslistor, Ersättning</b> och{' '}
            <b>Schemalagd tillgänglighet.</b>
          </p>
          <div className="mb-2 tv-title-text">Status på uppdrag</div>

          <p>
            <span
              className={`status-label-wrapper label-background_${status.inquiry.name}`}
            >
              {status.inquiry.name}
            </span>{' '}
            – På en förfrågan så kan du se den information du behöver för att
            acceptera eller avböja. Du kan se den svarstiden som gäller för att
            besvara förfrågan. Svarstiden styrs av det aktuella avtalet. På
            förfrågan ser du även en preliminär ersättning. Detta är endast en
            preliminär ersättning som baseras på avtalet och den bokade tiden.
            Denna ersättning kan komma att ändras, bland annat av den
            rapporterade tiden. Om du avböjer ett uppdrag så förvinner uppdraget
            för dig helt och hållet. Likaså skulle om kund skulle avboka ett
            uppdrag, medan det ligger på förfrågan, så försvinner uppdraget.
          </p>
          <p>
            <span
              className={`status-label-wrapper label-background_${status.accept.name}`}
            >
              {status.accept.name}
            </span>{' '}
            – Uppdrag som du har accepterat och ska genomföra. Var mycket noga
            med att läsa all information som gäller för uppdraget.
          </p>
          <p>
            <span
              className={`status-label-wrapper label-background_${status.performed.name}`}
            >
              {status.performed.name}
            </span>{' '}
            – När ett uppdrags starttid har passerats med 5 minuter så får den
            status Utförd och kan då rapporteras.
          </p>
          <p>
            <span
              className={`status-label-wrapper label-background_${status.reported.name}`}
            >
              {status.reported.name}
            </span>{' '}
            – När uppdraget är rapporterat så kan du se din tidrapport inne på
            uppdraget.
          </p>
          <p>
            <span
              className={`status-label-wrapper label-background_${status.cancelled.name}`}
            >
              {status.cancelled.name}
            </span>
            – När kund har avbokad ett accepterat uppdrag så får den status
            avbokad. Du kan se på det avbokade uppdraget om du kommer att få
            betalt eller ej. Detta styrs av det aktuella avtalet.
          </p>
          <div className="mb-2 tv-title-text">Tillgänglighet</div>
          <p>
            TFV tillsätter uppdrag efter högsta tillgängliga kompetens. För att
            få förfrågningar så måste du lägga dig tillgänglig. Det är mycket
            viktigt att den tillgänglighet du anger är aktuell. Tillgänglighet
            kan du lämna på två olika sätt. <b> Schemalagd tillgänglighet </b>{' '}
            passar dig som jobbar i större utsträckning för TFV. Du lägger in
            den tid du önskar jobba en eller flera dagar i veckan, alltså den
            tid som du är tillgänglig för uppdrag. Exempelvis mån-fre
            07:00-18:00. Den tid du lägger in här gäller sedan tillsvidare som
            din Schemalagda tillgänglighet. Det är mycket viktigt att den
            tillgänglighet du anger är aktuell. Kortare pass där du är{' '}
            <b>Upptagen</b> hanterar du sedan i<b> Tillgänglig/Upptagen </b>.
            Enkel Tillgänglighet under
            <b> Tillgänglig/Upptagen </b> passar istället dig som önskar lägga
            dig tillgänglig korta eller långa pass dag för dag, lite här och
            var.
          </p>
          <div className="mb-2 tv-title-text">Uppdragsväljaren</div>

          <p>
            Under Min profil hittar du Uppdragsväljaren. Här kan du välja vilken
            typ av uppdrag du vill få förfrågningar på. Ju högre kompetens du
            har desto fler val kan du göra. Valen du kan göra är efter
            <b> Typ</b>(telefon, kontakt etc), <b>Område</b>(migration, sjukvård
            etc) och
            <b> Avtal</b>(Region Stockholm, Arbetsförmedlingen etc). Tänk på att
            det är dina val här som helt styr vilka uppdrag du kommer att få
            förfrågningar på.
          </p>
          <div className="mb-2 tv-title-text">Rapportera uppdrag</div>
          <p>
            Ha som regel att alltid rapportera ett uppdrag direkt efter att det
            är utfört. Var mycket noga med att rapportera den faktiska
            tolkningstiden. Vid distanstolkning skall alltid sluttiden stämmas
            av med kund. Vid kontakttolkning skall kund alltid lämna en signatur
            och detta kan endast göras via appen. Var noga med att ange korrekt
            resa vid de uppdrag som tillåter detta. Vilka uppdrag/avtal som
            godkänner resa och/eller utlägg framgår i aktuell Arvodeslista. När
            uppdraget är rapporterat skickas en kopia till kundens egen portal.
            Lämna gärna ett betyg på uppdraget tillsammans med en kommentar.
            Positivt som negativt. Kommentaren och betyget ser bara TFV. Här kan
            vi få in och samla viktig information, till exempel om det har varit
            dålig ljudkvalitet under uppdraget.
          </p>
          <p>Lycka till med arbetet i nya tolkportalen och appen!</p>
        </div>
      </div>
    </div>
  </>
);
