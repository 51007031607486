import React from 'react';
import { Button, AssignmentStatus } from 'components';
import './style.scss';
import PropTypes from 'prop-types';

function MessageServiceOverview({ onClickClose, onReject, onAccept }) {
  return (
    <div className="tv-message-service-overview">
      <div className="tv-message-service-overview__outer-container">
        <div className="tv-message-service-overview__outer-container__header">
          <div className="message-service-name">FK7908</div>
          <Button
            onClick={onClickClose}
            className="close-icon"
            palette="text"
            label={<i className="fa fa-times" />}
          />
        </div>
        <hr />
        <div className="tv-message-service-overview__outer-container__wrapper">
          <div className="tv-message-service-overview__container">
            <div className="tv-message-service-overview__content">
              <div className="tv-message-service-overview__content-item mb-0">
                <div className="tv-message-service-overview__content-item-main">
                  <div className="d-flex flex-row align-items-center">
                    <div style={{fontSize: '1rem', fontWeight: '400'}}>Message Service</div>
                  </div>
                  <div className="tv-message-service-overview__content-item-main__status">
                    <AssignmentStatus status="inquiry" />
                  </div>
                </div>
              </div>
              <hr />
              <div className="tv-message-service-overview__content-item">
                <div className="tv-message-service-overview__content-item-main">
                  <div className="d-flex flex-row align-items-center">
                    <i className="material-icons">language</i>
                    <div className="ml-2">Albanien</div>
                  </div>
                </div>
              </div>
              <div className="tv-message-service-overview__content-item">
                <div className="tv-message-service-overview__content-item-main">
                  <div className="d-flex flex-row align-items-center">
                    <i className="material-icons">language</i>
                    <div className="ml-2">Dari</div>
                  </div>
                </div>
              </div>
              <div className="tv-message-service-overview__content-item">
                <div className="tv-message-service-overview__content-item-main">
                  <div className="d-flex flex-row align-items-center">
                    <i className="material-icons">calendar_today</i>
                    <div className="ml-2">06 Februari 2023</div>
                  </div>
                </div>
              </div>
              <div className="tv-message-service-overview__content-item">
                <div className="tv-message-service-overview__content-item-main">
                  <div className="d-flex flex-row align-items-center">
                    <i className="material-icons">schedule</i>
                    <div className="ml-2">08.00AM - 8.15AM</div>
                  </div>
                </div>
              </div>
              <div className="tv-message-service-overview__content-item">
                <div className="tv-message-service-overview__content-item-main">
                  <div className="d-flex flex-row align-items-center">
                    <i className="material-icons">chat_bubble</i>
                    <div className="ml-2">
                      TODO: Ge en kort introduktion av ditt projekt
                    </div>
                  </div>
                </div>
              </div>
              <div className="tv-message-service-overview__content-item">
                <div className="tv-message-service-overview__content-item-main">
                  <div className="d-flex flex-row align-items-center">
                    <i className="material-icons">person</i>
                    <div className="ml-2">John Wick</div>
                  </div>
                </div>
              </div>
              <div className="tv-message-service-overview__content-item">
                <div className="tv-message-service-overview__content-item-main">
                  <div className="d-flex flex-row align-items-center">
                    <i className="material-icons">smartphone</i>
                    <div className="ml-2">+4698000925</div>
                  </div>
                </div>
              </div>
              <div className="tv-message-service-overview__content-item">
                <div className="tv-message-service-overview__content-item-main">
                  <div className="d-flex flex-row align-items-center">
                    <i className="material-icons">info</i>
                    <div className="ml-2">Testinformation för tolk</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tv-message-service-overview__footer">
          <Button
            className="left-button"
            label="Tacka nej"
            palette="outline"
            onClick={() => onReject(false, '')}
          />
          <Button
            className="right-button"
            label="Acceptera"
            onClick={() => onAccept(true, '')}
          />
        </div>
      </div>
    </div>
  );
}

MessageServiceOverview.propTypes = {
  onClickClose: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
};
MessageServiceOverview.defaultProps = {
  onClickClose: () => {},
  onAccept: () => {},
  onReject: () => {},
};

export default MessageServiceOverview;
