import React, { useEffect, useState } from 'react';
import FeedbackModal from 'components/organisms/FeedbackModal';
import PropTypes from 'prop-types';
import { timeSheetContentTypes } from 'constant';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useGiveFeedback } from 'hooks';

const FeedbackContainer = ({ isVisible, onClose, item }) => {
  const {
    sendRatingData,
    sendResourceDeviationData,
    rateWorkAssignmentFulfilled,
    createResourceDeviationFulfilled,
  } = useGiveFeedback(item);

  const [feedbackStatus, setFeedbackStatus] = useState({});
  const [reasons, setReasons] = useState([]);
  const [reasonValue, setReasonValue] = useState({
    label: 'Välj',
    value: null,
  });
  const [showError, setShowError] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const { ResourceComplaintReasons } = useSelector(
    state => state.resource.getResourceComplaintReason.detail,
  );
  useEffect(() => {
    const originalTitle = document.title;
    document.title = `Ge feedback på ${item.OrderNumber} - TFV Tolkportal`;
    return () => {
      document.title = originalTitle;
    };
  }, [isVisible]);

  useEffect(() => {
    setFeedbackStatus({
      ...feedbackStatus,
      WorkAssignmentIdentifier: item.WorkAssignmentIdentifier,
      ContactPersonIdentifier: item.ContactPerson.ContactPersonIdentifier,
    });
  }, []);

  useEffect(() => {
    if (rateWorkAssignmentFulfilled) {
      const deviation = {
        ComplaintReasonIdentifier:
          feedbackStatus.ComplaintReasonIdentifier === ''
            ? null
            : feedbackStatus.ComplaintReasonIdentifier,
        ContactPersonIdentifier: feedbackStatus.ContactPersonIdentifier,
        DeviationDescription: feedbackStatus.Comment
          ? feedbackStatus.Comment
          : '',
        WorkAssignmentIdentifier: feedbackStatus.WorkAssignmentIdentifier,
        Documents: [],
      };
      sendResourceDeviationData(deviation);
    }
  }, [rateWorkAssignmentFulfilled]);

  useEffect(() => {
    if (createResourceDeviationFulfilled) {
      onClose();
    }
  }, [createResourceDeviationFulfilled]);

  const handleRate = value => {
    if (value) {
      if (ResourceComplaintReasons) {
        const response = ResourceComplaintReasons.filter(
          rate =>
            rate.LowestRatingFromResource <= value &&
            rate.HighestRatingFromResource >= value,
        );
        setReasons(response);
      }
    }
    setFeedbackStatus({
      ...feedbackStatus,
      RatingResource: value,
      ComplaintReasonIdentifier: '',
    });
    setReasonValue({ label: 'Välj', value: null });
  };

  const handleChange = (event, identifier) => {
    switch (identifier) {
      case timeSheetContentTypes.RATING:
        handleRate(event);
        break;

      case 'reason':
        setReasonValue(event.value);
        setFeedbackStatus({
          ...feedbackStatus,
          ComplaintReasonIdentifier: event.value,
        });
        setShowError(false);
        break;

      case 'comment':
        setCharCount(event.target.value.length);
        setFeedbackStatus({
          ...feedbackStatus,
          Comment: event.target.value,
        });
        break;

      default:
        break;
    }
  };

  const handleSubmit = async () => {
    if (feedbackStatus.RatingResource) {
      // if (
      //   feedbackStatus.ComplaintReasonIdentifier === '' ||
      //   feedbackStatus.ComplaintReasonIdentifier === null
      // ) {
      //   setShowError(true);
      // } else {
      const rate = {
        Comment: feedbackStatus.Comment ? feedbackStatus.Comment : '',
        RatingResource: feedbackStatus.RatingResource,
        WorkAssignmentIdentifier: feedbackStatus.WorkAssignmentIdentifier
          ? feedbackStatus.WorkAssignmentIdentifier
          : null,
      };
      if (rate) {
        sendRatingData(rate);
      }
      // }
    }
  };

  const generateOptions = () =>
    reasons.map(complaintReason => ({
      label: complaintReason.ComplaintReasonName,
      value: complaintReason.ComplaintReasonIdentifier,
      ...complaintReason,
    }));

  return (
    <>
      <FeedbackModal
        item={item}
        isVisible={isVisible}
        onClose={onClose}
        onChange={handleChange}
        onSumbit={handleSubmit}
        generateOptions={generateOptions}
        reasonValue={reasonValue}
        feedbackStatus={feedbackStatus}
        showError={showError}
        charCount={charCount}
      />
    </>
  );
};

FeedbackContainer.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  item: PropTypes.shape({
    WorkAssignmentIdentifier: PropTypes.string,
    ContactPerson: {
      ContactPersonName: PropTypes.string,
    },
    ComplaintReasonName: PropTypes.string,
    ComplaintReasonIdentifier: PropTypes.string,
    OrderNumber: PropTypes.string,
  }),
};
FeedbackContainer.defaultProps = {
  isVisible: false,
  onClose: () => {},
  item: PropTypes.shape({
    WorkAssignmentIdentifier: '',
    ContactPerson: {
      ContactPersonName: '',
    },
    ComplaintReasonName: '',
    ComplaintReasonIdentifier: '',
    OrderNumber: '',
  }),
};

export default FeedbackContainer;
