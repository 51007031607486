/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { convertToDayJsObject, localDayjs as dayJS } from 'utils/dateTimeUtils';

import './style.scss';

import {
  AssignmentType,
  AssignmentStatus,
  AssignmentRequestHandler,
  AssignmentCountdown,
  Translate,
} from 'components';
import moment from 'moment';
import { checkOnGoingAssignment } from 'utils/miscUtil';

const TranslationItem = ({
  className,
  onClick,
  onAccept,
  onReject,
  screen,
  item,
  isSelected,
}) => {
  const [statusColor, setStatusColor] = useState(item.status);
  useEffect(() => {
    const updateStatusColor = () => {
      const isOnGoingStatus = checkOnGoingAssignment(item);
      if (isOnGoingStatus) {
        setStatusColor('onGoing');
      } else {
        setStatusColor(item.status);
      }
      setTimeout(updateStatusColor, 5000);
    };
    updateStatusColor();
  }, [item]);
  return (
    <>
      <div
        className={`tv-assignment-item__container tv-workassignment__list-item ${
          isSelected
            ? 'tv-assignment-item__container tv-assignment-item-selected'
            : ''
        } ${className}`}
        onClick={() => onClick(item)}
        role="button"
        tabIndex={0}
        onKeyDown={e =>
          e.key === ' ' || e.key === 'Enter' ? onClick(item) : null
        }
      >
        <div className="align-items-center d-flex justify-content-around mx-0 position-relative px-0 tv-assignment-item__row tv-mobile--hide w-100">
          <div className="tv-assignment-item__row--type">
            <div className="tv-assignment-item-text text-max-lenght">
              {item.OrderNumber}
            </div>
          </div>

          <div className="tv-assignment-item__row--type">
            <div className="tv-assignment-item-text text-max-lenght">
              {item.ModeOfTransport}
            </div>
          </div>

          <div className="tv-assignment-item__row--language">
            <div className="tv-assignment-item-text text-max-lenght">
              {moment(item.DateDelivery).format('YYYY-MM-DD')}
            </div>
          </div>

          <div className="tv-assignment-item__row--language">
            <div className="tv-assignment-item-text text-max-lenght">
              {item.Skill}
            </div>
          </div>

          <div className="tv-assignment-item__row--language">
            <div className="tv-assignment-item-text text-max-lenght">
              {item.SkillTarget}
            </div>
          </div>

          <div className="tv-assignment-item__row--language">
            <div className="tv-assignment-item-text text-max-lenght">
              <AssignmentStatus
                className="d-flex justify-content-end text-md-right w-100"
                status={statusColor}
              />
            </div>
          </div>
        </div>
        <MobileView
          item={item}
          screen={screen}
          onAccept={onAccept}
          onReject={onReject}
        />
      </div>
    </>
  );
};

TranslationItem.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  screen: PropTypes.string,
  item: PropTypes.shape({}),
  isSelected: PropTypes.bool,
};
TranslationItem.defaultProps = {
  className: '',
  onClick: () => {},
  onAccept: () => {},
  onReject: () => {},
  screen: '',
  item: {},
  isSelected: false,
};

export default TranslationItem;

const MobileView = ({ item, screen, onAccept, onReject }) => {
  const [statusColor, setStatusColor] = useState(item.OrderStatus);

  useEffect(() => {
    const updateStatusColor = () => {
      const isOnGoingStatus = checkOnGoingAssignment(item);
      if (isOnGoingStatus) {
        setStatusColor('onGoing');
      } else {
        setStatusColor(item.OrderStatus);
      }
      setTimeout(updateStatusColor, 5000);
    };
    updateStatusColor();
  }, [item]);

  return (
    <div className="tv-mobile--show tv-assignment-item-mobile__container">
      <div className="tv-assignment-item-mobile__header">
        <AssignmentType
          status={item.OrderStatus}
          serviceName={item.ArticleName}
          assignment={item}
        />
        <AssignmentStatus status={statusColor} serviceName={item.ArticleName} />
      </div>
      <hr />
      <div className="tv-assignment-item-mobile__content">
        <div className="tv-assignment-item-text text-max-lenght">
          <i className="tv-mobile--show material-icons">language</i>
          {`Källspråk : ${item.Skill}`}
        </div>
        <div className="tv-assignment-item-text text-max-lenght">
          <i className="tv-mobile--show material-icons">translate</i>
          {`Målspråk : ${item.SkillTarget}`}
        </div>
        <div className="tv-assignment-item-text text-max-lenght">
          <i className="tv-mobile--show material-icons">calendar_today</i>
          {moment(item.DateDelivery).format('YYYY-MM-DD')}
        </div>
        <>
          <div className="tv-assignment-item-text text-max-lenght">
            <i className="tv-mobile--show material-icons">assignment</i>
            {`${Translate({ content: 'assignment.orderNumber' })} ${
              item.OrderNumber
            }`}
          </div>
        </>
      </div>
    </div>
  );
};
