import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import { iconForTheServiceType } from 'utils/miscUtil';

function AssignmentType({
  status,
  serviceName,
  className,
  isColoredIcon,
  showShortText,
  assignment,
}) {
  return (
    <div className={`tv-assignment-type__container ${className}`}>
      <i
        className={` icon tv-assignment-type-article-icon material-icons ${
          isColoredIcon
            ? `tv-assignment-type-article-icon-color--${status}`
            : ''
        } `}
      >
        {iconForTheServiceType(assignment)}
      </i>
      <div className="tv-assignment-type-article-name">
        {!showShortText ? serviceName : serviceName}
      </div>
      {assignment.IsSpecialAssignment ? (
        <div className="tv-assignment-type-article-name ml-1">
          (specialuppdrag)
        </div>
      ) : null}
    </div>
  );
}
AssignmentType.propTypes = {
  status: PropTypes.string,
  serviceName: PropTypes.string,
  className: PropTypes.string,
  isColoredIcon: PropTypes.bool,
  showShortText: PropTypes.bool,
  assignment: PropTypes.shape({
    IsSpecialAssignment: PropTypes.bool,
  }),
};
AssignmentType.defaultProps = {
  status: 'available',
  serviceName: 'Telefontolkning',
  className: '',
  isColoredIcon: true,
  showShortText: false,
  assignment: {
    IsSpecialAssignment: false,
  },
};

export default AssignmentType;
