/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Toggle,
  AvailabilityNowModal,
  Translate,
  Spinner,
  Modal,
  Button,
} from 'components';
import { fulfilled, rejected, pending, clean } from 'redux-saga-thunk';
import {
  resourceCreateRequest,
  resourceListReadRequest,
  resourceDetailReadRequest,
} from 'store/actions';
import { fromResource } from 'store/selectors';
import { currentDate, convertToDayJsObject } from 'utils/dateTimeUtils';
import useWorkAssignment from 'hooks/useWorkAssignment';
import FocusTrap from 'focus-trap-react';

function getFaviconEl() {
  return document.getElementById('favicon');
}
function AvailabilityNowContainer({
  isfulfilledCreate,
  isfulfilledDelete,
  isFulfilledAutoAssignOpeningHours,
  isPending,
  createAutoAssignAvailability,
  getAutoAssignOpeningHours,
  deleteAutoAssignAvailability,
  getResourceAvailability,
}) {
  const {
    searchWorkAssignment,
    isLoading,
    workAssignmentListFulfilled,
    workAssignmentList,
  } = useWorkAssignment();
  const favicon = getFaviconEl();
  const [checked, setChecked] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [removeEmergency, setRemoveEmergency] = useState(false);
  const [
    stopAvailableNowModalVisible,
    setStopAvailableNowModalVisible,
  ] = useState(false);
  const [isAvailableNow, setIsAvailableNow] = useState(false);
  const [
    canRegisterAutoAssignOrderAvailability,
    setCanRegisterAutoAssignOrderAvailability,
  ] = useState(false);
  const [availableDateTimeTo, setAvalableDateTimeTo] = useState(null);
  const [availableEndTime, setAvailableEndTime] = useState(null);
  const [displayWarningMessage, setDisplayWarningMessage] = useState(false);
  const [openingHours, setOpeningHours] = useState({
    from: currentDate(),
    to: currentDate().add(12, 'hours'),
  });

  let checkAvailabilityExpiry = useRef(null);
  const resourceAvailability = useSelector(
    state => fromResource.getList(state, 'resourceAvailability') || [],
  );
  const userProfile = useSelector(state =>
    fromResource.getUserProfile(state, 'userProfile'),
  );
  const openHourSetting = useSelector(state =>
    fromResource.getDetail(state, 'autoAssignOpeningHours'),
  );
  useEffect(() => {
    if (isFulfilledAutoAssignOpeningHours) {
      setModalVisible(true);
      clean('autoAssignOpeningHoursDetailRead');
    }
  }, [isFulfilledAutoAssignOpeningHours]);
  // useEffect(() => {
  //   if (openHourSetting?.AutoAssignOrderOpeningHours) {
  //     setOpeningHours({
  //       from: convertToDayJsObject(
  //         openHourSetting?.AutoAssignOrderOpeningHours[0].DatetimeOpenFrom,
  //         'YYYY-MM-DD HH:mm',
  //       ),
  //       to: convertToDayJsObject(
  //         openHourSetting?.AutoAssignOrderOpeningHours[0].DatetimeOpenTo,
  //         'YYYY-MM-DD HH:mm',
  //       ),
  //     });
  //   }
  // }, [openHourSetting]);
  useEffect(() => {
    const availablityTime = resourceAvailability.filter(
      item => item.AvailabilityType === 4,
    );
    if (availablityTime.length > 0) {
      const { DatetimeTo } = availablityTime[0];
      setAvailableEndTime(DatetimeTo);
      if (
        DatetimeTo &&
        !convertToDayJsObject(DatetimeTo).isBefore(currentDate())
      ) {
        setAvalableDateTimeTo(DatetimeTo);
      }
      if (availablityTime.length) {
        setIsAvailableNow(true);
        setChecked(true);
      }
    }
  }, [resourceAvailability]);

  useEffect(() => {
    if (userProfile != null) {
      const {
        Profile: { CanRegisterAutoAssignOrderAvailability },
      } = userProfile;
      setCanRegisterAutoAssignOrderAvailability(
        CanRegisterAutoAssignOrderAvailability,
      );
    }
  }, [userProfile]);

  useEffect(() => {
    if (checked) {
      checkAvailabilityExpiry = setInterval(
        availableDateTime => {
          if (checked && availableDateTime) {
            const currentDateTime = currentDate();
            const autoAssignEndTime = convertToDayJsObject(availableDateTime);
            if (autoAssignEndTime.isBefore(currentDateTime)) {
              favicon.href = '/favicon.png';
              setChecked(false);
            } else {
              favicon.href = '/favicon.png';
            }
          } else {
            favicon.href = '/favicon.png';
          }
        },
        1000,
        availableDateTimeTo,
      );
    }
    return () => {
      clearInterval(checkAvailabilityExpiry);
    };
  }, [checked, availableDateTimeTo]);

  useEffect(() => {
    if (isfulfilledCreate) {
      setModalVisible(false);
      getResourceAvailability();
    }
  }, [isfulfilledCreate]);

  useEffect(() => {
    if (isfulfilledDelete) {
      setIsAvailableNow(false);
      getResourceAvailability();
    }
  }, [isfulfilledDelete]);

  const toggleHandler = () => {
    if (!checked) {
      // getAutoAssignOpeningHours();
      setModalVisible(true);
      setOpeningHours({
        from: currentDate(),
        to: currentDate().add(12, 'hours'),
      });
    } else {
      searchWorkAssignment();
      setRemoveEmergency(true);
    }
  };

  useEffect(() => {
    if (workAssignmentListFulfilled && removeEmergency) {
      const currentTime = currentDate();
      const endTime = convertToDayJsObject(availableEndTime);
      const list = workAssignmentList.filter(
        assignment =>
          currentTime.isBefore(
            convertToDayJsObject(assignment.DatetimeOrderFrom),
          ) &&
          convertToDayJsObject(assignment.DatetimeOrderFrom).isBefore(endTime),
      );
      if (list.length > 0) {
        setDisplayWarningMessage(true);
      } else {
        setDisplayWarningMessage(false);
      }
      setStopAvailableNowModalVisible(true);
    }
  }, [workAssignmentListFulfilled, workAssignmentList]);
  const onCloseModal = () => {
    if (!isAvailableNow) {
      setChecked(false);
    }
    setModalVisible(false);
  };
  const onCreateAvailability = time => {
    setChecked(!checked);

    if (time > currentDate().endOf('day')) {
      createAutoAssignAvailability({
        TimeTo: time.format('HH:mm'),
        IsTimeUntilTomorrow: true,
      });
    } else {
      createAutoAssignAvailability({
        TimeTo: time.format('HH:mm'),
        IsTimeUntilTomorrow: false,
      });
    }
    setIsAvailableNow(true);
  };
  const onConfirmStopAvailable = () => {
    setChecked(false);
    deleteAutoAssignAvailability();
  };
  const closeStopAvailableNowModal = () => {
    setStopAvailableNowModalVisible(false);
    setRemoveEmergency(false);
  };

  // const handleEmergencyInterpretation = event => {
  //   if (event.shiftKey && event.key.toLowerCase() === 'e') {
  //     toggleHandler();
  //   }
  //   if (event.key === 'Escape') {
  //     onCloseModal();
  //     closeStopAvailableNowModal();
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('keydown', handleEmergencyInterpretation);
  //   return () => {
  //     document.removeEventListener('keydown', handleEmergencyInterpretation);
  //   };
  // }, [checked]);

  return (
    <>
      <div className="d-flex">
        <span className="tv-availability-now-modal__label tv-mobile--hide">
          <Translate content="availabilityNow.title" />
        </span>
        <div className={`ml-2 ${checked ? 'pulsate ' : ''} position-relative`}>
          <Toggle
            onChange={toggleHandler}
            checked={checked}
            isInactive={!canRegisterAutoAssignOrderAvailability}
            inactiveMessage="Du är inte aktiverad för TolkaDirekt. För att bli aktiverad så måste du ringa till oss på 08-120 800 05. "
            label="Gör dig tillgänglig för Akut tolk"
          />
        </div>
      </div>
      {modalVisible && canRegisterAutoAssignOrderAvailability && (
        <FocusTrap
          focusTrapOptions={{
            escapeDeactivates: false,
            allowOutsideClick: true,
          }}
        >
          <div>
            <AvailabilityNowModal
              isVisible={modalVisible}
              onClose={onCloseModal}
              onConfirm={onCreateAvailability}
              openingHours={openingHours}
              autoAssignIsActive={
                openHourSetting?.AutoAssignOrderOpeningHours
                  ? openHourSetting?.AutoAssignOrderOpeningHours[0].IsActive
                  : true
              }
              autoAssignSet={openHourSetting?.AutoAssignOrderOpeningHours}
            />
          </div>
        </FocusTrap>
      )}
      {stopAvailableNowModalVisible && canRegisterAutoAssignOrderAvailability && (
        <FocusTrap
          focusTrapOptions={{
            escapeDeactivates: false,
            allowOutsideClick: true,
            preventScroll: true,
          }}
        >
          <div>
            <Modal
              className="tv-assignment-cancel-modal__container"
              hideHeader
              onClose={closeStopAvailableNowModal}
              onOverlayClick={closeStopAvailableNowModal}
              isVisible={stopAvailableNowModalVisible}
            >
              <p>Vill du avsluta din tillgänglighet för Akut tolk?</p>
              {displayWarningMessage && (
                <p style={{ fontWeight: 600 }}>
                  Observera att du har redan tilldelade akut tolk-uppdrag. Dessa
                  måste utföras alternativt kontakta Tolkförmedling Väst på
                  0770-813 000.
                </p>
              )}
              <div className="tv-availability-now-modal__actions tv-availability-now-modal__actions-emergency">
                <Button
                  onClick={closeStopAvailableNowModal}
                  palette="outline"
                  className="tv-assignment-cancel-modal__button"
                  label="Avbryt"
                />
                <Button
                  onClick={onConfirmStopAvailable}
                  className="tv-assignment-cancel-modal__button"
                  label="Bekräfta"
                />
              </div>
            </Modal>
          </div>
        </FocusTrap>
      )}

      {(isPending || isLoading) && <Spinner />}
    </>
  );
}

AvailabilityNowContainer.propTypes = {};

const mapStateToProps = state => ({
  isPending:
    pending(state, 'createAutoAssignOrderAvailabilityCreate') ||
    pending(state, 'deleteAutoAssignOrderAvailabilityCreate') ||
    pending(state, 'autoAssignOpeningHoursDetailRead'),
  isfulfilledCreate: fulfilled(
    state,
    'createAutoAssignOrderAvailabilityCreate',
  ),
  isFulfilledAutoAssignOpeningHours: fulfilled(
    state,
    'autoAssignOpeningHoursDetailRead',
  ),
  isfulfilledDelete: fulfilled(
    state,
    'deleteAutoAssignOrderAvailabilityCreate',
  ),
  isfailed: rejected(state, 'createAutoAssignOrderAvailabilityCreate'),
});

const STANDARD_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const mapDispatchToProps = dispatch => ({
  createAutoAssignAvailability: params => {
    const favicon = getFaviconEl();
    favicon.href = '/favicon.png';
    dispatch(
      resourceCreateRequest('createAutoAssignOrderAvailability', params),
    );
  },
  getAutoAssignOpeningHours: () => {
    const params = {
      DateFrom: currentDate().format(STANDARD_DATE_TIME_FORMAT),
      DateTo: currentDate().format(STANDARD_DATE_TIME_FORMAT),
    };
    dispatch(resourceDetailReadRequest('autoAssignOpeningHours', params));
  },
  deleteAutoAssignAvailability: () => {
    const favicon = getFaviconEl();
    favicon.href = '/favicon.png';
    dispatch(resourceCreateRequest('deleteAutoAssignOrderAvailability', {}));
  },
  getResourceAvailability: () => {
    const params = {
      DatetimeFrom: currentDate().format(STANDARD_DATE_TIME_FORMAT),
      DatetimeTo: currentDate().format(STANDARD_DATE_TIME_FORMAT),
    };
    dispatch(
      resourceListReadRequest(
        'resourceAvailability',
        'AvailabilityIntervals',
        params,
        true,
      ),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvailabilityNowContainer);
