import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const IconButton = ({ iconName, className, onClick, label, ...rest }) => (
  <button
    type="button"
    onClick={onClick}
    className={`tv-icon_button ${className}`}
    tabIndex={0}
    {...rest}
  >
    <i className={`fa fa-${iconName}`} aria-label={label} />
  </button>
);

IconButton.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

IconButton.defaultProps = {
  iconName: '',
  className: '',
  onClick: () => {},
  label: '',
};

export default IconButton;
