import React from 'react';
import './style.scss';

function PersonalData() {
  return (
    <div className="processing-personal-data">
      <p>
        Du som är i kontakt med Tolkförmedling Väst ska känna dig trygg med att
        dina personuppgifter behandlas på ett säkert och lagligt sätt.
      </p>
      <p>
        Läs mer om behandling av personuppgifter på förbundets{' '}
        <a
          href="https://www.tolkformedlingvast.se/om-tfv/behandling-av-personuppgifter.html"
          target="_blank"
          rel="noreferrer"
        >
          webbplats.
        </a>
      </p>
    </div>
  );
}

export default PersonalData;
