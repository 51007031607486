import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';

import './style.scss';

function AssignmentNavigator({ onClickPrevious, onClickNext, className }) {
  return (
    <div className={`${className} tv-assignment-navigator__container`}>
      <div className="tv-assignment-navigator__content">
        <Button
          onClick={onClickPrevious}
          className="back-icon"
          palette="text"
          label={<i className="fa fa-chevron-left" />}
          aria-label="Föregående uppdrag"
        />
        <span
          role="button"
          onKeyPress={onClickPrevious}
          onClick={onClickPrevious}
          aria-hidden="true"
          className="pr-3"
        >
          Föregående uppdrag
        </span>
      </div>
      <div className="tv-assignment-navigator__content">
        <span
          role="button"
          onKeyPress={onClickNext}
          onClick={onClickNext}
          aria-hidden="true"
          className="pl-3"
        >
          Nästa uppdrag
        </span>
        <Button
          onClick={onClickNext}
          className="close-icon"
          palette="text"
          label={<i className="fa fa-chevron-right" />}
          aria-label="Nästa uppdrag"
        />
      </div>
    </div>
  );
}

AssignmentNavigator.propTypes = {
  onClickPrevious: PropTypes.func,
  onClickNext: PropTypes.func,
  className: PropTypes.string,
};
AssignmentNavigator.defaultProps = {
  onClickPrevious: () => {},
  onClickNext: () => {},
  className: '',
};

export default AssignmentNavigator;
