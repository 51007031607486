import React from 'react';
import { CalendarContainer, WelcomeMessageModalContainer,WelcomeMessageContainer } from 'containers';

function DashboardPage() {
  return (
    <>
      <div className="d-flex w-100">
        <CalendarContainer />
      </div>
      <WelcomeMessageContainer />
      {/* <WelcomeMessageModalContainer/> */}
    </>
  );
}

DashboardPage.propTypes = {};

export default DashboardPage;
