import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'components';

import './style.scss';

const GeneralSuccessModal = ({
  isVisible,
  onOverlayClick,
  onClose,
  onClick,
  title,
  body,
}) => (
  <Modal
    className="tv-general-success-modal__container"
    hideHeader
    onClose={onClose}
    onOverlayClick={onOverlayClick}
    isVisible={isVisible}
  >
    <h2 className="tv-general-success-modal__title">{title}</h2>
    <p className="tv-general-success-modal__text">{body}</p>
    <Button
      onClick={onClick}
      className="tv-general-success-modal__btn"
      label="OK"
    />
  </Modal>
);

GeneralSuccessModal.propTypes = {
  isVisible: PropTypes.bool,
  onOverlayClick: PropTypes.func,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
};
GeneralSuccessModal.defaultProps = {
  isVisible: false,
  onOverlayClick: () => {},
  onClose: () => {},
  onClick: () => {},
  title: '',
  body: '',
};

export default GeneralSuccessModal;
