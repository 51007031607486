import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Collapse = ({
  expand,
  className,
  handleShowAccordion,
  header,
  children,
}) => (
  <>
    <div className={`tv-collapse__wrapper ${className}`}>
      <div className="tv-collapse__head">
        <div className="tv-collapse__heading">{header}</div>
        <div
          className={`${
            expand ? 'tv-collapse__content--opened' : 'tv-collapse__content'
          }`}
        >
          <div className="tv-collapse__content-inner">{children}</div>
        </div>
      </div>
      <div
        role="button"
        tabIndex="-1"
        onClick={handleShowAccordion}
        onKeyDown={() => {}}
        className="tv-collapse__item-menu tv-display-flex"
      >
        <i
          className={`icon-arrow-expand  ${
            expand
              ? 'tv-collapse__icon fas fa-chevron-down'
              : 'fas fa-chevron-down'
          }`}
        />
      </div>
    </div>
  </>
);

Collapse.propTypes = {
  expand: PropTypes.bool,
  className: PropTypes.string,
  handleShowAccordion: PropTypes.func,
  header: PropTypes.string,
  children: PropTypes.node,
};
Collapse.defaultProps = {
  expand: false,
  className: '',
  handleShowAccordion: () => {},
  header: 'Header',
  children: '',
};

export default Collapse;
