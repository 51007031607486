/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  StatusFilter,
  Field,
  Translate,
  DateRangePicker,
  Dropdown,
  DatePicker,
  Button,
} from 'components';
import PropTypes from 'prop-types';
import { extendedDayJs } from 'utils/dateTimeUtils';
import './style.scss';
import FocusTrap from 'focus-trap-react';

const MobileHeader = ({ onClose }) => (
  <>
    <div className="filter-panel__header">
      <div className="filter-panel__header-title">
        {Translate({ content: 'calendar.filterMenue' })}
      </div>
      <div
        className="filter-panel__header-close material-icons"
        onClick={onClose}
        onKeyDown={e => (e.key === 'Enter' ? onClose() : null)}
        tabIndex={0}
        role="button"
      >
        clear
      </div>
    </div>
  </>
);

const FilterPanel = ({
  filterConfig: {
    showSearchFilter,
    showDateFilter,
    showTypeFilter,
    showStatusFilter,
  },
  onChange,
  options,
  onKeyPress,
  showPanel,
  onClose,
  searchDateRange,
  handleClearFilter,
  selectedFilter,
  typeFilterValue,
  handleAllFilters,
}) => {
  const [selectedFromDate, setSelectedFromDate] = useState(
    extendedDayJs(searchDateRange.start).toDate(),
  );
  const [selectedToDate, setSelectedToDate] = useState(
    extendedDayJs(searchDateRange.end).toDate(),
  );

  const [selectedFilters, setSelectedFilters] = useState({});
  const [mobileStatus, setMobileStatus] = useState(selectedFilter);
  const [mobileTypeFilter, setMobileTypeFilter] = useState(typeFilterValue);

  useEffect(() => {
    setSelectedFromDate(extendedDayJs(searchDateRange.start).toDate());
    setSelectedToDate(extendedDayJs(searchDateRange.end).toDate());
  }, [searchDateRange]);

  const handleChange = (event, identifier = '') => {
    if (identifier === 'datePicker') {
      if (showPanel) {
        setSelectedFilters({
          ...selectedFilters,
          [identifier]: [...event],
        });
      } else {
        onChange(event, identifier);
      }
    } else if (event?.target?.name === 'searchText') {
      onChange(event, event.target.name);
    } else if (identifier === 'type') {
      if (showPanel) {
        setMobileTypeFilter(event.value);
      } else {
        onChange(event, identifier);
      }
    } else if (identifier === 'status') {
      if (showPanel) {
        setMobileStatus([...event]);
      } else {
        onChange(event, identifier);
      }
    }
  };

  const handleFilters = async () => {
    handleAllFilters(mobileStatus, mobileTypeFilter, [
      selectedFromDate,
      selectedToDate,
    ]);

    onClose();
  };

  const generateOptions = () =>
    options.map(item => ({ label: item.name, value: item.value }));

  return (
    <>
      {showPanel && (
        <FocusTrap>
          <div className={` filter-panel filter-panel__container`}>
            <MobileHeader onClose={onClose} />
            {showSearchFilter && (
              <>
                <Field
                  placeholder={Translate({ content: 'filterPanel.search' })}
                  name="searchText"
                  icon="search"
                  showIcon
                  onBlur={handleChange}
                  onKeyUp={onKeyPress}
                  className={{
                    containerClass: 'filter-panel__search tv-mobile--hide',
                  }}
                />
              </>
            )}
            {showDateFilter && (
              <DateRangePicker
                placeholder={Translate({ content: 'filterPanel.selectDate' })}
                onChange={handleChange}
                className="tv-mobile--hide"
                searchDateRange={searchDateRange}
              />
            )}
            {showDateFilter && (
              <>
                <div className="filter-panel__field tv-mobile--show">
                  <label htmlFor="dateFrom">
                    {Translate({ content: 'assignment.dateFrom' })}
                  </label>
                  <DatePicker
                    id="dateFrom"
                    selectedDate={selectedFromDate}
                    name="dateFrom"
                    maxDate={selectedToDate}
                    onChange={setSelectedFromDate}
                  />
                </div>
                <div className="filter-panel__field tv-mobile--show">
                  <label htmlFor="dateTo">
                    {Translate({ content: 'assignment.dateTo' })}
                  </label>
                  <DatePicker
                    id="dateTo"
                    selectedDate={selectedToDate}
                    minDate={selectedFromDate}
                    name="dateTo"
                    onChange={toDate => {
                      setSelectedToDate(toDate);
                      handleChange([selectedFromDate, toDate], 'datePicker');
                    }}
                  />
                </div>
              </>
            )}
            {showTypeFilter && (
              <div className="filter-panel__field">
                <label>Typ av uppdrag</label>
                <Dropdown
                  label="Typ av uppdrag"
                  placeholder={Translate({ content: 'filterPanel.select' })}
                  name="type"
                  value={mobileTypeFilter}
                  options={generateOptions()}
                  onChange={value => handleChange(value, 'type')}
                  className="filter-panel__assignment-type"
                />
              </div>
            )}
            {showStatusFilter && (
              <StatusFilter
                onClick={handleChange}
                selectedFilters={selectedFilter}
              />
            )}
            <div className="tv-calendar__mobile-footer">
              <Button
                label={Translate({ content: 'assignment.clearFilter' })}
                palette="outline"
                onClick={() => {
                  handleClearFilter();
                  setSelectedFilters({});
                  setMobileTypeFilter('');
                }}
              />

              <Button
                label={Translate({ content: 'assignment.filter' })}
                onClick={handleFilters}
              />
            </div>
          </div>
        </FocusTrap>
      )}
      {!showPanel && (
        <div className={` filter-panel tv-mobile--hide`}>
          {showSearchFilter && (
            <>
              <Field
                placeholder={Translate({ content: 'filterPanel.search' })}
                name="searchText"
                icon="search"
                showIcon
                onBlur={handleChange}
                onKeyUp={onKeyPress}
                className={{
                  containerClass: 'filter-panel__search tv-mobile--hide',
                }}
              />
            </>
          )}
          {showDateFilter && (
            <DateRangePicker
              placeholder={Translate({ content: 'filterPanel.selectDate' })}
              onChange={handleChange}
              className="tv-mobile--hide"
              searchDateRange={searchDateRange}
            />
          )}
          {showDateFilter && (
            <>
              <div className="filter-panel__field tv-mobile--show">
                <label htmlFor="dateFrom">
                  {Translate({ content: 'assignment.dateFrom' })}
                </label>
                <DatePicker
                  id="dateFrom"
                  selectedDate={selectedFromDate}
                  name="dateFrom"
                  maxDate={selectedToDate}
                  onChange={setSelectedFromDate}
                />
              </div>
              <div className="filter-panel__field tv-mobile--show">
                <label htmlFor="dateTo">
                  {Translate({ content: 'assignment.dateTo' })}
                </label>
                <DatePicker
                  id="dateTo"
                  selectedDate={selectedToDate}
                  minDate={selectedFromDate}
                  name="dateTo"
                  onChange={toDate => {
                    setSelectedToDate(toDate);
                    handleChange([selectedFromDate, toDate], 'datePicker');
                  }}
                />
              </div>
            </>
          )}
          {showTypeFilter && (
            <div className="filter-panel__field">
              <label>Typ av uppdrag</label>
              <Dropdown
                label="Typ av uppdrag"
                placeholder={Translate({ content: 'filterPanel.select' })}
                name="type"
                value={mobileTypeFilter}
                options={generateOptions()}
                onChange={value => handleChange(value, 'type')}
                className="filter-panel__assignment-type"
              />
            </div>
          )}
          {showStatusFilter && (
            <StatusFilter
              onClick={handleChange}
              selectedFilters={selectedFilter}
            />
          )}
          <div className="tv-calendar__mobile-footer">
            <Button
              label={Translate({ content: 'assignment.clearFilter' })}
              palette="outline"
              onClick={() => {
                handleClearFilter();
                setSelectedFilters({});
                setMobileTypeFilter('');
              }}
            />

            <Button
              label={Translate({ content: 'assignment.filter' })}
              onClick={handleFilters}
            />
          </div>
        </div>
      )}
    </>
  );
};

FilterPanel.propTypes = {
  filterConfig: PropTypes.shape({
    showSearchFilter: PropTypes.bool,
    showDateFilter: PropTypes.bool,
    showTypeFilter: PropTypes.bool,
    showStatusFilter: PropTypes.bool,
  }),
  showPanel: PropTypes.bool,
  typeFilterValue: PropTypes.string,
  onChange: PropTypes.func,
  handleAllFilters: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onKeyPress: PropTypes.func,
  handleClearFilter: PropTypes.func,
  onClose: PropTypes.func,
};

FilterPanel.defaultProps = {
  filterConfig: {
    showSearchFilter: true,
    showDateFilter: true,
    showTypeFilter: true,
    showStatusFilter: true,
  },
  onChange: () => {},
  onKeyPress: () => {},
  onClose: () => {},
  handleClearFilter: () => {},
  handleAllFilters: () => {},
  typeFilterValue: '',
  showPanel: false,
  options: [
    {
      name: 'Välj',
      value: '',
    },
  ],
};

export default FilterPanel;
