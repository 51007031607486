/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import raf from 'raf';
import './style.scss';
import {
  currentDate,
  extendedDayJs,
  dayJsDuration,
  convertToDayJsObject,
} from 'utils/dateTimeUtils';

const scrollTo = (element, to, duration) => {
  // jump to target if duration zero
  if (duration <= 0) {
    raf(() => {
      element.scrollTop = to;
    });
    return;
  }
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 10;

  raf(() => {
    element.scrollTop += perTick;
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 10);
  });
};
function generateDuration(startTime, endTime) {
  const duration = dayJsDuration(endTime.diff(startTime));

  // duration in hours
  const hours = parseInt(duration.asHours(), 10);

  // duration in minutes
  const minutes = parseInt(duration.asMinutes(), 10) % 60;

  return `(${hours === 0 ? '' : `${hours}h `}${
    minutes === 0 ? '' : `${minutes}m`
  })`;
}
const passingCondition = (allowNextDay, i, diff, steps) => {
  if (!allowNextDay) return i < diff;
  return i <= 24 * (60 / steps) - 1;
};
function generateTimeList(
  allowNextDay,
  startFromDayBegin,
  initalTime,
  steps,
  isNotRanged,
  baseTime,
  maxTime = currentDate().endOf('day'),
  checkAvailability,
) {
  const arr = [];

  const start = currentDate();
  const remainder = start.minute() % steps;
  const dateTime = extendedDayJs(start).subtract(remainder, 'minutes');

  let currentTime = isNotRanged ? dateTime : extendedDayJs(baseTime);

  if (startFromDayBegin) {
    currentTime = currentDate().startOf('day');
  }

  if (checkAvailability) {
    const startTime = currentDate();
    const remainderForAvailable = 15 - (startTime.minute() % 15);
    if (remainderForAvailable < 15) {
      currentTime = convertToDayJsObject(startTime).add(
        remainderForAvailable + 15,
        'minutes',
      );
    } else {
      currentTime = convertToDayJsObject(startTime).add(15, 'minutes');
    }
  }

  let i = 0;

  const timeDifference = dayJsDuration(
    maxTime.diff(extendedDayJs(currentTime)),
  );
  const diff = parseInt(timeDifference.asMinutes() / steps, 10);

  while (passingCondition(allowNextDay, i, diff, steps)) {
    if (startFromDayBegin && i === 0) {
      arr.push({
        id: i,
        value: currentTime.format('HH:mm'),
        duration: generateDuration(baseTime, currentTime),
        stringValue: JSON.stringify({
          id: i,
          value: currentTime.format('HH:mm'),
        }),
        time: currentTime.format('YYYY-MM-DD HH:mm:ss'),
      });
    }

    currentTime = currentTime.add(steps, 'minute');

    arr.push({
      id: i,
      value: currentTime.format('HH:mm'),
      duration: generateDuration(baseTime, currentTime),
      stringValue: JSON.stringify({
        id: i,
        value: currentTime.format('HH:mm'),
      }),
      time: currentTime.format('YYYY-MM-DD HH:mm:ss'),
    });
    i += 1;
  }
  return arr;
}

function isValidFormat(timeString) {
  if (!timeString.includes(':')) return false;
  const timeParts = timeString.split(':');
  if (timeParts[0] < 0 || timeParts[0] > 23) return false;
  if (timeParts[1] < 0 || timeParts[1] > 59) return false;
  return true;
}

function setValidFormat(timeString) {
  try {
    if (!timeString) return currentDate().add(5, 'minute').format('HH:mm');

    if (timeString.includes(':')) {
      const resetValue = [];
      const timeParts = timeString.split(':');
      if (timeParts[0] < 0) resetValue.push('00');
      else if (timeParts[0] > 23) resetValue.push('23');
      else resetValue.push(timeParts[0]);
      if (timeParts[1] < 0) resetValue.push('00');
      else if (timeParts[1] > 59) resetValue.push('59');
      else resetValue.push(timeParts[1]);
      if (resetValue[0].length === 1) resetValue[0] = `0${resetValue[0]}`;
      if (resetValue[1].length === 1) resetValue[1] = `0${resetValue[1]}`;
      return resetValue.join(':');
      // eslint-disable-next-line no-else-return
    } else if (timeString.length > 2 || timeString.length === 2) {
      if (timeString.length === 3) {
        timeString = `0${timeString}`;
      }
      return setValidFormat(
        `${timeString.substring(0, 2)}:${
          timeString.length > 2 ? timeString.substring(2) : '00'
        }`,
      );
    }
    return setValidFormat(`0${timeString[0]}:00`);
  } catch (ex) {
    return '12:00';
  }
}

const TimePicker = ({
  id,
  defaultValue,
  durationCalculate,
  className,
  inputName,
  allowNextDay,
  startFromDayBegin,
  disabled,
  minuteStep,
  isNotRanged,
  hideDropDown,
  inputClassName,
  maxTime,
  checkAvailability,
  label,
  ...props
}) => {
  const [dropDownList, setDropDownList] = useState(
    generateTimeList(
      allowNextDay,
      startFromDayBegin,
      defaultValue,
      minuteStep,
      isNotRanged,
      durationCalculate,
      maxTime,
      checkAvailability,
    ),
  );
  const [selectedValue, setSelectedValue] = useState(dropDownList[0]);

  const inputRef = useRef(null);

  const dropDownRef = useRef(null);

  const containerRef = useRef(null);

  const onInputValueChange = date => {
    const [hours, minutes] = inputRef.current.value.split(':');
    const timeValue = extendedDayJs(defaultValue)
      .set('hour', hours)
      .set('minute', minutes.substring(0, 2));

    if (checkAvailability) {
      if (!date) {
        props.onChange(timeValue);
      }
    } else {
      props.onChange(timeValue);
    }
    // props.onChange(timeValue);
  };

  const onSelectChange = e => {
    try {
      inputRef.current.value = e.target.innerText;
      setSelectedValue(
        JSON.parse(e.target.getAttribute('data-dropdown-value')),
      );
      const date = e.target.getAttribute('data-duration');
      props.timeHandler(extendedDayJs(date));
      onInputValueChange(date);
      // inputRef.current.blur();
      // containerRef.current.classList.remove('active');
      inputRef.current.focus();
    } catch (ex) {
      console.log(ex);
    }
  };

  const scrollToSelected = (duration, dropDownElement) => {
    const select = dropDownElement;
    const list = dropDownElement;
    if (!list) {
      return;
    }
    if (!dropDownList.length) {
      return;
    }

    let index =
      dropDownList.filter(x =>
        x.value.includes(inputRef.current.value.split(':')[0] || '00'),
      )[0]?.id || selectedValue.id;
    if (index < 0) {
      index = 0;
    }
    const topOption = dropDownElement.children[index];
    const to = topOption.offsetTop;
    scrollTo(select, to, duration);
  };

  const onInputBlur = e => {
    const inputDisplayValue = isValidFormat(e.target.value)
      ? e.target.value
      : setValidFormat(e.target.value);

    // eslint-disable-next-line react/prop-types
    const oldValue = defaultValue.format('HH:mm');

    if (inputDisplayValue !== oldValue) {
      inputRef.current.value = inputDisplayValue;
      onInputValueChange();
    }
  };

  const openDropDown = e => {
    const dropDownElement = e.target.parentElement;
    try {
      if (!dropDownElement.classList.contains('active')) {
        dropDownElement.classList.add('active');
        scrollToSelected(120, dropDownElement.querySelector('ul'));
      } else {
        dropDownElement.classList.remove('active');
        // dropDownElement.blur();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const scrollList = e => {
    const dropDownElement = e.target.parentElement;
    dropDownElement.classList.add('active');
    scrollToSelected(120, dropDownElement.querySelector('ul'));
  };

  const onBlur = event => {
    const { target } = event;

    setTimeout(() => {
      if (
        containerRef?.current &&
        containerRef.current.classList.contains('keyboard_focus')
      ) {
        // containerRef.current.classList.remove('active');
      } else if (
        containerRef?.current &&
        containerRef.current.classList.contains('active')
      ) {
        containerRef.current.classList.remove('active');
      }
    }, 250);
  };

  const onKeyboardFocus = e => {
    const dropDownElement = e.target.parentElement;
    try {
      if (!dropDownElement.classList.contains('keyboard_focus')) {
        dropDownElement.classList.add('keyboard_focus');
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    inputRef.current.value = extendedDayJs(defaultValue).format('HH:mm');
  }, [defaultValue]);

  useEffect(() => {
    setDropDownList(
      generateTimeList(
        allowNextDay,
        startFromDayBegin,
        defaultValue,
        minuteStep,
        isNotRanged,
        durationCalculate,
        maxTime,
        checkAvailability,
      ),
    );
  }, [defaultValue, durationCalculate, startFromDayBegin]);
  const isNumFieldValidate = e => {
    const key = e.keyCode || e.which; // get key cross-browser
    if ((key < 48 || key > 57) && e.key !== ':') {
      // if it is not a number ascii code
      if (e.preventDefault) e.preventDefault(); // normal browsers
      e.returnValue = false; // IE
    }
  };

  const onKeyDownHandlerChecker = e =>
    e.key === ' ' || e.key === 'Enter' ? openDropDown(e) : null;

  const onKeyDownHandler = e =>
    e.key === 'Tab' ? onKeyboardFocus(e) : onKeyDownHandlerChecker(e);

  return (
    <div
      className={`tv-timepicker__container ${className}`}
      onBlur={onBlur}
      ref={containerRef}
      aria-label={label}
    >
      <input
        type="text"
        id={id}
        name={inputName}
        className={`tv-timepicker__input ${inputClassName}`}
        ref={inputRef}
        onBlur={onInputBlur}
        onFocus={openDropDown}
        onInput={scrollList}
        onKeyPress={isNumFieldValidate}
        disabled={disabled}
        maxLength="5"
        minLength="5"
        aria-label={label}
        onKeyDown={onKeyDownHandler}
        tabIndex={0}
      />
      {!disabled && (
        <div className="tv-timepicker__select-option" onClick={openDropDown} />
      )}
      {!hideDropDown && (
        <ul ref={dropDownRef} className="tv-timepicker__select-panel">
          {dropDownList.map(x => (
            <li
              key={x.stringValue}
              onClick={onSelectChange}
              data-dropdown-value={x.stringValue}
              data-duration={x.time}
              tabIndex={0}
              onKeyDown={e => {
                if (e.key === ' ' || e.key === 'Enter') {
                  e.preventDefault();
                  onSelectChange(e);
                }
                if (e.key === 'Tab' && dropDownList.length === x.id + 1) {
                  console.log('last');
                  inputRef.current.focus();
                }
              }}
            >
              {`${x.value} ${'  '} ${!isNotRanged ? x.duration : ''}`}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

TimePicker.propTypes = {
  defaultValue: PropTypes.shape({}),
  id: PropTypes.string,
  className: PropTypes.string,
  inputName: PropTypes.string,
  disabled: PropTypes.bool,
  isNotRanged: PropTypes.bool,
  onChange: PropTypes.func,
  durationCalculate: PropTypes.shape({}),
  allowNextDay: PropTypes.bool,
  startFromDayBegin: PropTypes.bool,
  rest: PropTypes.shape({}),
  minuteStep: PropTypes.number,
  inputClassName: PropTypes.string,
  hideDropDown: PropTypes.bool,
  maxTime: PropTypes.shape({}),
  timeHandler: PropTypes.func,
  checkAvailability: PropTypes.bool,
  label: PropTypes.string,
};

TimePicker.defaultProps = {
  className: '',
  id: '',
  disabled: false,
  inputClassName: '',
  inputName: 'time',
  isNotRanged: true,
  onChange: () => {},
  defaultValue: currentDate(),
  durationCalculate: currentDate(),
  allowNextDay: false,
  startFromDayBegin: false,
  minuteStep: 1,
  rest: {},
  hideDropDown: false,
  maxTime: currentDate().endOf('day'),
  timeHandler: () => {},
  checkAvailability: false,
  label: 'timepicker',
};
export default TimePicker;
