/* eslint-disable react/prop-types */
import React from 'react';
import { MessageServiceOverview } from 'components';

function MessageServiceOverviewContainer({onClickClose, ...props}) {

  return (
    <>
      <MessageServiceOverview {...props} onClickClose={onClickClose} />
    </>
  );
}

export default MessageServiceOverviewContainer;
