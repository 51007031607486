const merge = require('lodash/merge');

const config = {
  all: {
    env: process.env.REACT_APP_ENV || 'development',
    isDev: process.env.NODE_ENV !== 'production',
    basename: process.env.PUBLIC_PATH,
    isBrowser: typeof window !== 'undefined',
    apiUrl:
      process.env.REACT_APP_MULTISOFT_API ||
      'http://localhost:3000/TFV_Webservices_Stage/WebServices/CRM/',
    apiUrlCommon:
      process.env.REACT_APP_MULTISOFT_API_COMMON ||
      'http://localhost:3000/TFV_Webservices_Stage/WebServices/Common/',
    apiUrlCRMCustomer:
      process.env.REACT_APP_MULTISOFT_API_CRM_CUSTOMER ||
      'http://localhost:3000/TFV_Webservices_Stage/WebServices/CRMCustomer/',
    multiSoftApiKey:
      process.env.REACT_APP_MULTISOFT_API_KEY ||
      'A4779BC0-07FB-4B0D-9CBD-23C269490898',
    mutiSoftCryptoKey:
      process.env.REACT_APP_MULTISOFT_CRYPTO_KEY ||
      '7D28357C-BE0A-4618-9010-6A48E170318F',
    pushNotificationAppKey:
      process.env.REACT_APP_PUSH_NOTIFICATION_APP_KEY ||
      'SDEykmPFTJ6VLQLi0sK4ig',
    pushNotificationToken:
      process.env.REACT_APP_PUSH_NOTIFICATION_TOKEN ||
      'MTpTREV5a21QRlRKNlZMUUxpMHNLNGlnOkZQR2NkMjRzOW0yeTZsTmNkVk1pem9pbGhVZW1ZZmhJV0FJUUZNSGZKLTA',
    pushNotificationVapidPublicKey:
      process.env.REACT_APP_PUSH_NOTIFICATION_VPKEY ||
      'BAJHK_cRIRmBp_ryq-yFkb4hgC28nyREnSBlQy4fex1tUo-DuSg7f_4Zf5i54gqQdx-12bmTyxRongKVwv0aLMA=',
    pushNotificationWebPushId:
      process.env.REACT_APP_PUSH_NOTIFICATION_WEBPUSHID ||
      'tfvinterpreter.aventude.com',
    multiSoftICalcUrl:
      process.env.REACT_APP_MULTISOFT_ICALC_URL ||
      'https://stage.softadmin.se/TFV_Webservices_Stage/publish/file.aspx',
    multiSoftApiName:
      process.env.REACT_APP_MULTISOFT_API_NAME ||
      'tolktest.tolkformedlingvast.se',
  },
  development: {
    logApi: '',
  },
  qa: {
    logApi: '',
  },
  staging: {
    logApi: '',
  },
  production: {
    logApi: '',
  },
};

module.exports = merge(config.all, config[config.all.env]);
