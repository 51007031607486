/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

const Button = ({ type, label, disabled, palette, tabIndex, ...props }) => {
  const btnClass = classNames({
    'tv-button': true,
    'tv-button--disabled': disabled,
    'tv-button--primary': palette === 'primary',
    'tv-button--outline': palette === 'outline',
    'tv-button--secondary': palette === 'secondary',
    'tv-button--text': palette === 'text',
  });
  return (
    <button
      type={type || 'button'}
      {...props}
      className={`${btnClass} ${props.className}`}
      disabled={disabled}
      tabIndex={tabIndex}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  palette: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tabIndex: PropTypes.number,
};

Button.defaultProps = {
  palette: 'primary',
  className: '',
  disabled: false,
  type: 'button',
  label: 'Button',
  tabIndex: 0,
};

export default Button;
