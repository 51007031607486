import React from 'react';
import PropTypes from 'prop-types';
import { AssignmentListPageContainer } from 'containers';

import './style.scss';

const AssignmentListPage = props => (
  <div className="tv-assignment-list__container">
    <div className="tv-assignment-list__wrapper">
      <AssignmentListPageContainer />
    </div>
  </div>
);

AssignmentListPage.propTypes = {};

export default AssignmentListPage;
