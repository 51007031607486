import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'

function AssignmentRating({count}) {
    return (
        <div className="tv-assignment-rating__container">
            <div className="tv-assignment-rating__text-grade">Betyg</div>
        </div>
    )
}

AssignmentRating.propTypes = {
    count:PropTypes.number
}
AssignmentRating.defaultProps = {
    count:4
}

export default AssignmentRating;