export const WORK_ASSIGNMENT_HIDE = 'WORK_ASSIGNMENT_HIDE';

export const workAssignmentHidden = WorkAssignmentIdentifier => ({
  type: WORK_ASSIGNMENT_HIDE,
  payload: WorkAssignmentIdentifier,
  meta: {
    resource: 'assignmentOverviewDetail',
    thunk: 'assignmentOverviewDetailHide',
  },
});
