import React from 'react';
import PropTypes from 'prop-types';
import { TableList, CalendarRangeNavigator, Translate } from 'components';
import { compareObjectBasedOnDate } from 'utils/dateTimeUtils';

import('./style.scss');

function Compensation({
  list,
  navigatorText,
  handleCalendarTravel,
  onDownloadClick,
}) {
  return (
    <div className="tv-compensation__container">
      <div className="tv-compensation_title">
        {Translate({ content: 'settings.compensation' })}
      </div>

      <div className="tv-compensation_navigator">
        <CalendarRangeNavigator
          text={navigatorText}
          onForwardClick={() => handleCalendarTravel('forward')}
          onBackwardClick={() => handleCalendarTravel('backward')}
        />
      </div>
      <div className="tv-compensation_table">
        {Object.keys(list).map(key => (
          <>
            <div className="tv-compensation__month-block">{key}</div>
            <TableList
              list={list[key].sort((a, b) =>
                compareObjectBasedOnDate(a, b, 'FileCreatedDateTime'),
              )}
              key={key}
              onDownloadClick={onDownloadClick}
            />
          </>
        ))}
      </div>
    </div>
  );
}

Compensation.propTypes = {
  list: PropTypes.shape({}),
  navigatorText: PropTypes.string,
  handleCalendarTravel: PropTypes.func,
  onDownloadClick: PropTypes.func,
};
Compensation.defaultProps = {
  list: {},
  navigatorText: '',
  handleCalendarTravel: () => {},
  onDownloadClick: () => {},
};

export default Compensation;
