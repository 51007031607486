/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PreferenceSettings, Spinner, Modal, Button } from 'components';
import { SemesterAllocationContainer } from 'containers';
import { fulfilled, rejected, clean, pending } from 'redux-saga-thunk';
import { fromResource } from 'store/selectors';
import {
  resourceCreateRequest,
  resourceDetailReadRequest,
} from 'store/actions';
import { connect } from 'react-redux';

export const PreferenceSettingContainer = ({
  resourcePreferences,
  isReadPending,
  isUpdatePending,
  onGetPreferences,
  isUpdateFulfilled,
  onUpdatePreferences,
  onUpdateComplete,
}) => {
  const [localBusinessArea, setlocalBusinessArea] = useState([]);
  const [localArticle, setlocalArticle] = useState([]);
  const [localContractGroup, setlocalContractGroup] = useState([]);
  const [prefernceLevel, setprefernceLevel] = useState(1);
  const [selectedArticle, setSelectedArticle] = useState({
    showModal: false,
    article: null,
  });

  useEffect(() => {
    let localPreferenceLevel = 1;
    if (
      resourcePreferences?.ArticlePreferences &&
      resourcePreferences?.CanUpdateArticlePreferences &&
      resourcePreferences?.ArticlePreferences.length > 0
    ) {
      localPreferenceLevel = 1;
      setlocalArticle(resourcePreferences?.ArticlePreferences);
    }
    if (
      resourcePreferences?.AreaOfBusinessPreferences &&
      resourcePreferences?.CanUpdateAreaOfBusinessPreferences &&
      resourcePreferences?.AreaOfBusinessPreferences.length > 0
    ) {
      localPreferenceLevel = 2;
      setlocalBusinessArea(resourcePreferences?.AreaOfBusinessPreferences);
    }

    if (
      resourcePreferences?.ContractGroupPreferences &&
      resourcePreferences?.CanUpdateContractGroupPreferences &&
      resourcePreferences?.ContractGroupPreferences.length > 0
    ) {
      localPreferenceLevel = 3;
      setlocalContractGroup(resourcePreferences?.ContractGroupPreferences);
    }
    setprefernceLevel(localPreferenceLevel);
  }, [resourcePreferences]);

  useEffect(() => {
    if (!resourcePreferences.IsSuccess) {
      onGetPreferences();
    }
  }, []);
  useEffect(() => {
    if (isUpdateFulfilled) {
      onUpdateComplete();
      onGetPreferences();
    }
  }, [isUpdateFulfilled]);

  const handleArticleSelectionChange = (article, isSub = false) => {
    if (
      !article.IsDesiredByResource &&
      !isSub &&
      localContractGroup.length > 0
    ) {
      setSelectedArticle({ article, showModal: true });
    }
    const newLocalArticle = localArticle.map(x =>
      x.ArticleIdentifier === article.ArticleIdentifier
        ? { ...x, IsDesiredByResource: !x.IsDesiredByResource }
        : x,
    );
    setlocalArticle(newLocalArticle);
  };

  const handleAreaOfBusinessSelectionChange = idenetifier => {
    const newLocalBusinessArea = localBusinessArea.map(x =>
      x.AreaOfBusinessIdentifier === idenetifier
        ? { ...x, IsDesiredByResource: !x.IsDesiredByResource }
        : x,
    );
    setlocalBusinessArea(newLocalBusinessArea);
  };

  const handleContractGroupChange = (
    changedContractGroupItem,
    updateGroup = true,
  ) => {
    const updatedList = localContractGroup.map(x => {
      if (
        x.ContractGroupIdentifier ===
        changedContractGroupItem.ContractGroupIdentifier
      ) {
        return {
          ...changedContractGroupItem,
          IsDesiredByResource: updateGroup
            ? !changedContractGroupItem.IsDesiredByResource
            : changedContractGroupItem.IsDesiredByResource,
          ContractGroupArticlePreferences:
            updateGroup &&
            !changedContractGroupItem.IsDesiredByResource === false
              ? changedContractGroupItem.ContractGroupArticlePreferences.map(
                  y => ({
                    ...y,
                    IsDesiredByResource: false,
                  }),
                )
              : changedContractGroupItem.ContractGroupArticlePreferences,
          isUpdated: true,
        };
      }
      return x;
    });
    setlocalContractGroup(updatedList);
  };

  const handleContractGroupArticleChange = (
    UpdatedContractGroupPreferenceItem,
    changedArticlleIdentifier,
    updateGroup = false,
  ) => {
    const updatedList = UpdatedContractGroupPreferenceItem.ContractGroupArticlePreferences.map(
      x => {
        if (x.ArticleIdentifier === changedArticlleIdentifier) {
          return {
            ...x,
            IsDesiredByResource: !x.IsDesiredByResource,
            isUpdated: true,
          };
        }
        return x;
      },
    );
    handleContractGroupChange(
      {
        ...UpdatedContractGroupPreferenceItem,
        ContractGroupArticlePreferences: updatedList,
      },
      updateGroup,
    );
  };

  const checkArticleOfTypeDisable = contratcArticles => {
    const filteredItem = localArticle.filter(
      x => x.ArticleIdentifier === contratcArticles.ArticleIdentifier,
    );
    if (filteredItem.length > 0) {
      return filteredItem[0].IsDesiredByResource === false;
    }
    return false;
  };

  const handleSubmit = () => {
    onUpdatePreferences({
      AreaOfBusinessPreferencesUpdates: resourcePreferences?.CanUpdateAreaOfBusinessPreferences
        ? localBusinessArea.map(x => ({
            AreaOfBusinessIdentifier: x.AreaOfBusinessIdentifier,
            IsDesiredByResource: x.IsDesiredByResource,
          }))
        : [],
      ArticlePreferencesUpdates: resourcePreferences.CanUpdateArticlePreferences
        ? localArticle.map(x => ({
            ArticleIdentifier: x.ArticleIdentifier,
            IsDesiredByResource: x.IsDesiredByResource,
          }))
        : [],
      ContractGroupPreferencesUpdates: resourcePreferences.CanUpdateContractGroupPreferences
        ? localContractGroup.map(contractGroupItem => ({
            ContractGroupIdentifier: contractGroupItem.ContractGroupIdentifier,
            IsDesiredByResource: contractGroupItem.IsDesiredByResource,
            ContractGroupArticlePreferencesUpdates: contractGroupItem.ContractGroupArticlePreferences.map(
              articleItem => ({
                ArticleIdentifier: articleItem.ArticleIdentifier,
                IsDesiredByResource: articleItem.IsDesiredByResource,
              }),
            ),
          }))
        : [],
    });
  };

  const handleAllContractGroupArticleChanges = article => {
    const updateList = localContractGroup.map(contractGroup => {
      const articleList = contractGroup.ContractGroupArticlePreferences.map(
        x => ({
          ...x,
          IsDesiredByResource:
            article.ArticleIdentifier === x.ArticleIdentifier &&
            contractGroup.IsDesiredByResource === true
              ? !selectedArticle.IsDesiredByResource
              : x.IsDesiredByResource,
        }),
      );
      return {
        ...contractGroup,
        ContractGroupArticlePreferences: articleList,
      };
    });
    setlocalContractGroup(updateList);
    setSelectedArticle({
      showModal: false,
      article: null,
    });
  };

  return (
    <>
      <PreferenceSettings
        showContent={resourcePreferences}
        areaOfBusinessPreferences={localBusinessArea}
        articlePreferences={localArticle}
        contractGroupPreferences={localContractGroup}
        preferenceLevel={prefernceLevel}
        onArticleChange={handleArticleSelectionChange}
        onAreaOfBusinessChange={handleAreaOfBusinessSelectionChange}
        onContractGroupChange={handleContractGroupChange}
        onContractGroupArticleChange={handleContractGroupArticleChange}
        checkArticleOfTypeDisable={checkArticleOfTypeDisable}
        onSubmit={handleSubmit}
      />
      {(isReadPending || isUpdatePending) && <Spinner />}
      {selectedArticle.showModal && (
        <Modal
          isVisible={selectedArticle.showModal}
          className="tv-preference-setting__modal-container"
          onClose={() => {
            setSelectedArticle({
              showModal: false,
              article: null,
            });
          }}
        >
          Gäller alla avtal
          <div className="tv-exception-avilability__actions">
            <Button
              label="Avbryt"
              className="tv-exception-avilability__actions-button"
              palette="outline"
              onClick={() => {
                setSelectedArticle({
                  showModal: false,
                  article: null,
                });
              }}
            />
            <Button
              label="Bekräfta"
              className="tv-exception-avilability__actions-button"
              onClick={() =>
                handleAllContractGroupArticleChanges(selectedArticle.article)
              }
            />
          </div>
        </Modal>
      )}
    </>
  );
};

PreferenceSettingContainer.propTypes = {
  resourcePreferences: PropTypes.shape({}),
  isReadPending: PropTypes.bool,
  isUpdateFulfilled: PropTypes.bool,
  isUpdatePending: PropTypes.bool,
  onGetPreferences: PropTypes.func,
  onUpdatePreferences: PropTypes.func,
};

PreferenceSettingContainer.defaultProps = {
  resourcePreferences: null,
  isReadPending: false,
  isUpdateFulfilled: false,
  isUpdatePending: false,
  onGetPreferences: () => {},
  onUpdatePreferences: () => {},
};

const mapDispatchToProps = dispatch => ({
  onGetPreferences: params => {
    dispatch(
      resourceDetailReadRequest('getPreferencesSetting', null, params, true),
    );
  },
  onUpdatePreferences: params => {
    dispatch(resourceCreateRequest('updatePreferencesSetting', params));
  },
  onUpdateComplete: () => {
    dispatch(clean('updatePreferencesSetting'));
  },
});

const mapStateToProps = state => {
  const resourcePreferences =
    fromResource.getDetail(state, 'getPreferencesSetting') || {};

  return {
    isReadPending: pending(state, 'getPreferencesSettingDetailRead'),
    isUpdatePending: pending(state, 'updatePreferencesSettingCreate'),
    isUpdateFulfilled: fulfilled(state, 'updatePreferencesSettingCreate'),
    resourcePreferences,
    isUpdateFailed: rejected(state, 'updatePreferencesSettingCreate'),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreferenceSettingContainer);
