/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Translate, AssignmentItem } from 'components';

import './style.scss';

const AssignmentRequestList = ({
  list,
  screen,
  onClick,
  onAccept,
  onReject,
  selectedItem,
  sortDateTime,
  dateTimeSortedAssignmentRequests,
  assignmentRequestConsent,
}) => (
  <div className="tv-assignment-request-list__container">
    <div className="tv-assignment-request-list-title tv-mobile--hide">
      {Translate({ content: 'assignment.availableRequest' })}
    </div>
    <div className="tv-assignment-request-list__assignment-list">
      <div className="row tv-assignment-request-list-header">
        <div className="tv-assignment-request-list-header__item tv-assignment-request-list-header__item-type order-0">
          {Translate({ content: 'assignment.booking' })}
        </div>
        <div
          onClick={() =>
            sortDateTime(
              !dateTimeSortedAssignmentRequests,
              'sortRequestAssignments',
            )
          }
          className="tv-assignment-request-list-header__item order-1"
          role="button"
        >
          <div className="sort-column-request">
            {Translate({ content: 'assignment.dateAndTime' })}
            <div
              className={
                dateTimeSortedAssignmentRequests
                  ? 'sort-icon'
                  : 'sort-icon-transform'
              }
              tabIndex={0}
              role="button"
              aria-label="korta Förfrågningar efter datum och tid"
              style={{
                background: "url('/images/arrow-down.svg')",
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        </div>
        <div className="tv-assignment-request-list-header__item  order-2">
          {Translate({ content: 'assignment.assignmentNumber' })}
        </div>
        <div className="tv-assignment-request-list-header__item tv-assignment-request-list-header__item-pay  order-3">
          {Translate({ content: 'assignment.replacement' })}
        </div>

        <div className="tv-assignment-request-list-header__item  order-4">
          {Translate({ content: 'assignment.language' })}
        </div>
        {/* <div className="tv-assignment-request-list-header__item  order-5">
          {Translate({ content: 'assignment.alternativeLanguage' })}
        </div> */}

        <div className="tv-assignment-request-list-header__item order-6">
          {Translate({ content: 'assignment.responseTime' })}
        </div>

        <div className="tv-assignment-request-list-header__item tv-assignment-request-list-header__item-req-response order-7">
          {Translate({ content: 'assignment.response' })}
        </div>
      </div>
      <hr className="tv-mobile--hide" />
      {isEmpty(list) && !assignmentRequestConsent && (
        <p className="tv-assignment-request-list-empty-para d-flex justify-content-center mt-4">
          Du har inga aktiva förfrågningar. Säkerställ din tillgänglighet under
          <a
            href="settings#default-availability"
            className="link-tag mx-1"
            aria-label=" Du har inga aktiva förfrågningar. Säkerställ din tillgänglighet under  Schemalagd tillgänglighet för att få uppdrag."
          >
            Schemalagd tillgänglighet
          </a>{' '}
          för att få uppdrag.
        </p>
      )}
      {isEmpty(list) && assignmentRequestConsent && (
        <p className="tv-assignment-request-list-empty-para d-flex justify-content-center mt-4">
          Du har inga aktiva förfrågningar. Du kommer att bli meddelad när nya
          förfrågningar skickas till dig.
        </p>
      )}

      {list.map((item, index) => (
        <AssignmentItem
          key={`assignment-item-${item.WorkAssignmentIdentifier}`}
          onClick={onClick}
          screen={screen}
          item={item}
          isSelected={
            selectedItem?.WorkAssignmentIdentifier ===
            item.WorkAssignmentIdentifier
          }
          className={`${
            index % 2 === 1 && 'tv-assignment-request-list-bg-white'
          } tv-assignment-request-list__item`}
          onAccept={onAccept}
          onReject={onReject}
        />
      ))}
    </div>
  </div>
);

AssignmentRequestList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  screen: PropTypes.string,
  selectedItem: PropTypes.shape({ WorkAssignmentIdentifier: '' }),
  sortDateTime: PropTypes.func,
  assignmentRequestConsent: PropTypes.bool,
  dateTimeSortedAssignmentRequests: PropTypes.bool,
};
AssignmentRequestList.defaultProps = {
  list: [],
  onClick: () => {},
  onAccept: () => {},
  onReject: () => {},
  screen: 'availableRequest',
  sortDateTime: () => {},
  selectedItem: { WorkAssignmentIdentifier: '' },
  dateTimeSortedAssignmentRequests: true,
  assignmentRequestConsent: false,
};

export default AssignmentRequestList;
