import React from 'react';
import PropTypes from 'prop-types';
import { TableList, Translate } from 'components';
import { NavLink } from 'react-router-dom';

import('./style.scss');

const FeeList = ({ list, visibleDate, onDownloadClick }) => (
  <div className="tv-fee-list__container">
    <h2 className="tv-fee-list_title">
      {Translate({ content: 'settings.feeList' })}
    </h2>

    <div className="tv-fee-list_table">
      <TableList
        list={list}
        onDownloadClick={onDownloadClick}
        visibleDate={visibleDate}
      />
    </div>

    <p className="tv-fee-list_text">
      <NavLink to="/contact-and-faq">
        <Translate content="feeList.contactTFV" />{' '}
      </NavLink>
      <Translate content="feeList.feeListAddDescription" />
    </p>
  </div>
);

FeeList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onDownloadClick: PropTypes.func,
  visibleDate: PropTypes.bool,
};
FeeList.defaultProps = {
  list: [],
  onDownloadClick: () => {},
  visibleDate: true,
};

export default FeeList;
