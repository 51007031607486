import { tfvLog } from 'utils/miscUtil';
import { errorsReceive } from './actions';

const convertError = error => {
  const sendError = {
    ErrorCode: error.response.status,
    ErrorMessage: error.message,
  };
  return sendError;
};

const errorMiddleware = store => next => action => {
  const { payload, error } = action;
  if (error && payload) {
    console.log(error, payload, Object.prototype.toString.call(payload));
    if (
      Object.prototype.toString.call(payload) === '[object Error]' &&
      payload.response
    ) {
      tfvLog(error);
      store.dispatch(errorsReceive({ ...convertError(payload) }));
    } else if (Object.prototype.toString.call(payload) === '[object Error]') {
      console.log('Network Error');
    } else {
      store.dispatch(errorsReceive({ ...payload }));
    }
    return next(action);
  }
  return next(action);
};

export default errorMiddleware;
