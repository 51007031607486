/* eslint-disable no-use-before-define */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AttachedFile,
  Translate,
  Button,
  Field,
  DatePicker,
  TimePicker,
  StarRating,
  Checkbox,
  HoursMinutesInput,
} from 'components';
import {
  convertToDayJsObject,
  extendedDayJs,
  localDayjs,
} from 'utils/dateTimeUtils';
import { v4 as uuidv4 } from 'uuid';
import { timeSheetContentTypes } from 'constant';
import './style.scss';
import { isNumFieldValidate } from 'utils/miscUtil';

const initialAttachmentData = {
  id: uuidv4(),
  AmountWithVAT: 0,
  AmountWithoutVAT: 0,
  ExpenseType: 1,
  VATRate: 0,
  ReceiptAttachment: null,
};

const AttachFile = ({ text, onChange, name, onClick }) => (
  <div className="tv-attach-file__container">
    <input
      onChange={onChange}
      className="input"
      type="file"
      id={name}
      onClick={onClick}
    />
    <label className="attach-receipe" htmlFor={name} title={text}>
      {text}
    </label>
    <i htmlFor="files" className="material-icons">
      attach_file
    </i>
  </div>
);

const ContentRow = ({
  left,
  center,
  right,
  children,
  className,
  controlClass,
  controlClassLeft = '',
  controlClassRight = '',
  controlClassCenter = '',
  childClass,
}) => (
  <>
    <div className={`tv-assignment-time-report-edit__wrapper ${className}`}>
      {left && (
        <div
          className={`tv-assignment-time-report-edit__control ${controlClass} ${controlClassLeft}`}
        >
          {left}
        </div>
      )}
      {center && (
        <div
          className={`tv-assignment-time-report-edit__control ${controlClass} ${controlClassCenter}`}
        >
          {center}
        </div>
      )}
      {right && (
        <div
          className={`tv-assignment-time-report-edit__control  ${controlClass} ${controlClassRight}`}
        >
          {right}
        </div>
      )}
    </div>
    {children && <div className={childClass}>{children}</div>}
  </>
);

const FileUpload = ({ OnAddReceipt, onRemoveFile, type, Files = [] }) => {
  const [attachmentData, setAttachmentData] = useState(initialAttachmentData);
  const [amount, setAmount] = useState(undefined);
  const [validation, setValidation] = useState(null);

  const handleAttachments = event => {
    const {
      target: { files },
    } = event;

    const reader = new FileReader();
    const file = files[0];
    reader.onloadend = async () => {
      setAttachmentData({
        ReceiptAttachment: {
          ReceiptFilename: file.name,
          ReceiptContentType: file.type,
          Receipt: reader.result.replace(
            new RegExp('^data:.*;base64,', 'gm'),
            '',
          ),
        },
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setValidation(null);
  };

  const handleAddToList = () => {
    if (
      amount === 0 ||
      amount === undefined ||
      !attachmentData.ReceiptAttachment
    ) {
      setValidation({
        error: '* Ange belopp och bifoga kvitto',
      });
    } else {
      const recipt = {
        ...attachmentData,
        id: uuidv4(),
        AmountWithVAT: amount,
        AmountWithoutVAT: amount,
        ExpenseType: type === timeSheetContentTypes.PARKING_EXPENSES ? 1 : 2,
        VATRate: 0,
      };
      setAttachmentData({ id: uuidv4() });
      setAmount(undefined);
      OnAddReceipt(recipt);
    }
  };

  return (
    <>
      <ContentRow
        className="tv-assignment-time-report-edit__field-group"
        controlClass="tv-assignment-time-report-edit__field "
        left={
          <>
            <label htmlFor="distance">Belopp</label>
            <Field
              name={type}
              key={attachmentData.id}
              type="number"
              value={amount || undefined}
              placeholder="0 kr"
              autoComplete="off"
              className={{
                containerClass: 'distance-in-km',
              }}
              onChange={event => {
                setAmount(Math.abs(event.target.value));
              }}
              onBlur={() => {
                setValidation(null);
              }}
              onKeyPress={isNumFieldValidate}
            />
          </>
        }
        center={
          <>
            <label htmlFor="Start">Bifoga kvitto</label>
            <AttachFile
              text={
                attachmentData.ReceiptAttachment
                  ? attachmentData.ReceiptAttachment.ReceiptFilename
                  : 'Välj kvitto'
              }
              name={`${type}-files`}
              onChange={event => {
                handleAttachments(event, type);
              }}
              onClick={e => {
                e.target.value = null;
              }}
            />
            <div className="tv-assignment-time-report-edit__validation-message tv-mobile--show">
              {validation?.error}
            </div>
          </>
        }
        right={
          <>
            <Button
              onClick={handleAddToList}
              className="tv-assignment-time-report-edit__field-add-receipt"
              palette="outline"
              label="Lägg till kvitto"
            />
          </>
        }
      />
      <div className="tv-assignment-time-report-edit__validation-message tv-mobile--hide">
        {validation?.error}
      </div>
      {Files.map(fileItem => (
        <AttachedFile
          visibleRemoveButton
          onRemoveFile={() => {
            onRemoveFile(fileItem.id);
          }}
          displayText={
            fileItem?.ReceiptAttachment?.ReceiptFilename?.split('.')[0]
          }
          file={fileItem}
        />
      ))}
    </>
  );
};

const AssignmentTimeReportModal = ({
  onClose,
  onSubmit,
  item,
  handleChange,
  handleSectionSubmit,
  allExpenses,
  removeReceipt,
  onSwitchMode,
  timeSheetData,
  collapseSection,
  collapseSectionAction,
}) => {
  const {
    visibleEditTripInfo,
    visibleParkingTicket,
    visibleOtherExpenses,
  } = collapseSection;
  const From = convertToDayJsObject(
    item.TimeReport.DatetimeOriginalFrom || item.DatetimeOrderFrom,
  );

  const To = convertToDayJsObject(
    item.TimeReport.DatetimeOriginalTo || item.DatetimeOrderTo,
  );

  const submittedDate = extendedDayJs(item.DatetimeOrderTo).toDate();
  const totalDistance = Math.abs(
    timeSheetData.DistanceToAssignment + timeSheetData.DistanceFromAssignment,
  );
  useEffect(() => {
    const originalTitle = document.title;
    document.title = `Rapportera avvikande tid ${item.OrderNumber} - TFV Tolkportal`;
    return () => {
      document.title = originalTitle;
    };
  }, []);
  useEffect(() => {
    setTravelTimesTo(
      convertSecondsToHumanize(timeSheetData?.TraveltimeToAssignment),
    );
  }, [timeSheetData?.TraveltimeToAssignment]);
  useEffect(() => {
    setTravelTimesFrom(
      convertSecondsToHumanize(timeSheetData?.TraveltimeFromAssignment),
    );
  }, [timeSheetData?.TraveltimeFromAssignment]);

  const [travelTimesTo, setTravelTimesTo] = useState(
    convertSecondsToHumanize(0),
  );
  const [travelTimesFrom, setTravelTimesFrom] = useState(
    convertSecondsToHumanize(0),
  );
  const handleHoursMinutesInput = (timeDuration, inputName) => {
    const event = {
      target: {
        name: inputName,
        value: localDayjs.duration(timeDuration).asSeconds(),
      },
    };

    handleChange(event, timeSheetContentTypes.TIME_SHEET);
  };
  return (
    <div className="tv-assignment-time-report-edit__content">
      <div className="tv-assignment-time-report-edit__content-area">
        <Header item={item} onChange={handleChange} />

        <hr className="tv-assignment-time-report-edit__seperator tv-assignment-time-report-edit__seperator-top " />
        <>
          <label
            className="tv-assignment-time-report-edit__title"
            htmlFor="datenum"
          >
            {Translate({ content: 'timeReport.actualInterpretationTime' })}
          </label>
          <ContentRow
            className="tv-assignment-time-report-edit__field-group  tv-assignment-time-report-edit__field-group--order"
            controlClass="tv-assignment-time-report-edit__field "
            left={
              <>
                <label className="" htmlFor="datenum">
                  Datum
                </label>
                <DatePicker
                  selectedDate={submittedDate}
                  id="datenum"
                  minDate={new Date()}
                  disabled
                  label="Datum"
                  name="Datum"
                  placeholderText="Önskat datum"
                />
              </>
            }
            controlClassLeft="mobile-100w"
            center={
              <>
                <label htmlFor="Start">Start</label>
                <TimePicker
                  label="Start"
                  id="Start"
                  defaultValue={From}
                  inputClassName={
                    timeSheetData?.validate?.orderTime
                      ? 'tv-field__input--error'
                      : ''
                  }
                  startFromDayBegin
                  inputName="Start"
                  minuteStep={15}
                  onChange={date => {
                    handleChange(date, timeSheetContentTypes.TIME_PICKER_START);
                  }}
                />
              </>
            }
            controlClassCenter="mobile-50w"
            right={
              <>
                <label htmlFor="Slut">Slut</label>
                <TimePicker
                  label="Slut"
                  id="Slut"
                  inputClassName={
                    timeSheetData?.validate?.orderTime
                      ? 'tv-field__input--error'
                      : ''
                  }
                  defaultValue={To}
                  inputName="Stop"
                  minuteStep={15}
                  onChange={date => {
                    handleChange(date, timeSheetContentTypes.TIME_PICKER_END);
                  }}
                  startFromDayBegin
                />
              </>
            }
            controlClassRight="mobile-50w"
          />
          {/* {!item.IsPerformedRemotely && (
            <ContentRow
              className="tv-assignment-time-report-edit__field-group  tv-assignment-time-report-edit__field-group--order"
              controlClass="tv-assignment-time-report-edit__field "
              left={
                <>
                  <label className="" htmlFor="resttime">
                    Rast
                  </label>
                  <Field
                    label=""
                    type="number"
                    name="resttime"
                    autoComplete="off"
                    placeholder="0 min"
                    min="2"
                    max="2"
                    value={
                      (timeSheetData.DowntimeInSeconds || 0) / 60 || undefined
                    }
                    onChange={event =>
                      handleChange(event, timeSheetContentTypes.REST_TIME)
                    }
                    onKeyPress={isNumFieldValidate}
                    className={{
                      containerClass: 'distance-in-km',
                    }}
                  />
                </>
              }
              controlClassLeft="mobile-50w"
            />
          )} */}
          {timeSheetData?.validate?.orderTime && (
            <div className="tv-assignment-time-report-edit__validation-message mt-2">
              {timeSheetData.validate.orderTime}
            </div>
          )}
          {/* {!item.IsPerformedRemotely && (
            <>
              <hr className="tv-assignment-time-report-edit__seperator" />
              <div className="tv-assignment-time-report-edit__title-wrapper">
                <label
                  className="tv-assignment-time-report-edit__title"
                  htmlFor="datenum"
                >
                  Resa
                </label>
                {collapseSection.visibleEditTripInfo && (
                  <Button
                    onClick={() => {
                      collapseSectionAction.setVisibleEditTripInfo(false);
                      handleChange(null, timeSheetContentTypes.RESET_TRAVEL);
                    }}
                    palette="text"
                    label="Ta bort resa"
                    className="tv-assignment-time-report-edit__btn-text"
                  />
                )}
              </div>
              {!visibleEditTripInfo && (
                <Button
                  onClick={() =>
                    collapseSectionAction.setVisibleEditTripInfo(true)
                  }
                  palette="text"
                  label="+ Lägg till resa"
                  className="tv-assignment-time-report-edit__btn-text"
                />
              )}
              {visibleEditTripInfo && (
                <>
                  <ContentRow
                    className="tv-assignment-time-report-edit__field-group"
                    controlClass="tv-assignment-time-report-edit__field "
                    left={
                      <>
                        <label htmlFor="distance">Resa i km dit och hem</label>
                        <Field
                          label=""
                          type="number"
                          name="distance"
                          autoComplete="off"
                          placeholder="0 km"
                          value={totalDistance || undefined}
                          onChange={event =>
                            handleChange(
                              event,
                              timeSheetContentTypes.TOTAL_TRAVEL_DISTANCE,
                            )
                          }
                          onKeyPress={isNumFieldValidate}
                          className={{
                            inputClass: timeSheetData?.validate?.travel
                              ? 'tv-field__input--error'
                              : '',
                            containerClass: 'distance-in-km',
                          }}
                        />
                      </>
                    }
                    center={
                      <>
                        <label htmlFor="TraveltimeToAssignment">
                          Restid dit{' '}
                        </label>
                        <HoursMinutesInput
                          key="TraveltimeToAssignment"
                          name="TraveltimeToAssignment"
                          onBlur={timeDuration =>
                            handleHoursMinutesInput(
                              timeDuration,
                              'TraveltimeToAssignment',
                            )
                          }
                          value={travelTimesTo}
                          className={
                            timeSheetData?.validate?.travel
                              ? 'tv-field__input--error distance-in-km'
                              : 'distance-in-km'
                          }
                        />
                      </>
                    }
                    right={
                      <>
                        <label htmlFor="TraveltimeFromAssignment">
                          Restid hem{' '}
                        </label>
                        <HoursMinutesInput
                          key="TraveltimeFromAssignment"
                          name="TraveltimeFromAssignment"
                          onBlur={timeDuration =>
                            handleHoursMinutesInput(
                              timeDuration,
                              'TraveltimeFromAssignment',
                            )
                          }
                          value={travelTimesFrom}
                          className={
                            timeSheetData?.validate?.travel
                              ? 'tv-field__input--error distance-in-km'
                              : 'distance-in-km'
                          }
                        />
                      </>
                    }
                    controlClassLeft="mobile-100w"
                    controlClassCenter="mobile-50w"
                    controlClassRight="mobile-50w"
                  />

                  <Checkbox
                    className="tv-assignment-time-report-edit__checkbox"
                    text="Egen bil"
                    onClick={() =>
                      handleChange(null, timeSheetContentTypes.USE_OWN_VEHICLE)
                    }
                    checked={
                      item.TimeReport.IsResourceRequestingKilometerCompensation
                    }
                  />
                  {timeSheetData?.validate?.travel && (
                    <div className="tv-assignment-time-report-edit__validation-message mt-2">
                      {timeSheetData.validate.travel}
                    </div>
                  )}
                </>
              )}
              <hr className="tv-assignment-time-report-edit__seperator" />
              <div className="tv-assignment-time-report-edit__title-wrapper">
                <label
                  className="tv-assignment-time-report-edit__title"
                  htmlFor="datenum"
                >
                  Parkeringsbiljett
                </label>
                {visibleParkingTicket && (
                  <Button
                    onClick={() => {
                      collapseSectionAction.setVisibleParkingTicket(false);
                      handleChange(null, timeSheetContentTypes.RESET_PARKING);
                    }}
                    palette="text"
                    label="Ta bort parkeringsbiljett"
                    className="tv-assignment-time-report-edit__btn-text"
                    htmlFor="datenum"
                  />
                )}
              </div>
              {!visibleParkingTicket && (
                <Button
                  onClick={() => {
                    collapseSectionAction.setVisibleParkingTicket(true);
                  }}
                  palette="text"
                  label="+ Lägg parkeringsbiljett"
                  className="tv-assignment-time-report-edit__btn-text"
                  htmlFor="datenum"
                />
              )}
              {visibleParkingTicket && (
                <FileUpload
                  Files={allExpenses.filter(
                    attachment => attachment.ExpenseType === 1,
                  )}
                  onRemoveFile={removeReceipt}
                  OnAddReceipt={handleSectionSubmit}
                  name="parkingValue"
                  type={timeSheetContentTypes.PARKING_EXPENSES}
                />
              )}
              <hr className="tv-assignment-time-report-edit__seperator" />
              <div className="tv-assignment-time-report-edit__title-wrapper">
                <label
                  className="tv-assignment-time-report-edit__title"
                  htmlFor="datenum"
                >
                  Övriga utgifter
                </label>
                {visibleOtherExpenses && (
                  <Button
                    onClick={() => {
                      collapseSectionAction.setVisibleOtherExpenses(false);
                      handleChange(
                        null,
                        timeSheetContentTypes.RESET_OTHER_EXPENSE,
                      );
                    }}
                    palette="text"
                    label="Ta bort övriga utgifter"
                    className="tv-assignment-time-report-edit__btn-text"
                    htmlFor="datenum"
                  />
                )}
              </div>
              {!visibleOtherExpenses && (
                <Button
                  onClick={() => {
                    collapseSectionAction.setVisibleOtherExpenses(true);
                  }}
                  palette="text"
                  label="+ Lägg till övriga utgifter"
                  className="tv-assignment-time-report-edit__btn-text"
                  htmlFor="datenum"
                />
              )}
              {visibleOtherExpenses && (
                <>
                  <FileUpload
                    Files={allExpenses.filter(
                      attachment => attachment.ExpenseType === 2,
                    )}
                    onRemoveFile={removeReceipt}
                    OnAddReceipt={handleSectionSubmit}
                    type={timeSheetContentTypes.OTHER_EXPENSES}
                  />
                </>
              )}
            </>
          )} */}

          {/* <hr className="tv-assignment-time-report-edit__seperator" />
          <label
            className="tv-assignment-time-report-edit__title"
            htmlFor="Comment"
          >
            Övriga kommentarer
          </label>

          <div>
            <textarea
              placeholder="Valfritt att lämna en kommentar"
              name="Comment"
              maxLength="900"
              value={timeSheetData.Comment}
              onChange={event =>
                handleChange(event, timeSheetContentTypes.TIME_SHEET)
              }
              className="tv-assignment-time-report-edit__field-comment"
            />
          </div> */}
        </>
      </div>
      <div className="tv-assignment-time-report-edit__actions mt-4">
        <Button
          label={Translate({ content: 'timeReport.directSubmit' })}
          className="tv-assignment-time-report-edit__actions-button"
          palette="outline"
          onClick={onSubmit}
        />
        <Button
          label={Translate({ content: 'timeReport.switchToPreview' })}
          className="tv-assignment-time-report-edit__actions-button"
          onClick={() => onSwitchMode('preview')}
        />
      </div>
    </div>
  );
};

AssignmentTimeReportModal.propTypes = {
  onClose: PropTypes.func,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  validationError: PropTypes.shape({
    isValid: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),
  item: PropTypes.shape({}),
  onSwitchMode: PropTypes.func,
  handleSectionSubmit: PropTypes.func,
};

AssignmentTimeReportModal.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  handleSectionSubmit: () => {},
  handleChange: () => {},
  validationError: {
    isValid: true,
    errorMessage: '',
  },
  item: [],
  onSwitchMode: () => {},
};

export default AssignmentTimeReportModal;

const Header = ({ item, onChange }) => {
  const From = convertToDayJsObject(item.DatetimeOrderFrom);

  const To = convertToDayJsObject(item.DatetimeOrderTo);
  const dateTimeString = `${From.format('DD MMMM YYYY')}, klockan ${From.format(
    'HH:mm',
  )}-${To.format('HH:mm')}`;
  return (
    <div className="tv-assignment-time-report-edit__header-container">
      <div className="tv-assignment-time-report-edit__header-area">
        <label className="tv-assignment-time-report-edit__title">
          {item.OrderNumber}, {item.Customer?.CustomerName}
        </label>
        <label className="tv-mobile--hide">{dateTimeString}</label>
      </div>
      {/* <div className="tv-assignment-time-report-edit__header-area">
        <label className="tv-mobile--hide">Betygsätt uppdraget</label>
        <StarRating
          defaultValue={item.RatingResource || null}
          viewMode={false}
          onChange={value => onChange(value, timeSheetContentTypes.RATING)}
        />
        <label className="tv-mobile--show">{dateTimeString}</label>
      </div> */}
    </div>
  );
};

const convertSecondsToHumanize = timeInSeconds =>
  timeInSeconds
    ? localDayjs
        .duration(timeInSeconds, 'seconds')
        .format(timeInSeconds >= 86400 ? 'DD:HH:mm' : 'HH:mm')
    : undefined;

const convertSecondsToHourHumaniz = timeInSeconds =>
  timeInSeconds
    ? localDayjs
        .duration(timeInSeconds, 'seconds')
        .format('(HH [tim] mm [min])')
    : undefined;
