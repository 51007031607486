import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'components';

const FilterItem = ({
  text,
  onClick,
  className,
  labelClassName,
  controlClassName,
  value,
  labelColor,
  textColor,
  checked,
}) => (
  <div className={className}>
    {/* <button className="tv-buttons__type" value={value} onClick={() => onClick(value)}>{text}</button> */}
    <Checkbox
      onClick={event => onClick(event, value)}
      className={labelClassName}
      controlClassName={controlClassName}
      text={text}
      value={value}
      labelColor={labelColor}
      textColor={textColor}
      checked={checked}
      ariaLabel={text}
    />
  </div>
);

FilterItem.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  controlClassName: PropTypes.string,
  value: PropTypes.string,
  labelColor: PropTypes.string,
  textColor: PropTypes.string,
  checked: PropTypes.bool,
};
FilterItem.defaultProps = {
  text: null,
  value: '',
  onClick: () => {},
  className: 'tv-status-filter-list',
  controlClassName: '',
  labelClassName: '',
  labelColor: 'gray',
  textColor: 'black',
  checked: false,
};

export default FilterItem;
