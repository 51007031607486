/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Translate, TranslationItem } from 'components';

import './style.scss';

const AssignmentList = ({
  list,
  screen,
  onClick,
  isFiltering,
  dateTimeSorted,
  sortDateTime,
  selectedItem,
  visibleWorkAssignment,
}) => (
  <div className="tv-assignment-list__container">
    <div className="tv-assignment-list-title tv-mobile--hide">
      {Translate({ content: 'emailTranslation.title' })}
    </div>
    <div className="tv-assignment-list__assignment-list">
      <div className="row tv-assignment-list-header">
        <div className="tv-assignment-list-header__item  order-2">
          {Translate({ content: 'singleEmailTranslation.orderNumber' })}
        </div>
        <div className="tv-assignment-list-header__item  order-3">
          {Translate({ content: 'singleEmailTranslation.modeOfDelivery' })}
        </div>
        <div className="tv-assignment-list-header__item  order-4">
          {Translate({ content: 'singleEmailTranslation.deliveryDate' })}
        </div>
        <div className="tv-assignment-list-header__item  order-5">
          {Translate({ content: 'singleEmailTranslation.sourceLanguage' })}
        </div>
        <div className="tv-assignment-list-header__item  order-6">
          {Translate({ content: 'singleEmailTranslation.targetLanguage' })}
        </div>
        {/* <div className="tv-assignment-list-header__item  order-7">
          {Translate({
            content: 'singleEmailTranslation.contactPersonOrderer',
          })}
        </div> */}
        <div className="tv-assignment-list-header__item  order-7 text-md-right pr-5 mr-md-4">
          {Translate({ content: 'singleEmailTranslation.status' })}
        </div>
      </div>
      <hr className="tv-mobile--hide" />
      {visibleWorkAssignment &&
        visibleWorkAssignment.map((item, index) => (
          <TranslationItem
            isSelected={selectedItem?.OrderNumber === item.OrderNumber}
            key={item.OrderNumber}
            onClick={onClick}
            screen={screen}
            item={item}
            className={index % 2 === 1 ? 'tv-assignment-list-bg-white' : ''}
          />
        ))}
    </div>
  </div>
);

AssignmentList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
  screen: PropTypes.string,
  isFiltering: PropTypes.bool,
  sortDateTime: PropTypes.func,
  dateTimeSorted: PropTypes.bool,
  selectedItem: PropTypes.shape({ OrderNumber: '' }),
  visibleWorkAssignment: PropTypes.arrayOf(PropTypes.shape({})),
};
AssignmentList.defaultProps = {
  list: [],
  onClick: () => {},
  screen: 'assignment',
  isFiltering: false,
  sortDateTime: () => {},
  dateTimeSorted: true,
  selectedItem: { OrderNumber: '' },
  visibleWorkAssignment: [],
};

export default AssignmentList;
