import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate, Avatar } from 'components';
import './style.scss';
import { LOCAL_STORE_INTERPRETER_NUMBER } from 'constant';
import { multiSoftICalcUrl } from 'config';

const UserProfile = ({
  profile: {
    FirstName,
    LastName,
    MobilePhoneNumber,
    WorkPhoneNumber,
    PostalAddress,
    EmailAddress,
    ExternalIdentifier,
  },
}) => {
  const interpreterNumber = localStorage.getItem(
    LOCAL_STORE_INTERPRETER_NUMBER,
  );
  useEffect(() => {
    document.title = `Kontoinställningar - TFV Tolkportal`;
  }, []);
  return (
    <div className="tv-myprofile tv-myprofile__wrapper">
      <div className="tv-myprofile__header-wrapper">
        <div className="tv-myprofile__avatar">
          <Avatar className="tv-myprofile__avatar-img" />
        </div>
        <div className="tv-myprofile__header-text">
          <div className="tv-myprofile__name">{`${FirstName} ${LastName}`}</div>
          <div className="tv-myprofile__client-number">
            <Translate content="yourProfile.clientNumber" />:{' '}
            {interpreterNumber || '-'}
          </div>
        </div>
      </div>
      <div className="tv-myprofile__content-wrapper">
        <div className="tv-myprofile__field-row">
          <div className="tv-myprofile__field">
            <Translate content="yourProfile.address" />
            <div className="tv-myprofile_value">
              <div className="profile_values_element">
                {PostalAddress.StreetAddress}
              </div>
              <div className="profile_values_element">
                {PostalAddress.PostalCode}
              </div>
              <div className="profile_values_element">{PostalAddress.City}</div>
            </div>
          </div>
          <div className="tv-myprofile__field">
            <div>
              <div className="tv-myprofile__field">
                <Translate content="yourProfile.phone" />
                <div className="tv-myprofile_value ls-2">
                  <div className="profile_values_element">
                    {MobilePhoneNumber || '-'}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="mt-3">
              <div className="tv-myprofile__field">
                <Translate content="yourProfile.workPhoneNumber" />
                <div className="tv-myprofile_value ls-2">
                  <div className="profile_values_element">
                    {WorkPhoneNumber || '-'}
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="tv-myprofile__field tv-mobile--hide">
            <Translate content="yourProfile.email" />
            <div className="tv-myprofile_value">
              <div className="profile_values_element">{EmailAddress}</div>
            </div>
          </div>
        </div>
        <a
          style={{ fontSize: '18px', fontWeight: 'bold' }}
          rel="noreferrer"
          target="_blank"
          href={`${multiSoftICalcUrl}?id=${ExternalIdentifier}`}
        >
          Ladda ner iCalendar
        </a>
        <hr className="tv-myprofile_hr" />
      </div>
    </div>
  );
};

UserProfile.propTypes = {
  profile: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    PostalAddress: PropTypes.shape({
      StreetAddress: PropTypes.string,
      PostalCode: PropTypes.number,
      City: PropTypes.string,
    }),
    WorkPhoneNumber: PropTypes.string,
    MobilePhoneNumber: PropTypes.string,
    customerNumber: PropTypes.string,
    EmailAddress: PropTypes.string,
    ExternalIdentifier: PropTypes.string,
  }),
};

UserProfile.defaultProps = {
  profile: {
    FirstName: '',
    LastName: '',
    PostalAddress: {
      StreetAddress: '',
      PostalCode: 0,
      City: '',
    },
    WorkPhoneNumber: '-',
    MobilePhoneNumber: '-',
    customerNumber: null,
    EmailAddress: '-',
    ExternalIdentifier: '',
  },
};

export default UserProfile;
