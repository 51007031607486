import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Logo } from 'components';
import './style.scss';
import showChat from 'utils/chatUtils';

export const LoginLayout = ({ children }) => {
  useEffect(() => {
    showChat();
  }, []);
  return (
    <>
      <div className="tv-login-layout__container">
        <Logo />
        <div className="tv-login-layout__form-container">{children} </div>
      </div>
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};
LoginLayout.defaultProps = {
  children: null,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoginLayout);
