import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import {
  NotificationIcon,
  GeneralNotification,
  NotificationRequest,
  NotificationMenuDropdown,
  Translate,
  Spinner,
} from 'components';
import useWindowSize from 'hooks/useWindowSize';
import { localDayjs as dayJS } from 'utils/dateTimeUtils';
import { NavLink } from 'react-router-dom';

const Notification = ({
  className,
  generalNotifications,
  generalNotificationsDropDown,
  requests,
  isPage,
  onAccept,
  onReject,
  handleCardClick,
  notificationCount,
  isLoading,
  visibleNotificationDropdown,
  setVisibleNotificationDropdown,
  redirectToNotificationPage,
  onShowInCalendar,
}) => {
  const { isMobileView } = useWindowSize();

  return (
    <div className={` ${isPage ? 'w-100 d-flex' : ''} ${className.iconClass}`}>
      {/* For the Top-Bar Notification menu */}
      {!isPage && (
        <NotificationMenuDropdown
          visible={visibleNotificationDropdown}
          setVisible={setVisibleNotificationDropdown}
          button={<NotificationIcon count={notificationCount} />}
        >
          <div className="tv-notification__container">
            <div className="tv-notification__list">
              {requests.map(item => (
                <NotificationRequest
                  key={`notification-request-${item.NotificationIdentifier}`}
                  onAccept={onAccept}
                  onReject={onReject}
                  item={item}
                  handleCardClick={handleCardClick}
                  onShowInCalendar={onShowInCalendar}
                />
              ))}
              {notificationCount === 0 && (
                <div className="tv-notification__no-item">
                  {Translate({ content: 'notification.noNotification' })}
                </div>
              )}
              {generalNotificationsDropDown.map(
                item =>
                  item.Headline !== null && (
                    <GeneralNotification
                      key={`notification-${item.NotificationIdentifier}`}
                      title={item.Headline}
                      message={item.Message}
                      date={item.DatetimeSent}
                      isNew={!item.IsRead}
                      item={item}
                      handleCardClick={handleCardClick}
                      isPage={isMobileView}
                    />
                  ),
              )}
            </div>
            <button
              onClick={() => redirectToNotificationPage()}
              type="button"
              className="tv-notification__button"
            >
              {Translate({ content: 'notification.goToNotificationPage' })}
            </button>
          </div>
        </NotificationMenuDropdown>
      )}

      {/* For the Notification Page */}
      {isPage && (
        <div className={`tv-notification__page ${className.pageClass}`}>
          <h2 className="tv-notification__page__title">
            {Translate({ content: 'notification.title' })}
          </h2>
          {isLoading && <Spinner />}
          <div className="tv-notification__container-fluid  ">
            <div className="">
              {requests.map(item => (
                <NotificationRequest
                  key={`notification-request-${item.NotificationIdentifier}`}
                  onAccept={onAccept}
                  onReject={onReject}
                  item={item}
                  handleCardClick={handleCardClick}
                  onShowInCalendar={onShowInCalendar}
                />
              ))}
              {!generalNotifications.length && (
                <div className="tv-notification__no-item">
                  {Translate({ content: 'notification.noNotification' })}
                </div>
              )}
              {generalNotifications
                .sort((a, b) => dayJS(b.DatetimeSent).diff(a.DatetimeSent))
                .map(
                  item =>
                    item.Headline !== null && (
                      <GeneralNotification
                        key={`notification-${item.NotificationIdentifier}`}
                        title={item.Headline}
                        message={item.Message}
                        date={item.DatetimeSent}
                        isNew={!item.IsRead}
                        isPage={isPage}
                        item={item}
                        handleCardClick={handleCardClick}
                      />
                    ),
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Notification.propTypes = {
  className: PropTypes.shape({
    iconClass: PropTypes.string,
    pageClass: PropTypes.string,
  }),
  isPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  generalNotifications: PropTypes.arrayOf(PropTypes.shape({})),
  requests: PropTypes.arrayOf(PropTypes.shape({})),
  generalNotificationsDropDown: PropTypes.arrayOf(PropTypes.shape({})),
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  handleCardClick: PropTypes.func,
  notificationCount: PropTypes.number,
  visibleNotificationDropdown: PropTypes.bool,
  setVisibleNotificationDropdown: PropTypes.func,
  redirectToNotificationPage: PropTypes.func,
  onShowInCalendar: PropTypes.func,
};

Notification.defaultProps = {
  className: {
    iconClass: '',
    pageClass: '',
  },
  isPage: false,
  isLoading: false,
  generalNotifications: [],
  notificationCount: 0,
  requests: [],
  generalNotificationsDropDown: [],
  onAccept: () => {},
  onReject: () => {},
  handleCardClick: () => {},
  visibleNotificationDropdown: false,
  setVisibleNotificationDropdown: () => {},
  redirectToNotificationPage: () => {},
  onShowInCalendar: () => {},
};

export default Notification;
