export const TFV_LOGIN = 'TFV_LOGIN';
export const AUTHENTICATE_RESOURCE_SUCCESS = 'AUTHENTICATE_RESOURCE_SUCCESS';
export const AUTHENTICATE_RESOURCE = 'AUTHENTICATE_RESOURCE';
export const AUTHENTICATE_RESOURCE_USING_ID = 'AUTHENTICATE_RESOURCE_USING_ID';
export const AUTHENTICATE_RESOURCE__USING_ID_SUCCESS =
  'AUTHENTICATE_RESOURCE__USING_ID_SUCCESS';

export const TFV_LOGIN_REQUEST = 'TFV_LOGIN_REQUEST';
export const TFV_LOGIN_SUCCESS = 'TFV_LOGIN_SUCCESS';
export const TFV_LOGIN_FAILURE = 'TFV_LOGIN_FAILURE';
export const TFV_LOGOUT = 'TFV_LOGOUT';

export const tfvResourceAuthenticate = ({
  email,
  passwordHash,
  isRefresh,
}) => ({
  type: AUTHENTICATE_RESOURCE,
  payload: {
    email,
    passwordHash,
    isRefresh: isRefresh === null ? false : isRefresh,
  },
  meta: {
    thunk: 'resourceUserAuthenticate',
  },
});

export const tfvResourceAuthenticateSuccess = (
  data,
  request,
  thunk,
) => ({
  type: AUTHENTICATE_RESOURCE_SUCCESS,
  payload: { ...data, ...request },
  meta: {
    request,
    thunk,
  },
});

export const tfvResourceAuthenticateUsingId = ({
  email,
  interpreterId,
}) => ({
  type: AUTHENTICATE_RESOURCE_USING_ID,
  payload: {
    email,
    interpreterId,
  },
  meta: {
    thunk: 'resourceUserAuthenticateUsingId',
  },
});

export const tfvLoginRequest = (
  interpreterId,
  resourceIdentifier,
  timeStamp,
) => ({
  type: TFV_LOGIN_REQUEST,
  payload: {
    interpreterId,
    resourceIdentifier,
    timeStamp,
  },
  meta: {
    thunk: 'loginRequest',
  },
});

export const tfvLoginSuccess = (data, request, thunk) => ({
  type: TFV_LOGIN_SUCCESS,
  payload: data,
  meta: {
    thunk,
    request,
  },
});

export const tfvLoginFailure = (error, request, thunk) => ({
  type: TFV_LOGIN_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
  },
});

export const tfvLogout = () => ({ type: TFV_LOGOUT });
