/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  convertToDayJsObject,
  currentDate,
  localDayjs,
} from 'utils/dateTimeUtils';
import {
  Button,
  StarRating,
  AssignmentCancelModal,
  AssignmentContactModal,
  AssignmentType,
  AssignmentStatus,
  AssignmentCustomerName,
  AssignmentNavigator,
} from 'components';
import { AssignmentTimeReportEditContainer } from 'containers';
import './style.scss';
import axios from 'axios';
import { checkOnGoingAssignment, tfvLog } from 'utils/miscUtil';
import { useSelector } from 'react-redux';
import FeedbackContainer from 'containers/FeedbackContainer';
import AssignmentRequestModal from 'components/organisms/AssignmentRequestModal';
import { apiUrl } from 'config';
import FocusTrap from 'focus-trap-react';
import Attachment from '../Attachment';

function formatAddress(addressText, isDeviating) {
  return isDeviating ? `OBS! ${addressText}` : addressText;
}

function AssignmentOverviewContainer({
  assignmentId,
  children,
  onClickBack,
  onClickClose,
  NavigationClickHandler,
  onAssignmentEarlyCompleted,
  showTabIndex,
}) {
  const overViewRef = useRef(null);
  useEffect(() => {
    const originalTitle = document.title;
    document.title = `${assignmentId}- TFV Tolkportal`;
    return () => {
      document.title = originalTitle;
    };
  }, [assignmentId]);
  return (
    <FocusTrap
      focusTrapOptions={{
        escapeDeactivates: false,
        allowOutsideClick: true,
        preventScroll: true,
      }}
    >
      <div
        className="tv-assignment-overview"
        ref={overViewRef}
        aria-live="polite"
        tabIndex={showTabIndex}
      >
        <AssignmentNavigator
          onClickPrevious={() => {
            NavigationClickHandler('previous');
          }}
          onClickNext={() => {
            NavigationClickHandler('next');
          }}
          className="tv-mobile--hide"
        />
        <div className="tv-assignment-overview__outer-container">
          <div className="tv-assignment-overview__outer-container__header">
            <Button
              onClick={onClickBack}
              className="back-icon"
              palette="text"
              label={<i className="fa fa-chevron-left" />}
              aria-label="tillbakaknapp"
              tabIndex="-1"
              aria-hidden="true"
            />
            <div className="assignment-number">{assignmentId}</div>
            <Button
              onClick={onClickClose}
              className="close-icon"
              palette="text"
              label={<i className="fa fa-times" />}
              aria-label="stäng knapp"
            />
          </div>
          <hr />
          <div className="tv-assignment-overview__outer-container__wrapper">
            {children}
          </div>
          <AssignmentNavigator
            onClickPrevious={() => {
              NavigationClickHandler('previous');
            }}
            onClickNext={() => {
              NavigationClickHandler('next');
            }}
            className="tv-assignment-overview__mobile-navigator"
          />
        </div>
      </div>
    </FocusTrap>
  );
}

function AssignmentOverview({
  item,
  onClickClose,
  onHideAssignment,
  onAccept,
  onReject,
  NavigationClickHandler,
  onAssignmentEarlyCompleted,
  cancelAcceptedWorkAssignment,
  searchSingleWorkAssignment,
  cancelAcceptedAssignment,
  cleanCancelAcceptedWorkAssignment,
  setDefaultAcceptedWorkAssignment,
}) {
  const [statusColor, setStatusColor] = useState(item.OrderStatus);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [
    showAcceptedOrderCancelModal,
    setShowAcceptedOrderCancelModal,
  ] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showTimeSheetModal, setShowTimeSheetModal] = useState(false);
  const [showTabIndex, setShowTabIndex] = useState(0);
  const [disableFinishedEarly, setDisableFinishedEarly] = useState(true);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [onGoingOrder, setOnGoingOrder] = useState(false);

  const { token } = useSelector(state => state.authenticate);

  const modifiedStartTime = convertToDayJsObject(item.DatetimeOrderFrom).add(
    7,
    'minute',
  );

  let countdown = null;

  const checkCurrentTimePassedStartTime = () => {
    const currentTime = localDayjs();
    if (currentTime.isAfter(modifiedStartTime)) {
      setDisableFinishedEarly(false);
      clearInterval(countdown);
    }
  };
  useEffect(() => {
    if (item.OrderStatus === 'accept') {
      countdown = setInterval(checkCurrentTimePassedStartTime, 10000);
    }
    return countdown;
  }, []);

  let timer;
  useEffect(() => {
    const updateStatusColor = () => {
      const isOnGoingStatus = checkOnGoingAssignment(item);
      if (isOnGoingStatus) {
        if (statusColor !== 'onGoing') {
          setStatusColor('onGoing');
          setOnGoingOrder(true);
        }
      } else {
        setStatusColor(item.OrderStatus);
        setOnGoingOrder(false);
      }
      timer = setTimeout(updateStatusColor, 5000);
    };
    updateStatusColor();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [item]);

  useEffect(() => {
    if (cancelAcceptedAssignment) {
      setShowAcceptedOrderCancelModal(false);
      searchSingleWorkAssignment(item.WorkAssignmentIdentifier);
      onClickClose();
      setDefaultAcceptedWorkAssignment();
      cleanCancelAcceptedWorkAssignment();
    }
  }, [cancelAcceptedAssignment]);

  const getAttachmentCustomer = attachmentIdentifier => {
    axios
      .post(`${apiUrl}GetAttachment`, {
        Token: token,
        AttachmentIdentifier: attachmentIdentifier,
      })
      .then(response => {
        if (response.data && !response.data.Errors) {
          const contentType = response.data.Attachment.AttachmentContentType; // application/pdf, application/doc ...
          const base64Content = response.data.Attachment.AttachmentFile;
          const linkSource = `data:${contentType};base64,${base64Content}`;
          const fileName = response.data.Attachment.AttachmentFilename; // foo.pdf, bar.doc ...

          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          tfvLog(response.data.Errors, logApiLevel.error);
        }
      })
      .catch(err => {
        tfvLog(err, logApiLevel.error);
      });
  };

  const checkCancelAcceptedAssignment = () => {
    const startDate = new Date(currentDate().format('YYYY-MM-DD HH:mm:ss'));
    const endDate = new Date(item.DatetimeOrderFrom);
    const dateDifference = endDate - startDate;
    const hoursDiff = dateDifference / 1000 / 60 / 60;
    if (hoursDiff > 24.0) {
      const params = {
        CancellationReasonIdentifier: null,
        WorkAssignmentIdentifier: item.WorkAssignmentIdentifier,
      };
      setShowAcceptedOrderCancelModal(true);
    } else {
      setShowCancelModal(true);
    }
  };

  const attachments = item.Attachments || [];

  const attachmentElement = attachments.map(attach => (
    <Attachment
      key={attach.AttachmentReference}
      attachmentId={attach.AttachmentReference}
      fileName={attach.AttachmentFilename}
      type={attach.AttachmentContentType}
      onGetAttachment={getAttachmentCustomer}
    />
  ));

  const checkTimeReportExists = (from, to) => {
    if (from && to) {
      return true;
    }
    return false;
  };

  return (
    <>
      <AssignmentOverviewContainer
        showTabIndex={showTabIndex}
        NavigationClickHandler={NavigationClickHandler}
        assignmentId={item.OrderNumber}
        onClickBack={() => {
          clearInterval(countdown);
          onClickClose();
        }}
        onClickClose={() => {
          clearInterval(countdown);
          onClickClose();
        }}
      >
        <div className="tv-assignment-overview__container">
          <div className="tv-assignment-overview__content">
            <AssignmentOverviewProperty className="tv-assignment-overview__assignment-type">
              <AssignmentCustomerName
                serviceName={item.ArticleName}
                status={item.OrderStatus}
                customerName={item.Customer?.CustomerName}
                isColoredIcon
                assignment={item}
              />
              <AssignmentStatus status={statusColor} />
            </AssignmentOverviewProperty>
          </div>
          <hr />
          <div className="tv-assignment-overview__content">
            <AssignmentOverviewProperty
              isDisplay={
                item.OrderStatus === 'accept' ||
                item.OrderStatus === 'inquiry' ||
                item.OrderStatus === 'performed' ||
                item.OrderStatus === 'reported' ||
                item.OrderStatus === 'cancelled'
              }
              propertyIcon="person"
              propertyText={item.Customer?.CustomerName}
            />
            {item.IsTranslation === true && (
              <AssignmentOverviewProperty
                propertyText={item.ModeOfTransport}
                propertyIcon="account_tree"
              />
            )}
            {item.IsTranslation === false && (
              <AssignmentOverviewProperty
                propertyIcon="language"
                propertyText={item.Skill}
              />
            )}
            {item.IsTranslation === true && (
              <AssignmentOverviewProperty
                propertyIcon="language"
                propertyText={`Källspråk : ${item.Skill}`}
              />
            )}
            {item.IsTranslation === true && (
              <AssignmentOverviewProperty
                propertyIcon="translate"
                propertyText={`Målspråk : ${item.SkillTarget}`}
              />
            )}
            {item.SkillSubstitute && (
              <AssignmentOverviewProperty
                isLabelled={
                  item.IsBasedOnSkillSubstitute &&
                  item.OrderStatus !== 'inquiry'
                }
                propertyIcon=""
                propertyText={`Alternativt språk: ${item.SkillSubstitute}`}
              />
            )}
            <AssignmentOverviewProperty
              propertyIcon="watch_later"
              propertyText={`${convertToDayJsObject(
                item.DatetimeOrderFrom,
              ).format('HH:mm')} - ${convertToDayJsObject(
                item.DatetimeOrderTo,
              ).format('HH:mm, DD MMMM YYYY')}`}
            />
            <AssignmentOverviewProperty
              isDisplay={item.OrderStatus === 'inquiry'}
              className="tv-assignment-overview__amount"
              propertyText={`~ ${item.PreliminaryRemunerationAmountSEK} kr preliminär ersättning`}
              propertyIcon="attach_money"
            />
            {/* {item.IsPerformedRemotely === false &&
              item.AddressPerformingEffective && (
                <AssignmentOverviewProperty
                  propertyIcon="home"
                  propertyText={item.AddressPerformingEffective}
                />
              )} */}
          </div>

          <div className="tv-assignment-overview__content">
            {item.IsMessageService && <hr />}
            {item.Message && (
              <AssignmentOverviewProperty
                propertyIcon="note"
                propertyText={`Meddelande: ${item.Message}`}
              />
            )}
            {item.MessageReceiver && (
              <AssignmentOverviewProperty
                propertyIcon="person_outline"
                propertyText={`Mottagarens namn: ${item.MessageReceiver}`}
              />
            )}
            {item.MessageTelephone && (
              <AssignmentOverviewProperty
                propertyIcon="phone"
                propertyText={`Mottagarens telefonnummer: ${item.MessageTelephone}`}
              />
            )}
          </div>

          <hr />
          <div className="tv-assignment-overview__content">
            <AssignmentOverviewProperty>
              <AssignmentType
                serviceName={item.ArticleName}
                status={item.OrderStatus}
                isColoredIcon={false}
                className="tv-assignment-overview__assignment-type--small"
                assignment={item}
              />
            </AssignmentOverviewProperty>

            <AssignmentOverviewProperty
              isDisplay={
                item.MeetingDetails.DigitalMeetingPlatformIdentifier &&
                item.OrderStatus === 'inquiry'
              }
              propertyIcon="ondemand_video"
              propertyText={`Plattform: ${item.MeetingDetails?.DigitalMeetingPlatformName}`}
            />

            <AssignmentOverviewProperty
              isDisplay={item.OrderStatus === 'cancelled'}
              propertyIcon="watch_later"
              propertyText={`Uppdraget avbokades: ${convertToDayJsObject(
                item.DatetimeSubmitted,
              ).format('HH:mm, DD MMMM YYYY')}`}
            />
            {/* <AssignmentOverviewProperty
              isDisplay={
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed' ||
                  item.OrderStatus === 'reported') &&
                item.CustomerReference
              }
              propertyIcon="assignment"
              propertyText={`Bokningsreferens: ${item.CustomerReference}`}
            /> */}
            {/* <AssignmentOverviewProperty
              isDisplay={
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed' ||
                  item.OrderStatus === 'reported') &&
                item.CustomerOrderNumber
              }
              propertyIcon="confirmation_number"
              propertyText={`Kundens bokningsnummer: ${item.CustomerOrderNumber}`}
            /> */}
            <AssignmentOverviewProperty
              isDisplay={
                item.OrderStatus === 'inquiry' ||
                item.OrderStatus === 'accept' ||
                item.OrderStatus === 'performed' ||
                item.OrderStatus === 'reported'
              }
              propertyIcon="perm_contact_calendar"
              propertyText={`Tolkanvändare: ${
                item.ContactPerson?.ContactPersonTitle !== null &&
                item.ContactPerson?.ContactPersonTitle !== ''
                  ? item.ContactPerson?.ContactPersonTitle
                  : ''
              } ${' '} ${item.ContactPerson?.ContactPersonName}`}
            />
            <AssignmentOverviewProperty
              isDisplay={item.OrderStatus !== 'cancelled' && item.NotesResource}
              propertyIcon="message"
              className="mobile-expand"
              propertyText={`Information till tolk: ${item.NotesResource}`}
            />
            <AssignmentOverviewProperty
              isDisplay={
                item.OrderStatus !== 'inquiry' &&
                item.OrderStatus === 'cancelled'
              }
              propertyIcon="message"
              className="mobile-expand"
              propertyText={item.RemunerationDetails}
            />
            <AssignmentOverviewProperty
              isDisplay={
                item.MeetingDetails.DigitalMeetingPlatformIdentifier &&
                item.OrderStatus === 'accept' &&
                item.MeetingDetails.MeetingPhoneNumber
              }
              propertyIcon="phone_in_talk"
              propertyText={
                item.MeetingDetails?.MeetingPhoneNumber || item.PhoneNumberOrder
              }
            />
            <AssignmentOverviewProperty
              isDisplay={
                item.MeetingDetails.DigitalMeetingPlatformIdentifier &&
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed')
              }
              propertyIcon="ondemand_video"
              propertyText={`Plattform: ${item.MeetingDetails?.DigitalMeetingPlatformName}`}
            />
            <AssignmentOverviewProperty
              isDisplay={
                item.MeetingDetails.DigitalMeetingPlatformIdentifier &&
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed')
              }
              isLink
              propertyIcon="link"
              propertyText={item.MeetingDetails?.MeetingUrl}
            />
            <AssignmentOverviewProperty
              isDisplay={
                item.MeetingDetails.DigitalMeetingPlatformIdentifier &&
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed') &&
                item.MeetingDetails?.DoRequireBankIdVerification === 'True'
              }
            >
              <div className="tv-assignment-overview__text">
                <i
                  className="tv-assignment-overview--bankid-icon"
                  style={{
                    backgroundImage: 'url("/images/bankid.png")',
                  }}
                />
                <div className="tv-assignment-overview__text">BankID krävs</div>
              </div>
            </AssignmentOverviewProperty>
            <AssignmentOverviewProperty
              isDisplay={
                item.MeetingDetails.DigitalMeetingPlatformIdentifier &&
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed') &&
                item.MeetingDetails?.MeetingPassCode
              }
              propertyIcon="lock"
              propertyText={item.MeetingDetails?.MeetingPassCode}
            />
            <AssignmentOverviewProperty
              isDisplay={item.IsPerformedRemotely === false}
              className={item.IsDeviatingAddressPerforming ? 'red-text' : ''}
              propertyIcon="business"
              propertyText={formatAddress(
                item.AddressPerformingEffective,
                item.IsDeviatingAddressPerforming,
              )}
            />
            <AssignmentOverviewProperty
              isDisplay={
                item.IsPerformedRemotely === false &&
                item.SpecificTravelInstructions !== null &&
                item.IsDeviatingAddressPerforming === false &&
                item.IsHomeAddress === false
              }
              propertyIcon="message"
              className="mobile-expand"
              propertyText={item.SpecificTravelInstructions}
            />
            <AssignmentOverviewProperty
              isDisplay={item.OrderStatus === 'reported' && item.RatingResource}
            >
              <div>
                <div className="tv-assignment-overview__text">Betyg</div>
                <StarRating
                  defaultValue={item.RatingResource || null}
                  viewMode
                />
              </div>
            </AssignmentOverviewProperty>
          </div>
          <hr />
          <div className="tv-assignment-overview__content">
            <AssignmentOverviewProperty>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  marginBottom: '10px',
                  fontFamily: 'Source Sans Pro',
                  fontSize: '13px',
                  fontWeight: '400',
                }}
              >
                {attachmentElement}
              </div>
            </AssignmentOverviewProperty>
          </div>

          {item.IsTranslation === false && (
            <div className="tv-assignment-overview__footer">
              {item.OrderStatus === 'inquiry' && (
                <>
                  <Button
                    className="left-button"
                    label="Tacka nej"
                    palette="outline"
                    onClick={() =>
                      onReject(
                        false,
                        item.ResourceRequestIdentifier,
                        item.WorkAssignmentIdentifier,
                      )
                    }
                  />
                  <Button
                    className="right-button"
                    label="Acceptera"
                    onClick={() =>
                      onAccept(
                        true,
                        item.ResourceRequestIdentifier,
                        item.WorkAssignmentIdentifier,
                      )
                    }
                  />
                </>
              )}
              {item.OrderStatus === 'accept' && (
                <div className="button-group-wrapper">
                  {!onGoingOrder && (
                    <div className="button-wrapper">
                      <Button
                        className="full-button"
                        label="Klar med uppdraget"
                        palette="primary"
                        disabled={disableFinishedEarly}
                        onClick={() => {
                          onAssignmentEarlyCompleted(
                            item.WorkAssignmentIdentifier,
                          );
                        }}
                      />
                    </div>
                  )}

                  {!onGoingOrder && (
                    <Button
                      className="cancel-button"
                      label="Kan ej genomföra"
                      palette="text"
                      onClick={() => checkCancelAcceptedAssignment()}
                    />
                  )}
                  {((item.CanBeTimeReported === true &&
                    !checkTimeReportExists(
                      item.TimeReport.DatetimeOriginalFrom,
                      item.TimeReport.DatetimeOriginalTo,
                    )) ||
                    (item.CanBeTimeReported === true &&
                      checkTimeReportExists(
                        item.TimeReport.DatetimeOriginalFrom,
                        item.TimeReport.DatetimeOriginalTo,
                      )) ||
                    (item.CanBeTimeReported === false &&
                      checkTimeReportExists(
                        item.TimeReport.DatetimeOriginalFrom,
                        item.TimeReport.DatetimeOriginalTo,
                      ))) &&
                    onGoingOrder && (
                      <Button
                        className="full-button mt-2"
                        // label="Rapportera avvikande tid"
                        label={
                          item.CanBeTimeReported === true
                            ? 'Tidrapport'
                            : 'Visa tidrapport'
                        }
                        palette="primary"
                        onClick={() => {
                          setShowTimeSheetModal(true);
                          setShowTabIndex(-1);
                        }}
                      />
                    )}
                  {item.CanBeRated && onGoingOrder && (
                    <Button
                      className="full-button"
                      label="Ge feedback"
                      palette="primary"
                      onClick={() => {
                        setShowFeedbackModal(true);
                        
                      }}
                    />
                  )}
                </div>
              )}
              {item.OrderStatus === 'performed' && (
                <>
                  {item.CanBeRated && (
                    <Button
                      className="full-button"
                      label="Ge feedback"
                      palette="primary"
                      onClick={() => {
                        setShowFeedbackModal(true);
                       
                      }}
                    />
                  )}
                  {((item.CanBeTimeReported === true &&
                    !checkTimeReportExists(
                      item.TimeReport.DatetimeOriginalFrom,
                      item.TimeReport.DatetimeOriginalTo,
                    )) ||
                    (item.CanBeTimeReported === true &&
                      checkTimeReportExists(
                        item.TimeReport.DatetimeOriginalFrom,
                        item.TimeReport.DatetimeOriginalTo,
                      )) ||
                    (item.CanBeTimeReported === false &&
                      checkTimeReportExists(
                        item.TimeReport.DatetimeOriginalFrom,
                        item.TimeReport.DatetimeOriginalTo,
                      ))) && (
                    <Button
                      className="full-button"
                      // label="Rapportera avvikande tid"
                      label={
                        item.CanBeTimeReported === true
                          ? 'Tidrapport'
                          : 'Visa tidrapport'
                      }
                      palette="primary"
                      onClick={() => {
                        setShowTimeSheetModal(true);
                        setShowTabIndex(-1);
                      }}
                    />
                  )}
                </>
              )}
              {item.OrderStatus === 'reported' && (
                <Button
                  className="full-button"
                  label="Se rapporterad avvikande tid"
                  palette="primary"
                  onClick={() => {
                    setShowTimeSheetModal(true);
                    setShowTabIndex(-1);
                  }}
                />
              )}
              {item.OrderStatus === 'cancelled' && (
                <>
                  {/* <Button
                  className="full-button "
                  label="Kontakta TFV"
                  palette="outline"
                  onClick={() => {
                    setShowContactModal(true);
                  }}
                /> */}
                  {item.IsVisible && (
                    <Button
                      className="full-button"
                      label="Ta bort från kalendern"
                      palette="primary"
                      onClick={() => {
                        onHideAssignment(item);
                      }}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </AssignmentOverviewContainer>
      {showCancelModal && (
        <AssignmentCancelModal
          isVisible={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onClick={() => setShowCancelModal(false)}
        />
      )}
      {showAcceptedOrderCancelModal && (
        <AssignmentRequestModal
          isVisible="true"
          onClose={() => {
            setShowAcceptedOrderCancelModal(false);
          }}
          title="Återlämna uppdrag"
          acceptanceButtonLabel="Återlämna uppdrag"
          rejectButtonLabel="Avbryt"
          onClick={() => {
            const params = {
              CancellationReasonIdentifier: null,
              WorkAssignmentIdentifier: item.WorkAssignmentIdentifier,
            };
            cancelAcceptedWorkAssignment(params);
          }}
        >
          <p className="mt-3">
            Är du säker på att du vill lämna tillbaka uppdraget?
          </p>
          <p>
            Om du återlämnar många uppdrag kan det komma att påverka hur många
            framtida förfrågningar du får.
          </p>
        </AssignmentRequestModal>
      )}
      {showContactModal && (
        <AssignmentContactModal
          isVisible={showContactModal}
          onClose={() => setShowContactModal(false)}
          onClick={() => setShowContactModal(false)}
        />
      )}
      {showTimeSheetModal && (
        <AssignmentTimeReportEditContainer
          item={item}
          isVisible={showTimeSheetModal}
          isEdit={item.CanBeTimeReported}
          onClose={() => setShowTimeSheetModal(false)}
        />
      )}
      {showFeedbackModal && (
        <FeedbackContainer
          item={item}
          isVisible={showFeedbackModal}
          onClose={() => setShowFeedbackModal(false)}
        />
      )}
    </>
  );
}

AssignmentOverview.propTypes = {
  item: PropTypes.shape({}),
  onClickClose: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  NavigationClickHandler: PropTypes.func,
  onHideAssignment: PropTypes.func,
  onAssignmentEarlyCompleted: PropTypes.func,
};
AssignmentOverview.defaultProps = {
  item: {},
  onClickClose: () => {},
  onAccept: () => {},
  onReject: () => {},
  NavigationClickHandler: () => {},
  onHideAssignment: () => {},
  onAssignmentEarlyCompleted: () => {},
};

export default AssignmentOverview;

const AssignmentOverviewProperty = ({
  children,
  isDisplay = true,
  propertyIcon,
  propertyText,
  className = '',
  isLink = false,
  isLabelled = false,
}) =>
  isDisplay && (
    <div
      className={`tv-assignment-overview__content-area ${
        isLabelled && 'labelled'
      } ${className}`}
    >
      {!children ? (
        <div className="tv-assignment-overview__text" title={propertyText}>
          <i className="material-icons">{propertyIcon}</i>
          {!isLink ? (
            <span>{propertyText}</span>
          ) : (
            <a href={propertyText} target="__blank">
              {propertyText}
            </a>
          )}
        </div>
      ) : (
        children
      )}
    </div>
  );
