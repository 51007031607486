/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Button, SidebarAssignmentListItem } from 'components';
import {
  convertToDayJsObject,
  currentDate,
  get24HoursInday,
} from 'utils/dateTimeUtils';
import { WorkAssignmentItemContainer } from 'containers';
import './style.scss';
import { PIXEL_PER_HOUR } from 'constant';
import { calculateHeightAndPosition } from 'context/CalendarContext';
import { useSelector } from 'react-redux';
import { fromWorkAssignment } from 'store/selectors';
import FocusTrap from 'focus-trap-react';

function SidebarAssignmentList({
  list,
  availabilityList,
  onWorkAssignmentClick,
  onAvailabilityExceptionClick,
  date,
  onClose,
  hours,
}) {
  const assignmentListRef = useRef(null);
  const currentTimeRef = useRef(null);
  const [listState, setListState] = useState(list);
  const scrollToCurrentTime = elementRef => {
    const currentTimeElement = elementRef?.current;
    const pixelPerMinute = 128 / 60;
    const initialStartTime =
      currentDate().diff(currentDate().startOf('day'), 'minute') *
      pixelPerMinute;

    currentTimeElement.style.top = `${initialStartTime}px`;
    currentTimeElement.setAttribute('data-time', initialStartTime);
    currentTimeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });

    const currentTimeInterval = setInterval(() => {
      const newTime =
        (Number.parseFloat(currentTimeElement.getAttribute('data-time')) || 0) +
        pixelPerMinute;
      currentTimeElement.style.top = `${newTime}px`;
      currentTimeElement.setAttribute('data-time', newTime);
    }, 60000);
    return currentTimeInterval;
  };

  useEffect(() => {
    let currentTimeInterval;
    setListState(list);
    setTimeout(() => {
      const assignmentItem = assignmentListRef.current.querySelector(
        '.tv-sidebar-assignment-list-item__container',
      );

      if (currentTimeRef?.current) {
        currentTimeInterval = scrollToCurrentTime(currentTimeRef);
      } else if (assignmentItem) {
        if (!window.document.documentMode) {
          assignmentItem.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
      }
    }, 500);
    return () => {
      if (currentTimeInterval) {
        clearInterval(currentTimeInterval);
      }
    };
  }, [list, date]);

  const assignmentTobeHidden = useSelector(state =>
    fromWorkAssignment.getHiddenAssignment(state),
  );

  useEffect(() => {
    if (assignmentTobeHidden) {
      setListState(
        listState.filter(
          x =>
            x.WorkAssignmentIdentifier !== assignmentTobeHidden && x.IsVisible,
        ),
      );
    }
  }, [assignmentTobeHidden]);

  return (
    <FocusTrap
      focusTrapOptions={{
        escapeDeactivates: false,
        allowOutsideClick: true,
      }}
    >
      <div className="tv-sidebar-assignment-list" ref={assignmentListRef}>
        <div className="tv-sidebar-assignment-list__header">
          <div className="tv-sidebar-assignment-list__header-title tv-mobile--hide">
            Uppdrag
          </div>
          <div className="tv-sidebar-assignment-list__header-title tv-mobile--show">
            {date.format('dddd, D MMMM')}
          </div>
          <IconButton
            onClick={onClose}
            iconName="times"
            aria-label="stäng knapp"
            label="stäng knapp"
            className="month-week-single-assignment"
          />
        </div>
        <div className="tv-sidebar-assignment-list__content">
          <div className="tv-sidebar-assignment-list__content-text  tv-mobile--hide">
            Visar alla uppdrag för {date.format('D MMMM, YYYY')}
          </div>
          <div className="tv-sidebar-assignment-list__content-timeline">
            {currentDate().isSame(date, 'day') && (
              <hr
                className="tv-sidebar-assignment-list__content-timeline__curent-time"
                ref={currentTimeRef}
              />
            )}

            <div
              className="tv-sidebar-assignment-list__content-availability--default"
              style={{
                background: "url('/images/unavailable-texture.png')",
                height: `${PIXEL_PER_HOUR * 24}px`,
              }}
            />
            <HourList list={hours} />
            <AssignmentList
              list={listState}
              onWorkAssignmentClick={onWorkAssignmentClick}
            />
            <AvailabilityList
              list={availabilityList}
              onItmeclick={onAvailabilityExceptionClick}
            />
          </div>
        </div>

        <Button
          label=" Stäng"
          palette="outline"
          className="tv-sidebar-assignment-list__close-button"
          onClick={onClose}
        />
      </div>
    </FocusTrap>
  );
}

SidebarAssignmentList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  hours: PropTypes.arrayOf(PropTypes.string),
  date: PropTypes.shape({}),
  onClose: PropTypes.func,
  onWorkAssignmentClick: PropTypes.func,
};

SidebarAssignmentList.defaultProps = {
  list: [],
  hours: get24HoursInday(),
  date: {},
  onClose: () => {},
  onWorkAssignmentClick: () => {},
};

export default SidebarAssignmentList;

function AssignmentList({ list = [], onWorkAssignmentClick }) {
  const [assignmentsList, setAssignmentsList] = useState(list || []);
  useEffect(() => {
    setAssignmentsList(list);
  }, [list]);
  return assignmentsList.map((item, index) => (
    <WorkAssignmentItemContainer
      key={item.WorkAssignmentIdentifier}
      detail={item}
      index={index}
      render={childProps => (
        <SidebarAssignmentListItem
          {...childProps}
          onClick={e => {
            e.stopPropagation();
            onWorkAssignmentClick(item);
          }}
        />
      )}
    />
  ));
}

function HourList({ list = [] }) {
  const [hours, setHours] = useState(list || []);
  useEffect(() => {
    setHours(list);
  }, [list]);
  return (
    <div className="tv-sidebar-assignment-list__content-timeline-container">
      {hours.map(item => (
        <div
          className="tv-sidebar-assignment-list__content-timeline-frame"
          key={item}
        >
          <div className="tv-sidebar-assignment-list__content-timeline-frame-time">
            {item}
          </div>
        </div>
      ))}
    </div>
  );
}

function AvailabilityList({ list = [], onItmeclick }) {
  const [availabilityList, setAvailabilityList] = useState(list || []);
  useEffect(() => {
    setAvailabilityList(list);
  }, [list]);
  return (
    <div className="tv-sidebar-assignment-list__content-availability-container">
      {availabilityList.map(availability => (
        <AvailabilitySlot
          {...availability}
          onClick={() => onItmeclick(availability)}
        />
      ))}
    </div>
  );
}

function AvailabilitySlot({ ...props }) {
  const [timeFrom, timeTo] = [
    convertToDayJsObject(props.DatetimeFrom),
    convertToDayJsObject(props.DatetimeTo),
  ];
  const processItem = calculateHeightAndPosition(timeFrom, timeTo);
  const timeRangeString = `${timeFrom.format('HH:mm')} - ${timeTo.format(
    'HH:mm',
  )}`;
  return (
    <div
      className={`tv-sidebar-assignment-list__content-availability-slot ${
        props.AvailabilityType === 4 &&
        'tv-week-day-availability__slot-available-now'
      } `}
      style={{
        top: processItem.yCoordinate,
        height: processItem.height,
        background: props.AvailabilityType === 2 ? '#FFF' : '#F2F2F2',
        zIndex: props.AvailabilityType === 3 && '4',
      }}
    >
      {props.DefaultAvailabilityExceptionIdentifier && (
        <div
          className="tv-week-view-assignment-item__container tv-week-view-assignment-item__container--availablity tv-week-view-assignment-item--availablity"
          role="button"
          tabIndex="0"
          onClick={props.onClick}
          onKeyPress={props.onClick}
        >
          <div className="tv-week-view-assignment-item__header">
            <div className="tv-week-view-assignment-item__header__name">
              {props.AvailabilityType === 2 ? 'Tillgänglig' : 'Upptagen'}
            </div>
          </div>
          {processItem.diffInMinutes > 15 && (
            <div className="tv-week-view-assignment-item__sub-container">
              <div className="tv-week-view-assignment-item__sub-item">
                <span className="tv-week-view-assignment-item__sub-item__value--text">
                  {timeRangeString}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {props.AvailabilityType === 4 && (
        <>
          <div className="tv-week-view-available-now__container">
            Tillgänglig nu till {timeTo.format('HH:mm')}
          </div>
          <div className="arrow-right" />
          <div className="arrow-left" />
        </>
      )}
    </div>
  );
}
