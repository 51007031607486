/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { convertToDayJsObject, localDayjs as dayJS } from 'utils/dateTimeUtils';

import './style.scss';

import {
  AssignmentType,
  AssignmentStatus,
  AssignmentRequestHandler,
  AssignmentCountdown,
  Translate,
} from 'components';
import { checkOnGoingAssignment } from 'utils/miscUtil';

const AssignmentItem = ({
  className,
  onClick,
  onAccept,
  onReject,
  screen,
  item,
  isSelected,
}) => {
  const [statusColor, setStatusColor] = useState(item.OrderStatus);

  useEffect(() => {
    const updateStatusColor = () => {
      const isOnGoingStatus = checkOnGoingAssignment(item);
      if (isOnGoingStatus) {
        setStatusColor('onGoing');
      } else {
        setStatusColor(item.OrderStatus);
      }
      setTimeout(updateStatusColor, 5000);
    };
    updateStatusColor();
  }, [item]);
  return (
    <>
      <div
        className={`tv-assignment-item__container tv-workassignment__list-item ${
          isSelected
            ? 'tv-assignment-item__container tv-assignment-item-selected'
            : ''
        } ${className}`}
        onClick={() => onClick(item)}
        role="button"
        tabIndex="0"
        // onKeyDown={() => onClick(item)}
        onKeyDown={e =>
          e.key === 'Enter' || e.key === ' ' ? onClick(item) : null
        }
      >
        <div className="align-items-center d-flex justify-content-around mx-0 position-relative px-0 tv-assignment-item__row tv-mobile--hide w-100">
          <div className="tv-assignment-item__row--type">
            <div className="tv-assignment-item-text">
              <AssignmentType
                status={item.OrderStatus}
                serviceName={item.ArticleName}
                showShortText
                assignment={item}
              />
            </div>
          </div>
          <div className="tv-assignment-item__row--date">
            <div className="tv-assignment-item-text tv-workassignment__item-text-date">
              {`${convertToDayJsObject(item.DatetimeOrderFrom).format(
                'DD MMM, HH:mm',
              )} - ${convertToDayJsObject(item.DatetimeOrderTo).format(
                'HH:mm',
              )}`}
            </div>
          </div>
          <div className="tv-assignment-item__row--number">
            <div className="tv-assignment-item-text">{item.OrderNumber}</div>
          </div>
          {screen === 'availableRequest' && (
            <div className="tv-assignment-item__row--pay">
              <div className="tv-assignment-item-text">
                {item.PreliminaryRemunerationAmountSEK} kr
              </div>
            </div>
          )}
          {screen !== 'availableRequest' && (
            <div className="tv-assignment-item__row--customer">
              <div className="tv-assignment-item-text text-max-lenght">
                {item.Customer.CustomerName}
              </div>
            </div>
          )}
          <div className="tv-assignment-item__row--language">
            <div className="tv-assignment-item-text text-max-lenght">
              {screen !== 'availableRequest' && item.IsBasedOnSkillSubstitute
                ? item.SkillSubstitute
                : item.Skill}
            </div>
          </div>
          {screen === 'availableRequest' && (
            <div className="tv-assignment-item__row--countdown">
              <AssignmentCountdown
                className="tv-assignment-item-text text-max-lenght tv-assignment-item-expiry-time"
                expiryTime={convertToDayJsObject(item?.DatetimeExpiration)}
              />
            </div>
          )}
          <div className="tv-assignment-item__row--status">
            <div className="tv-assignment-item-text">
              {screen === 'availableRequest' && (
                <AssignmentRequestHandler
                  item={item}
                  onAccept={onAccept}
                  onReject={onReject}
                  isSelected={isSelected}
                />
              )}
              {screen !== 'availableRequest' && (
                <AssignmentStatus
                  className="d-flex justify-content-end text-md-right w-100"
                  status={statusColor}
                  serviceName={item.ArticleName}
                />
              )}
            </div>
          </div>
        </div>
        <MobileView
          item={item}
          screen={screen}
          onAccept={onAccept}
          onReject={onReject}
        />
      </div>
    </>
  );
};

AssignmentItem.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  screen: PropTypes.string,
  item: PropTypes.shape({}),
  isSelected: PropTypes.bool,
};
AssignmentItem.defaultProps = {
  className: '',
  onClick: () => {},
  onAccept: () => {},
  onReject: () => {},
  screen: '',
  item: {},
  isSelected: false,
};

export default AssignmentItem;

const MobileView = ({ item, screen, onAccept, onReject }) => {
  const [statusColor, setStatusColor] = useState(item.OrderStatus);

  useEffect(() => {
    const updateStatusColor = () => {
      const isOnGoingStatus = checkOnGoingAssignment(item);
      if (isOnGoingStatus) {
        setStatusColor('onGoing');
      } else {
        setStatusColor(item.OrderStatus);
      }
      setTimeout(updateStatusColor, 5000);
    };
    updateStatusColor();
  }, [item]);

  return (
    <div className="tv-mobile--show tv-assignment-item-mobile__container">
      <div className="tv-assignment-item-mobile__header">
        <AssignmentType
          status={item.OrderStatus}
          serviceName={item.ArticleName}
          assignment={item}
        />
        {screen !== 'availableRequest' && (
          <AssignmentStatus
            status={statusColor}
            serviceName={item.ArticleName}
          />
        )}
        {screen === 'availableRequest' && (
          <AssignmentCountdown
            className="tv-assignment-item-expiry-time"
            expiryTime={convertToDayJsObject(item?.DatetimeExpiration)}
          />
        )}
      </div>
      <hr />
      <div className="tv-assignment-item-mobile__content">
        <div className="tv-assignment-item-text text-max-lenght">
          <i className="tv-mobile--show material-icons">language</i>
          {item.Skill}
        </div>
        <div className="tv-assignment-item-text text-max-lenght">
          <i className="tv-mobile--show material-icons">access_time_filled</i>
          {`${convertToDayJsObject(item.DatetimeOrderFrom).format(
            'DD MMM, HH:mm',
          )} - ${convertToDayJsObject(item.DatetimeOrderTo).format('HH:mm')}`}
        </div>
        {screen === 'availableRequest' && (
          <div className="tv-assignment-item-mobile__group">
            <div className="tv-assignment-item-mobile-contact-person">
              <div className="tv-assignment-item-text text-max-lenght">
                <i className="tv-mobile--show material-icons">person</i>
                {item.Customer.CustomerName}
              </div>
            </div>
            <div>
              <AssignmentRequestHandler
                item={item}
                onAccept={onAccept}
                onReject={onReject}
              />
            </div>
          </div>
        )}
        {screen !== 'availableRequest' && (
          <>
            <div className="tv-assignment-item-text text-max-lenght">
              <i className="tv-mobile--show material-icons">person</i>
              {item.Customer.CustomerName}
            </div>
            <div className="tv-assignment-item-text text-max-lenght">
              <i className="tv-mobile--show material-icons">assignment</i>
              {`${Translate({ content: 'assignment.orderNumber' })} ${
                item.OrderNumber
              }`}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
