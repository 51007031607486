import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resourceCreateRequest } from 'store/actions';
import { pending, fulfilled, clean } from 'redux-saga-thunk';
import { useWorkAssignment } from 'hooks';

export const apis = {
  RATE_WORK_ASSIGNMENTS: 'rateWorkAssignment',
  CREATE_RESOURCE_DEVIATIONS: 'createResourceDeviation',
};

const useGiveFeedback = (workAssignment = {}) => {
  const dispatch = useDispatch();

  const { searchSingleWorkAssignment } = useWorkAssignment();

  const sendRatingData = rate => {
    dispatch(resourceCreateRequest(apis.RATE_WORK_ASSIGNMENTS, rate));
  };

  const sendResourceDeviationData = deviationData => {
    dispatch(
      resourceCreateRequest(apis.CREATE_RESOURCE_DEVIATIONS, deviationData),
    );
  };

  const [
    rateWorkAssignmentFulfilled,
    setRateWorkAssignmentFulfilled,
  ] = useState(false);
  const [
    createResourceDeviationFulfilled,
    setCreateResourceDeviationFulfilled,
  ] = useState(false);

  const sendRatingDataFulfilled = useSelector(state =>
    fulfilled(state, `${apis.RATE_WORK_ASSIGNMENTS}Create`),
  );

  const sendRatingDataPending = useSelector(state =>
    pending(state, `${apis.RATE_WORK_ASSIGNMENTS}Create`),
  );

  const sendResourceDeviationDataFulfilled = useSelector(state =>
    fulfilled(state, `${apis.CREATE_RESOURCE_DEVIATIONS}Create`),
  );

  const sendResourceDeviationDataPending = useSelector(state =>
    pending(state, `${apis.CREATE_RESOURCE_DEVIATIONS}Create`),
  );

  useEffect(() => {
    if (sendRatingDataFulfilled && workAssignment.WorkAssignmentIdentifier) {
      searchSingleWorkAssignment(workAssignment.WorkAssignmentIdentifier);
      setRateWorkAssignmentFulfilled(true);
    }
    return () => {
      dispatch(clean(`${apis.RATE_WORK_ASSIGNMENTS}Create`));
    };
  }, [sendRatingDataFulfilled]);

  useEffect(() => {
    if (
      sendResourceDeviationDataFulfilled &&
      workAssignment.WorkAssignmentIdentifier
    ) {
      searchSingleWorkAssignment(workAssignment.WorkAssignmentIdentifier);
      setCreateResourceDeviationFulfilled(true);
    }
    return () => {
      dispatch(clean(`${apis.CREATE_RESOURCE_DEVIATIONS}Create`));
    };
  }, [sendResourceDeviationDataFulfilled]);

  return {
    sendRatingData,
    sendResourceDeviationData,
    rateWorkAssignmentFulfilled,
    createResourceDeviationFulfilled,
  };
};

export default useGiveFeedback;
