import { LOCAL_WEB_SESSION_ID } from 'constant';
import { localDayjs } from 'utils/dateTimeUtils';
import {
  customEvent,
  registerAirshipChannel,
} from 'utils/pushNotificationUtils';

// eslint-disable-next-line import/prefer-default-export
export const isIE =
  !!document.documentMode ||
  window.navigator.userAgent.indexOf('Edge') > -1 ||
  false;

function validateWebSession(webSessionId) {
  const currentDate = localDayjs().startOf('day');
  const sessionDate = localDayjs(webSessionId, 'YYYYMMDDHHmmss').startOf('day');
  const diff = currentDate.diff(sessionDate, 'days');
  if (diff === 0) {
    return true;
  }
  return false;
}
async function setWebSessionOnLocalStorage() {
  localStorage.setItem(
    LOCAL_WEB_SESSION_ID,
    localDayjs().format('YYYYMMDDHHmmss'),
  );
  await customEvent('web_session_open', {});
}
export const createUpdateWebSession = async interpreterId => {
  const localWebSessionId = localStorage.getItem(LOCAL_WEB_SESSION_ID);
  if (!localWebSessionId) {
    await registerAirshipChannel(interpreterId, setWebSessionOnLocalStorage);

    return 1;
  }
  if (!validateWebSession(localWebSessionId)) {
    await setWebSessionOnLocalStorage();
    return 1;
  }
  return 0;
};
