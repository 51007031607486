/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fulfilled, rejected, clean, pending } from 'redux-saga-thunk';
import { currentDate, localDayjs as dayjs } from 'utils/dateTimeUtils';
import { AssignmentFilterModal, Spinner } from 'components';
import { resourceCreateRequest, resourceListReadRequest } from 'store/actions';
import { connect } from 'react-redux';

const AssignmentFilterContainer = ({ onClose, isVisible, isPending }) => {
  const [fromDatePicker, setFromDatePicker] = useState(null);
  const [toDatePicker, setToDatePicker] = useState(null);

  const handleOnSubmit = () => {};

  const handleFromDatePickerChange = date => {
    const changedDate = dayjs(date);
    setFromDatePicker(changedDate);
  };

  const handleToDatePickerChange = date => {
    const changedDate = dayjs(date);
    setToDatePicker(changedDate);
  };

  return (
    <>
      <AssignmentFilterModal
        isVisible={isVisible}
        onClose={onClose}
        validationError={[]}
        selectedFromDate={fromDatePicker}
        selectedToDate={toDatePicker}
        onConfirm={handleOnSubmit}
        onFromDatePickerChange={handleFromDatePickerChange}
        onToDatePickerChange={handleToDatePickerChange}
      />
      {isPending && <Spinner />}
    </>
  );
};

AssignmentFilterContainer.propTypes = {
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  isPending: PropTypes.bool,
};

AssignmentFilterContainer.defaultProps = {
  onClose: () => {},
  isVisible: false,
  isPending: false,
};

const mapDispatchToProps = dispatch => ({});
const mapStateToProps = state => ({
  isPending: false,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignmentFilterContainer);
