/* eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'components';
import './style.scss';

const ContentPanel = props => (
  <div className="tv-person-data-policy single">{props.children}</div>
);

const ContentPageWrap = props => (
  <div className="tv-person-data-policy__page-wrap">{props.children}</div>
);

const ContentPage = props => (
  <div className="tv-person-data-policy__page">{props.children}</div>
);

const ContentTitle = props => (
  <h4 className="tv-person-data-policy__heading1">{props.children}</h4>
);

const ContentHeading = props => (
  <p className="tv-person-data-policy__heading2">{props.children}</p>
);

const ContentParagraph = props => (
  <p className="tv-person-data-policy__paragraph">{props.children}</p>
);

const ContentSection = props => (
  <div className="tab-panel-section">{props.children}</div>
);

const ContentSubSection = props => (
  <div className="tab-panel-section__sub">{props.children}</div>
);

function PersonalDataPolicy(props) {
  return <ContentPanel></ContentPanel>;
}

PersonalDataPolicy.propTypes = {};

export default PersonalDataPolicy;
