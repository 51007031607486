/* eslint-disable no-else-return */
import { useState, useContext, useEffect } from 'react';
import { ReactReduxContext } from 'react-redux';
import { resourceListReadRequest } from 'store/actions';
import { fromResource } from 'store/selectors';
import { fulfilled, pending, rejected, clean } from 'redux-saga-thunk';
import { useToasts } from 'react-toast-notifications';
import { Translate } from 'components';
import { downloadFile } from 'utils/fileUtils';

function useGetFile(resource = 'GetFile') {
  const { store } = useContext(ReactReduxContext);
  const { getState, dispatch, subscribe } = store;
  const { addToast } = useToasts();

  const [downloadedFile, setdownloadedFile] = useState(
    fromResource.getList(getState(), resource),
  );
  const [isDownloadsuccess, setIsDownloadsuccess] = useState(
    fulfilled(getState(), `${resource}ListRead`),
  );
  const [isDownloadpending, setIsDownloadpending] = useState(
    rejected(getState(), `${resource}ListRead`),
  );
  const [isDownloadfailed, setIsDownloadfailed] = useState(
    rejected(getState(), `${resource}ListRead`),
  );

  useEffect(
    () =>
      subscribe(() =>
        setdownloadedFile(fromResource.getList(getState(), resource)),
      ),
    [],
  );
  useEffect(
    () =>
      subscribe(() =>
        setIsDownloadsuccess(fulfilled(getState(), `${resource}ListRead`)),
      ),
    [],
  );
  useEffect(
    () =>
      subscribe(() =>
        setIsDownloadpending(pending(getState(), `${resource}ListRead`)),
      ),
    [],
  );
  useEffect(
    () =>
      subscribe(() =>
        setIsDownloadfailed(rejected(getState(), `${resource}ListRead`)),
      ),
    [],
  );

  useEffect(() => {
    if (isDownloadsuccess) {
      downloadFile(
        downloadedFile.FileContentType,
        downloadedFile.File,
        downloadedFile.FileName,
      );
      dispatch(clean(`${resource}ListRead`));

      addToast(Translate({ content: 'file.downloadSuccess' }), {
        appearance: 'success',
      });
    }
  }, [isDownloadsuccess, isDownloadfailed]);

  const GetFile = params => {
    if (!isDownloadpending) {
      dispatch(resourceListReadRequest(resource, 'File', params, true));
    }
  };

  return {
    GetFile,
    downloadedFile,
    isDownloadsuccess,
    isDownloadfailed,
  };
}

export default useGetFile;
