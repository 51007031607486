import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { truncateFileName } from 'utils/miscUtil';

const propTypes = {
  fileName: PropTypes.string,
  attachmentId: PropTypes.string,
  onGetAttachment: PropTypes.func,
};

const defaultProps = {
  fileName: '',
  attachmentId: '',
  onGetAttachment: () => {},
};

class Attachment extends Component {
  handleDownload = () => {
    const { attachmentId, onGetAttachment } = this.props;
    onGetAttachment(attachmentId);
  };

  shorterFileName = fileName => {
    const EXPECT_LENGTH = 15;
    return truncateFileName(fileName, EXPECT_LENGTH, '...');
  };

  render() {
    const { fileName } = this.props;

    return (
      <div
        style={{
          fontSize: '12px',
          color: '#74737b',
          textAlign: 'start',
          display: 'flex',
          marginBottom: '5px',
        }}
      >
        <i
          className="fa fa-file"
          aria-hidden="true"
          style={{ marginRight: '12px', marginLeft: '2px', marginTop: '3px' }}
        />
        <div style={{ color: '#252525' }}>{this.shorterFileName(fileName)}</div>
        <span
          role="button"
          onClick={this.handleDownload}
          className="ml-2"
          title="Ladda ner"
          style={{ cursor: 'pointer' }}
          onKeyDown={e => (e.key === 'Enter' ? this.handleDownload() : null)}
          tabIndex={0}
        >
          <i className="fa fa-download" />
        </span>
      </div>
    );
  }
}

Attachment.propTypes = propTypes;
Attachment.defaultProps = defaultProps;

export default Attachment;
