/* eslint-disable no-useless-escape */
/* eslint-disable no-console */
import { createBrowserHistory } from 'history';
import { isDev } from 'config';
import Translate from 'components/atoms/Translate';
import { errorCodeMap } from 'constant';
import moment from 'moment';
import { logApiLevel, serviceLog } from './logUtil';

export const history = createBrowserHistory();

export function consoleLog(message, type = 'log') {
  if (isDev) {
    const fn = console[type];
    if (console && fn) {
      fn(message);
    }
  }
}

export function tfvLog(message, type = logApiLevel.info) {
  if (type === logApiLevel.info) {
    consoleLog(message, 'log');
  } else if (type === logApiLevel.error) {
    serviceLog(message, type);
  }
}

export const getErrorMessage = error =>
  errorCodeMap[error.ErrorCode]
    ? Translate({
        content: `errors.${errorCodeMap[error.ErrorCode]}`,
      })
    : error.ErrorMessage || error;

export const iconForTheServiceType = (assignment = {}) => {
  let iconClass = 'phone';
  if (assignment) {
    if (assignment.IsPerformedRemotely === false) {
      iconClass = 'directions_walk';
    }
    if (assignment.IsMessageService === true) {
      iconClass = 'message';
    }
    if (assignment.IsTranslation === true) {
      iconClass = 'translate';
    }
    if (assignment.MeetingDetails.DigitalMeetingPlatformIdentifier) {
      iconClass = 'videocam';
    }
  }
  // if (service.toLowerCase().includes('tele')) {
  //   iconClass = 'phone';
  // }
  // if (service.includes('Utgående')) {
  //   iconClass = 'phone_forwarded';
  // }
  // if (service.includes('Kont')) {
  //   iconClass = 'directions_walk';
  // }
  // if (service.includes('Video')) {
  //   iconClass = 'videocam';
  // }
  // if (service.includes('Skype')) {
  //   iconClass = 'fab fa-skype';
  // }
  // if (service.includes('Simult')) {
  //   iconClass = 'hearing';
  // }
  // if (service.includes('Plat')) {
  //   iconClass = 'directions_walk';
  // }
  // if (service.includes('Skär')) {
  //   iconClass = 'videocam';
  // }
  // if (service.includes('Medd')) {
  //   iconClass = 'message';
  // }
  // if (service.includes('Översä')) {
  //   iconClass = 'translate';
  // }
  return iconClass;
};

export const validPasswordFormat = passwordStr => {
  const errorMessage = [];
  if (passwordStr === undefined || !passwordStr)
    return { isValid: true, message: '' };

  // if (passwordStr.length < 8 || passwordStr.length > 8) {
  //   return { isValid: false, message: 'exakt 8 tecken' };
  // }

  if (passwordStr.length < 8) {
    return { isValid: false, message: 'minst 8 tecken' };
  }
  if (passwordStr.length > 256) {
    return { isValid: false, message: 'max 256 tecken' };
  }
  // if (passwordStr.search(/[0-9]/) < 0) {
  //   return { isValid: false, message: 'minst en siffra' };
  // }
  // if (passwordStr.search(/[a-z]/) < 0) {
  //   return { isValid: false, message: 'minst en liten bokstav' };
  // }
  // if (passwordStr.search(/[A-Z]/) < 0) {
  //   return { isValid: false, message: 'minst en stor bokstav' };
  // }
  // if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(passwordStr)) {
  //   return { isValid: false, message: 'minst ett specialtecken' };
  // }

  return { isValid: true, message: '' };
};

export const notificationHandler = location => {
  const searchParameter = location?.search;
  let notificationId;
  if (searchParameter && searchParameter.includes('notification_id')) {
    const notificationSearchParameter = /notification_id=(.*)/gm.exec(
      searchParameter,
    ) || [null];
    // eslint-disable-next-line prefer-destructuring
    notificationId = notificationSearchParameter[1];
    return notificationId;
  }
  return null;
};

export const scrollElementTop = (
  scrollElement,
  scrollContainer,
  maxDistance = 200,
) => {
  if (scrollElement && scrollContainer) {
    // eslint-disable-next-line no-param-reassign
    scrollContainer.scrollTop =
      scrollElement.offsetTop - maxDistance < 0
        ? scrollElement.offsetTop
        : scrollElement.offsetTop - maxDistance;
  }
};

export const isNumFieldValidate = e => {
  if (e.key === '-' || e.key === '+' || e.key === 'e') {
    e.preventDefault();
    return false;
  }
  return true;
};

export const sleepTime = ms => new Promise(resolve => setTimeout(resolve, ms));
export const isMobile = {
  Android() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export const truncateFileName = (
  nameWithExt,
  expectLength,
  signal = '[...]',
) => {
  const ext = nameWithExt
    .substring(nameWithExt.lastIndexOf('.') + 1, nameWithExt.length)
    .toLowerCase();
  let filename = nameWithExt.replace(`. + ${ext}`, '');
  if (filename.length <= expectLength) {
    return nameWithExt;
  }

  filename =
    filename.substr(0, expectLength) +
    (nameWithExt.length > expectLength ? signal : '');
  return `${filename}.${ext}`;
};

export function truncateTextName(firstName, secondName, expectLength) {
  const fullText = `${firstName} - ${secondName}`;
  if (fullText.length >= expectLength) {
    return `${fullText.substring(0, expectLength)}...`;
  }
  return fullText;
}

export function checkOnGoingAssignment(item) {
  let isOnGoingStatus = false;
  let isStatusMatchForOnGoingStatus = false;
  let isReportMatchForOnGoingStatus = false;
  let isTimeMatchForOnGoingStatus = false;
  if (item.OrderStatus === 'performed' || item.OrderStatus === 'accept') {
    isStatusMatchForOnGoingStatus = true;
  }
  if (
    !item.TimeReport.DatetimeOriginalFrom &&
    !item.TimeReport.DatetimeOriginalTo
  ) {
    isReportMatchForOnGoingStatus = true;
  }
  if (
    moment(item.DatetimeOrderFrom) < moment() &&
    moment() < moment(item.DatetimeOrderTo)
  ) {
    isTimeMatchForOnGoingStatus = true;
  }
  if (
    isStatusMatchForOnGoingStatus &&
    isReportMatchForOnGoingStatus &&
    isTimeMatchForOnGoingStatus
  ) {
    isOnGoingStatus = true;
  }
  return isOnGoingStatus;
}
