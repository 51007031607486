/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Translate, DatePicker, Button, StatusFilter } from 'components';
import './style.scss';

const AssignmentFilterModal = ({
  onClose,
  onFromDatePickerChange,
  onToDatePickerChange,
  onConfirm,
  isVisible,
  selectedFromDate,
  selectedToDate,
  validationError,
}) => (
  <Modal
    mainTitle={Translate({ content: 'assignment.header' })}
    isVisible={isVisible}
    className="tv-assignment-filter-modal__container"
    onClose={onClose}
  >
    <div className="tv-assignment-filter-modal__content">
      <div className="tv-assignment-filter-modal__wrapper">
        <div className="tv-assignment-filter-modal__control">
          <label htmlFor="dateFrom">
            {Translate({ content: 'assignment.dateFrom' })}
          </label>
          <DatePicker
            selectedDate={selectedFromDate}
            minDate={new Date()}
            label="Datenum"
            name="dateFrom"
            id="dateFrom"
            onChange={onFromDatePickerChange}
          />
        </div>
        <div className="tv-assignment-filter-modal__control">
          <label htmlFor="dateTo">
            {Translate({ content: 'assignment.dateTo' })}
          </label>
          <DatePicker
            selectedDate={selectedToDate}
            minDate={new Date()}
            label="Datenum"
            name="dateTo"
            id="dateTo"
            onChange={onToDatePickerChange}
          />
        </div>
        <div className="tv-assignment-filter-modal__control">
          <StatusFilter />
        </div>
      </div>
      {!validationError.isValid && (
        <div className="tv-assignment-filter-modal__errors">
          {validationError.errorMessage}
        </div>
      )}
      <div className="tv-assignment-filter-modal__actions">
        <Button
          label={Translate({ content: 'assignment.clearFilter' })}
          className="tv-assignment-filter-modal__actions-button"
          palette="outline"
          onClick={onClose}
        />
        <Button
          label={Translate({ content: 'assignment.filter' })}
          className="tv-assignment-filter-modal__actions-button"
          onClick={onConfirm}
        />
      </div>
    </div>
  </Modal>
);

AssignmentFilterModal.propTypes = {
  onClose: PropTypes.func,
  onFromDatePickerChange: PropTypes.func,
  onToDatePickerChange: PropTypes.func,
  onConfirm: PropTypes.func,
  isVisible: PropTypes.bool,
  selectedFromDate: PropTypes.shape({}),
  selectedToDate: PropTypes.shape({}),
  validationError: PropTypes.shape({
    isValid: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),
};

AssignmentFilterModal.defaultProps = {
  onClose: () => {},
  onFromDatePickerChange: () => {},
  onToDatePickerChange: () => {},
  onConfirm: () => {},
  isVisible: false,
  selectedFromDate: new Date(),
  selectedToDate: new Date(),
  validationError: {
    isValid: true,
    errorMessage: '',
  },
};

export default AssignmentFilterModal;
