/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromResource } from 'store/selectors';
import { UserProfile, Translate, Spinner, ChangePassword } from 'components';
import { pending } from 'redux-saga-thunk';
import ResetPasswordContainer from './ResetPasswordContainer';

export const ProfileContainer = ({ profile, isReadPending }) => (
  <>
    <div className="tv-setting__panel-title tv-mobile--hide">
      <Translate content="settings.accountSettings" />
    </div>
    <div className="tv-setting__panel-title-sub tv-mobile--hide">
      <Translate content="settings.accountSettingsSub" />
    </div>
    {profile.FirstName && <UserProfile profile={profile} />}
    <div className="tv-setting__panel-title">
      <Translate content="settings.changePassword" />
    </div>
    <ResetPasswordContainer
      render={({ onSetPassword, key }) => (
        <ChangePassword onSetPassword={onSetPassword} key={key} />
      )}
    />
    {isReadPending && <Spinner />}
  </>
);
ProfileContainer.prototype = {
  profile: PropTypes.shape({}),
  isReadPending: PropTypes.bool,
};
ProfileContainer.defaultProps = {
  profile: {},
  isReadPending: false,
};
const mapStateToProps = state => {
  const userProfile = fromResource.getDetail(state, 'userProfile') || {};
  return {
    isReadPending: pending(state, 'userProfileDetailRead'),
    profile: userProfile?.Profile,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
