import React from 'react';
import './style.scss';

const Spinner = () => (
  <div className="tv-loading__wrapper">
    <div className="tv-loading__content">
      <div className="tv-loading__spinner" />
    </div>
  </div>
);

export default Spinner;
