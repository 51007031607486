/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGetAttachment } from 'hooks';
import { Button, Translate } from 'components';

import './style.scss';
import { downloadFile } from 'utils/fileUtils';
import { clean } from 'redux-saga-thunk';

const AttachedFile = ({
  file,
  displayText,
  onClick,
  visibleRemoveButton,
  onRemoveFile,
}) => {
  const {
    GetAttachment,
    downloadedFile,
    isDownloadsuccess,
    isDownloadfailed,
    addToast,
    dispatch,
  } = useGetAttachment();

  useEffect(() => {
    if (
      isDownloadsuccess &&
      downloadedFile?.AttachmentIdentifier ===
        file?.AttachmentMetadata?.AttachmentReference
    ) {
      downloadFile(
        downloadedFile.AttachmentContentType,
        downloadedFile.AttachmentFile,
        downloadedFile.AttachmentFilename,
      );
      dispatch(clean(`GetAttachmentListRead`));

      addToast(Translate({ content: 'file.downloadSuccess' }), {
        appearance: 'success',
      });
    }
  }, [isDownloadsuccess, isDownloadfailed]);

  const handleDownload = () => {
    if (file && file.AttachmentMetadata) {
      GetAttachment({
        AttachmentIdentifier: file.AttachmentMetadata.AttachmentReference,
      });
    }
  };
  return (
    <div className="tv-attachedFile__container" onClick={handleDownload}>
      <div className="tv-attachedFile__amount">{file.AmountWithVAT} kr</div>
      <div className="tv-attachedFile__content">
        <i className="material-icons">image</i>

        <div className="tv-attachedFile__filename" title={displayText}>
          {displayText}
        </div>
      </div>

      {visibleRemoveButton && (
        <Button
          onClick={e => {
            e.preventDefault();
            onRemoveFile();
          }}
          palette="text"
          label="Ta bort"
        />
      )}
    </div>
  );
};

AttachedFile.propTypes = {
  file: PropTypes.shape({}),
  displayText: PropTypes.string,
  onClick: PropTypes.func,
  visibleRemoveButton: PropTypes.bool,
  onRemoveFile: PropTypes.func,
};

AttachedFile.defaultProps = {
  file: {},
  displayText: 'Bifogat kvitto',
  onClick: () => {},
  visibleRemoveButton: false,
  onRemoveFile: () => {},
};
export default AttachedFile;
