/* eslint-disable react/prop-types */
import React from 'react';

const ContactCard = ({
  addressLine,
  imageUrl,
  place,
  addressCityPostCode,
  customerServiceNumber,
}) => (
  <>
    <div className="w-100 mr-md-3 mr-0">
      <div className="generic_card">
        <div className="contact_image">
          <img src={imageUrl} alt={place} />
        </div>
        <div className="d-flex flex-column">
          <div className="contact_city">{place}</div>
          <div className="contact_address_wrapper">
            <div className="contact_address_wrapper_element">{addressLine}</div>
            <div className="contact_address_wrapper_element">{addressCityPostCode}</div>
          </div>
          <div className="contact_method_wrapper">
            <div className="mr-2 contact_method_wrapper-name">Kundtjänst:</div>
            <div className="contact_method_wrapper-number">
              <a href={`tel:${customerServiceNumber}`}>
                {customerServiceNumber}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default ContactCard;
