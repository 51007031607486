import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'components';

import './style.scss';

function AssignmentCancelModal({
  isVisible,
  onOverlayClick,
  onClick,
  onClose,
}) {
  return (
    <Modal
      className="tv-assignment-cancel-modal__container"
      hideHeader
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      isVisible={isVisible}
    >
      <p>
        Har något inträffat så du inte kan genomföra ditt accepterade uppdrag?
      </p>
      <p>
        Kontakta Tolkförmedling Väst på <b>0770-813-000</b>
      </p>
      <p>Du kan även chatta med oss.</p>

      <Button
        onClick={onClick}
        className="tv-assignment-cancel-modal__button"
        label="Ok"
        aria-label="okButton"
      />
    </Modal>
  );
}

AssignmentCancelModal.propTypes = {
  isVisible: PropTypes.bool,
  onOverlayClick: PropTypes.func,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};
AssignmentCancelModal.defaultProps = {
  isVisible: false,
  onOverlayClick: () => {},
  onClose: () => {},
  onClick: () => {},
};

export default AssignmentCancelModal;
