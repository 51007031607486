const showChat = () => {
  //   var Tawk_API = Tawk_API || {},
  //     Tawk_LoadStart = new Date();
  (function () {
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/5e298ea5daaca76c6fcf7e7b/default';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
};

export default showChat;
