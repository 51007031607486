/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Translate, FilterItem } from 'components';
import { status } from 'constant';

const StatusFilter = props => {
  const { onClick, selectedFilters } = props;

  const [selectedFilter, setSelectedFilter] = useState([selectedFilters]);

  let selectedStatusItems = selectedFilter;

  useEffect(() => {
    setSelectedFilter(selectedFilters);
  }, [selectedFilters]);

  const handleFilterChange = (event, value) => {
    // event.preventDefault();
    if (selectedStatusItems.includes(value)) {
      const index = selectedStatusItems.indexOf(value);
      selectedStatusItems.splice(index, 1);
      setSelectedFilter(selectedStatusItems);
    } else {
      selectedStatusItems.push(value);
      setSelectedFilter(selectedStatusItems);
    }

    if (value !== status.all.name) {
      if (!selectedStatusItems.length) {
        selectedStatusItems.push(status.all.name);
        setSelectedFilter(selectedStatusItems);
      } else {
        const index = selectedStatusItems.indexOf(status.all.name);
        if (index !== -1) {
          selectedStatusItems.splice(index, 1);
          setSelectedFilter(selectedStatusItems);
        }
      }
    } else {
      const index = selectedStatusItems.indexOf(value);
      if (index !== -1) {
        selectedStatusItems = [status.all.name];
        setSelectedFilter(selectedStatusItems);
      }
    }
    if (!selectedStatusItems.length) {
      onClick([], 'status');
    } else {
      onClick(selectedStatusItems, 'status');
    }
  };

  const setAllItems = () => {
    if (selectedStatusItems.includes(status.all.name)) {
      selectedStatusItems = [
        // status.reported.name,
        status.accept.name,
        status.performed.name,
        status.cancelled.name,
        status.inquiry.name,
      ];
      setSelectedFilter(selectedStatusItems);
    } else {
      selectedStatusItems = [status.all.name];
      setSelectedFilter(selectedStatusItems);
    }
    if (!selectedStatusItems.length) {
      onClick([], 'status');
    } else {
      onClick(selectedStatusItems, 'status');
    }
  };

  const checkItem = item => selectedFilter.includes(item);

  return (
    <div className="tv-status-filter">
      <div className="tv-status-filter__title__wrapper">
        <div className="tv-status-filter__title">
          {Translate({ content: 'status.title' })}
        </div>
        <div
          className="sub"
          onClick={setAllItems}
          tabIndex={0}
          role="button"
          onKeyDown={e =>
            e.key === ' ' || e.key === 'Enter' ? setAllItems(e) : null
          }
        >
          {selectedStatusItems.includes(status.all.name)
            ? Translate({ content: 'status.allStatus' })
            : Translate({ content: 'status.clearAll' })}
        </div>
      </div>
      <div className="tv-status-filter__area filter-border">
        <>
          <FilterItem
            onClick={(event, value) => {
              handleFilterChange(event, value);
            }}
            text={Translate({
              content: 'status.inquiry',
            })}
            controlClassName={`tv-checkbox__control-${status.inquiry.name}`}
            labelClassName={`tv-checkbox__label-${status.inquiry.name}`}
            value={status.inquiry.name}
            textColor={status.inquiry.color}
            labelColor="#FFF"
            checked={checkItem(status.inquiry.name)}
          />
          <FilterItem
            onClick={(event, value) => {
              handleFilterChange(event, value);
            }}
            text={Translate({
              content: 'status.accept',
            })}
            controlClassName={`tv-checkbox__control-${status.accept.name}`}
            labelClassName={`tv-checkbox__label-${status.accept.name}`}
            value={status.accept.name}
            textColor={status.accept.color}
            labelColor="#FFF"
            checked={checkItem(status.accept.name)}
          />
          <FilterItem
            onClick={(event, value) => {
              handleFilterChange(event, value);
            }}
            text={Translate({
              content: 'status.performed',
            })}
            controlClassName={`tv-checkbox__control-${status.performed.name}`}
            labelClassName={`tv-checkbox__label-${status.performed.name}`}
            value={status.performed.name}
            textColor={status.performed.color}
            labelColor="#FFF"
            checked={checkItem(status.performed.name)}
          />
          <FilterItem
            onClick={(event, value) => {
              handleFilterChange(event, value);
            }}
            text={Translate({
              content: 'status.cancelled',
            })}
            controlClassName={`tv-checkbox__control-${status.cancelled.name}`}
            labelClassName={`tv-checkbox__label-${status.cancelled.name}`}
            value={status.cancelled.name}
            textColor={status.cancelled.color}
            labelColor="#FFF"
            checked={checkItem(status.cancelled.name)}
          />

          {/* <FilterItem
            onClick={(event, value) => {
              handleFilterChange(event, value);
            }}
            text={Translate({
              content: 'status.reported',
            })}
            controlClassName={`tv-checkbox__control-${status.reported.name}`}
            labelClassName={`tv-checkbox__label-${status.reported.name}`}
            value={status.reported.name}
            textColor={status.reported.color}
            labelColor="#FFF"
            checked={checkItem(status.reported.name)}
          /> */}
        </>
      </div>
    </div>
  );
};

StatusFilter.propTypes = {
  onClick: PropTypes.func,
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
};
StatusFilter.defaultProps = {
  onClick: () => {},
  selectedFilters: [
    // status.reported.name,
    status.accept.name,
    status.performed.name,
    status.cancelled.name,
    status.inquiry.name,
  ],
};

export default StatusFilter;
