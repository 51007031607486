import React from 'react';
import PropTypes from 'prop-types';
import { CurrentTime, Translate } from 'components';
import './style.scss';

function WelcomeMessage({ customerName, scheduleMessage }) {
  return (
    <div className="tv-welcome-message">
      <div className="tv-welcome-message__text-wrapper">
        <div className="tv-welcome-message__text-area">
          <div className="tv-welcome-message__text">
            {Translate({ content: 'welcome.message' })}
          </div>
          <div className="tv-welcome-message__customer-name">
            {customerName}!
          </div>
        </div>
        <div className="tv-welcome-message__schedule-message">
          {scheduleMessage}
        </div>
      </div>
      <CurrentTime className="tv-welcome-message__time" />
    </div>
  );
}

WelcomeMessage.propTypes = {
  customerName: PropTypes.string,
  scheduleMessage: PropTypes.string,
};
WelcomeMessage.defaultProps = {
  customerName: 'Sara',
  scheduleMessage: 'Din schemalagda arbetstid idag är',
};

export default WelcomeMessage;
