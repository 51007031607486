/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { convertToDayJsObject, localDayjs } from 'utils/dateTimeUtils';
import {
  Button,
  StarRating,
  AssignmentCancelModal,
  AssignmentContactModal,
  AssignmentType,
  AssignmentStatus,
  AssignmentCustomerName,
  AssignmentNavigator,
} from 'components';
import { AssignmentTimeReportEditContainer } from 'containers';
import './style.scss';
import moment from 'moment';
import axios from 'axios';
import { checkOnGoingAssignment, tfvLog } from 'utils/miscUtil';
import { apiUrl } from 'config';
import FocusTrap from 'focus-trap-react';
import Attachment from '../Attachment';

function formatAddress(addressText, isDeviating) {
  return isDeviating ? `OBS! ${addressText}` : addressText;
}

function TranslationOverviewContainer({
  item,
  assignmentId,
  children,
  onClickBack,
  onClickClose,
  NavigationClickHandler,
  onAssignmentEarlyCompleted,
}) {
  const overViewRef = useRef(null);
  useEffect(() => {
    const originalTitle = document.title;
    document.title = `${assignmentId} - TFV Tolkportal`;
    return () => {
      document.title = originalTitle;
    };
  }, [assignmentId]);
  return (
    <FocusTrap
      focusTrapOptions={{
        escapeDeactivates: false,
        allowOutsideClick: true,
      }}
    >
      <div>
        <div
          className="tv-assignment-overview"
          ref={overViewRef}
          aria-live="polite"
        >
          <AssignmentNavigator
            onClickPrevious={() => {
              NavigationClickHandler('previous');
            }}
            onClickNext={() => {
              NavigationClickHandler('next');
            }}
            className="tv-mobile--hide"
          />
          <div className="tv-assignment-overview__outer-container">
            <div className="tv-assignment-overview__outer-container__header">
              <Button
                onClick={onClickBack}
                className="back-icon"
                palette="text"
                label={<i className="fa fa-chevron-left" />}
                aria-label="tillbakaknapp"
                tabIndex="-1"
                aria-hidden="true"
              />
              <div className="assignment-number">{assignmentId}</div>
              <Button
                onClick={onClickClose}
                className="close-icon"
                palette="text"
                label={<i className="fa fa-times" />}
                aria-label="stäng knapp"
              />
            </div>
            <hr />
            <div className="tv-assignment-overview__outer-container__wrapper">
              {children}
            </div>
            <AssignmentNavigator
              onClickPrevious={() => {
                NavigationClickHandler('previous');
              }}
              onClickNext={() => {
                NavigationClickHandler('next');
              }}
              className="tv-assignment-overview__mobile-navigator"
            />
          </div>
        </div>
      </div>
    </FocusTrap>
  );
}

function AssignmentOverview({
  item,
  onClickClose,
  onHideAssignment,
  onAccept,
  onReject,
  NavigationClickHandler,
  onAssignmentEarlyCompleted,
}) {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showTimeSheetModal, setShowTimeSheetModal] = useState(false);
  const [disableFinishedEarly, setDisableFinishedEarly] = useState(true);
  const [statusColor, setStatusColor] = useState(item.OrderStatus);

  const { token } = useSelector(state => state.authenticate);

  const modifiedStartTime = convertToDayJsObject(item.DatetimeOrderFrom).add(
    7,
    'minute',
  );

  let countdown = null;

  const checkCurrentTimePassedStartTime = () => {
    const currentTime = localDayjs();
    if (currentTime.isAfter(modifiedStartTime)) {
      setDisableFinishedEarly(false);
      clearInterval(countdown);
    }
  };
  useEffect(() => {
    if (item.OrderStatus === 'accept') {
      countdown = setInterval(checkCurrentTimePassedStartTime, 10000);
    }
    return countdown;
  }, []);

  let timer;
  useEffect(() => {
    const updateStatusColor = () => {
      const isOnGoingStatus = checkOnGoingAssignment(item);
      if (isOnGoingStatus) {
        if (statusColor !== 'onGoing') {
          setStatusColor('onGoing');
        }
      } else {
        setStatusColor(item.OrderStatus);
      }
      timer = setTimeout(updateStatusColor, 5000);
    };
    updateStatusColor();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [item]);

  const getAttachmentCustomer = attachmentIdentifier => {
    axios
      .post(`${apiUrl}GetAttachment`, {
        Token: token,
        AttachmentIdentifier: attachmentIdentifier,
      })
      .then(response => {
        if (response.data && !response.data.Errors) {
          const contentType = response.data.Attachment.AttachmentContentType; // application/pdf, application/doc ...
          const base64Content = response.data.Attachment.AttachmentFile;
          const linkSource = `data:${contentType};base64,${base64Content}`;
          const fileName = response.data.Attachment.AttachmentFilename; // foo.pdf, bar.doc ...

          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          tfvLog(response.data.Errors, logApiLevel.error);
        }
      })
      .catch(err => {
        tfvLog(err, logApiLevel.error);
      });
  };

  const attachments = item.Attachments || [];

  const attachmentElement = attachments.map(attach => (
    <Attachment
      key={attach.AttachmentReference}
      attachmentId={attach.AttachmentReference}
      fileName={attach.AttachmentFilename}
      type={attach.AttachmentContentType}
      onGetAttachment={getAttachmentCustomer}
    />
  ));

  return (
    <>
      <TranslationOverviewContainer
        item={item}
        NavigationClickHandler={NavigationClickHandler}
        assignmentId={item.OrderNumber}
        onClickBack={() => {
          clearInterval(countdown);
          onClickClose();
        }}
        onClickClose={() => {
          clearInterval(countdown);
          onClickClose();
        }}
      >
        <div className="tv-assignment-overview__container">
          <div className="tv-assignment-overview__content">
            <AssignmentOverviewProperty className="tv-assignment-overview__assignment-type">
              <AssignmentCustomerName
                serviceName={item.ArticleName}
                status={item.OrderStatus}
                customerName={item.Customer?.CustomerName}
                isColoredIcon
                assignment={item}
              />
              <AssignmentStatus status={statusColor} />
            </AssignmentOverviewProperty>
          </div>
          <hr />
          <div className="tv-assignment-overview__content">
            <AssignmentOverviewProperty
              propertyText={item.ModeOfTransport}
              propertyIcon="account_tree"
            />
            <AssignmentOverviewProperty
              propertyText={moment(item.DateDelivery).format('YYYY-MM-DD')}
              propertyIcon="calendar_today"
            />
            <AssignmentOverviewProperty
              propertyIcon="language"
              propertyText={`Källspråk : ${item.Skill}`}
            />
            <AssignmentOverviewProperty
              propertyText={`Målspråk : ${item.SkillTarget}`}
              propertyIcon="translate"
            />
          </div>
          <hr />
          <div className="tv-assignment-overview__content">
            <AssignmentOverviewProperty>
              <AssignmentType
                serviceName={item.ArticleName}
                status={item.OrderStatus}
                isColoredIcon={false}
                className="tv-assignment-overview__assignment-type--small"
                assignment={item}
              />
            </AssignmentOverviewProperty>
            {/* <AssignmentOverviewProperty
              isDisplay={
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed' ||
                  item.OrderStatus === 'reported') &&
                item.CustomerReference
              }
              propertyIcon="assignment"
              propertyText={`Bokningsreferens: ${item.CustomerReference}`}
            /> */}
            {/* <AssignmentOverviewProperty
              isDisplay={
                item.OrderStatus === 'inquiry' ||
                item.OrderStatus === 'accept' ||
                item.OrderStatus === 'performed' ||
                item.OrderStatus === 'reported'
              }
              propertyIcon="perm_contact_calendar"
              propertyText={`Tolkanvändare: ${item.ContactPerson?.ContactPersonName}`}
            /> */}
          </div>

          <hr />
          <div className="tv-assignment-overview__content">
            <AssignmentOverviewProperty>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  marginBottom: '10px',
                  fontFamily: 'Source Sans Pro',
                  fontSize: '13px',
                  fontWeight: '400',
                }}
              >
                {attachmentElement}
              </div>
            </AssignmentOverviewProperty>
          </div>
        </div>
      </TranslationOverviewContainer>
      {showCancelModal && (
        <AssignmentCancelModal
          isVisible={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onClick={() => setShowCancelModal(false)}
        />
      )}
      {showContactModal && (
        <AssignmentContactModal
          isVisible={showContactModal}
          onClose={() => setShowContactModal(false)}
          onClick={() => setShowContactModal(false)}
        />
      )}
      {showTimeSheetModal && (
        <AssignmentTimeReportEditContainer
          item={item}
          isVisible={showTimeSheetModal}
          isEdit={item.CanBeTimeReported}
          onClose={() => setShowTimeSheetModal(false)}
        />
      )}
    </>
  );
}

AssignmentOverview.propTypes = {
  item: PropTypes.shape({}),
  onClickClose: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  NavigationClickHandler: PropTypes.func,
  onHideAssignment: PropTypes.func,
  onAssignmentEarlyCompleted: PropTypes.func,
};
AssignmentOverview.defaultProps = {
  item: {},
  onClickClose: () => {},
  onAccept: () => {},
  onReject: () => {},
  NavigationClickHandler: () => {},
  onHideAssignment: () => {},
  onAssignmentEarlyCompleted: () => {},
};

export default AssignmentOverview;

const AssignmentOverviewProperty = ({
  children,
  isDisplay = true,
  propertyIcon,
  propertyText,
  className = '',
  isLink = false,
  isLabelled = false,
}) =>
  isDisplay && (
    <div
      className={`tv-assignment-overview__content-area ${
        isLabelled && 'labelled'
      } ${className}`}
    >
      {!children ? (
        <div className="tv-assignment-overview__text" title={propertyText}>
          <i className="material-icons">{propertyIcon}</i>
          {!isLink ? (
            <span className="assignment-label">{propertyText}</span>
          ) : (
            <a href={propertyText} target="__blank">
              {propertyText}
            </a>
          )}
        </div>
      ) : (
        children
      )}
    </div>
  );
