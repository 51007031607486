import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function MonthAssignmentPill({ countAssignment, onClick, isActive, date }) {
  return (
    <button
      // style={{ zIndex: 1002 }}
      type="button"
      className={`tv-month_assignment_pill ${
        isActive && 'tv-month_assignment_pill--active'
      }`}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClick(date);
      }}
    >{`${countAssignment} uppdrag`}</button>
  );
}

MonthAssignmentPill.propTypes = {
  countAssignment: PropTypes.number,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  date: PropTypes.shape({}),
};
MonthAssignmentPill.defaultProps = {
  countAssignment: 0,
  onClick: () => {},
  isActive: false,
  date: {},
};

export default MonthAssignmentPill;
