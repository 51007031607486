import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { IconButton } from 'components';

const LogoLink = ({ className }) => (
  <div>
    <NavLink to="/">
      <IconButton className={className} iconName="bell" />
    </NavLink>
  </div>
);

LogoLink.propTypes = {
  className: PropTypes.string,
};
LogoLink.defaultProps = {
  className: '',
};

export default LogoLink;
