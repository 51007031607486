/* eslint-disable react/prop-types */
import React from 'react';
import './style.scss';

export default function SemesterColumn({
  itemClassName,
  onItemClick,
  semester,
  isSemesterStartDay = false,
  singleDay = false,
}) {
  return (
    <div className="listClassName tv-week-day-semester__container">
      <>
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          className="tv-week-day-semester__slot"
          role="button"
          tabIndex="0"
          onClick={() => onItemClick(semester)}
          onKeyPress={() => onItemClick(semester)}
          style={{
            top: 0,
            height: '100%',
            backgroundColor: '#F1E8EB',
            zIndex: '4',
          }}
        >
          <div className="tv-week-view-assignment-item__container tv-week-view-assignment-item__container--availablity tv-week-view-assignment-item--availablity">
            <div className="tv-week-view-assignment-item__header">
              <div className="tv-week-view-assignment-item__header__name">
                Semester
              </div>
            </div>
            <div className="tv-week-view-assignment-item__sub-container">
              <div className="flex-column tv-week-view-assignment-item__sub-item semester-info">
                {(isSemesterStartDay || singleDay) && (
                  <>
                    <span className="tv-week-view-assignment-item__sub-item__value--text">
                      {semester.FirstDayOfVacationPeriod}
                    </span>
                    <span className="tv-week-view-assignment-item__sub-item__value--text">
                      {semester.LastDayOfVacationPeriod}
                    </span>
                    <div
                      className={`icon-label ${
                        singleDay ? 'icon-label--single-day' : ''
                      }`}
                    >
                      <div>Förfrågningar:</div>
                      <i
                        className={`fa fa-${
                          semester.DoAllowResourceRequests
                            ? 'check-square'
                            : 'square'
                        }`}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
