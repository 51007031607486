/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MonthAssignmentPill } from 'components';
import './style.scss';
import { isEqual } from 'lodash';
import { currentDate } from 'utils/dateTimeUtils';

class MonthDay extends Component {
  shouldComponentUpdate(nextProps) {
    const { isActive, eventsForDay, semesterDay } = this.props;

    if (!isEqual(eventsForDay, nextProps.eventsForDay)) {
      return true;
    }

    if (!isEqual(semesterDay, nextProps.semesterDay)) {
      return true;
    }

    if (isActive !== nextProps.isActive) {
      return true;
    }
    return false;
  }

  render() {
    const {
      col,
      handleAssignmentPillClick,
      onActiveSemesterAllocationClick,
      isActive,
      eventsForDay,
      semesterDay,
      date,
    } = this.props;
    return (
      <td className="tv-month-day" style={{ position: 'relative' }}>
        <div className="tv-month-day__date-wrapper">
          <div className="tv-month-day__week-number tv-month-day__zIndex">
            {col === 0 && date.isoWeek()}
          </div>
          <div className="d-sm-none d-none d-md-block d-md-block-none-portrait d-lg-block tv-month-day__zIndex">
            {date.format('D')}
          </div>
          <div className="d-lg-none d-md-block  d-md-block-none-landscape d-sm-block tv-month-day__zIndex">
            <span className="tk-monthly-calendar__item-text">
              {date.format('dddd, D MMM')}
            </span>
          </div>
        </div>

        {eventsForDay.length > 0 && (
          <MonthAssignmentPill
            countAssignment={eventsForDay.length}
            date={date}
            isActive={isActive}
            onClick={d => handleAssignmentPillClick(d, eventsForDay)}
          />
        )}
        {semesterDay.isSemesterDay && (
          <div
            className="tv-month-day__semester-container"
            role="button"
            tabIndex={0}
            onClick={() => onActiveSemesterAllocationClick(semesterDay.data)}
            style={{
              position: 'absolute',
              backgroundColor: '#F1E8EB',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 10,
              cursor: 'pointer',
            }}
          >
            <div className="tv-month-day__semester-container-header">
              <div className="tv-month-day__semester-container-header--name">
                Semester
              </div>
            </div>
            <div className="tv-month-day__semester-container-sub-container">
              {semesterDay.isSemesterStartDay && (
                <>
                  <span>{semesterDay.data.FirstDayOfVacationPeriod}</span>
                  <span>{semesterDay.data.LastDayOfVacationPeriod}</span>
                  <div className="icon-label">
                    <div>Förfrågningar:</div>
                    <i
                      className={`fa fa-${
                        semesterDay.data.DoAllowResourceRequests
                          ? 'check-square'
                          : 'square'
                      }`}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </td>
    );
  }
}

MonthDay.propTypes = {
  col: PropTypes.number,
  handleAssignmentPillClick: PropTypes.func,
  isActive: PropTypes.bool,
  eventsForDay: PropTypes.arrayOf(PropTypes.shape({})),
  date: PropTypes.shape({}),
  semesterDay: PropTypes.shape({
    isSemesterDay: PropTypes.bool,
    data: PropTypes.shape({}),
  }),
  onActiveSemesterAllocationClick: PropTypes.func,
};

MonthDay.defaultProps = {
  col: 0,
  handleAssignmentPillClick: () => {},
  isActive: false,
  eventsForDay: [],
  date: currentDate(),
  semesterDay: { isSemesterDay: false, data: {} },
  onActiveSemesterAllocationClick: () => {},
};

export default MonthDay;
