/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Translate, TimePicker, Button } from 'components';
import { currentDate, convertToDayJsObject } from 'utils/dateTimeUtils';
import './style.scss';
import { NavLink } from 'react-router-dom';

const AvailabilityNowModal = ({
  onClose,
  onConfirm,
  isVisible,
  openingHours,
  autoAssignIsActive,
  autoAssignSet,
}) => {
  const start = currentDate();
  const [availableTo, setAvailableTo] = useState(start);
  const [isError, setIsError] = useState(false);
  const [endTimeTo, setEndTimeTo] = useState();
  const [startTime, setStartTime] = useState();
  const [originalTitle, setOriginalTitle] = useState(document.title);
  useEffect(() => {
    const newTitle = 'Akut tolk - TFV Tolkportal';
    if (isVisible) {
      document.title = newTitle;
    } else {
      document.title = originalTitle;
    }

    return () => {
      document.title = originalTitle;
    };
  }, [isVisible, originalTitle]);
  useEffect(() => {
    // const remainder = 30 - (start.minute() % 30);
    // const dateTime = convertToDayJsObject(start).add(remainder + 15, 'minutes');
    const remainder = 15 - (start.minute() % 15);
    let dateTime;
    let endTime;
    if (remainder < 15) {
      dateTime = convertToDayJsObject(start).add(remainder + 30, 'minutes');
      endTime = convertToDayJsObject(start)
        .add(24, 'hours')
        .add(remainder, 'minutes');
    } else {
      dateTime = convertToDayJsObject(start).add(30, 'minutes');
      endTime = convertToDayJsObject(start).add(24, 'hours').add(15, 'minutes');
    }
    setAvailableTo(dateTime);
    setEndTimeTo(endTime);
    setStartTime(dateTime);
  }, [isVisible]);

  useEffect(() => {
    if (availableTo < startTime || currentDate() > startTime) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [availableTo]);
  const timePickerHandler = date => {
    setAvailableTo(date);
  };

  const timeHandler = date => {
    setAvailableTo(date);
  };

  const onSubmit = () => {
    if (!isError) {
      onConfirm(availableTo);
    }
  };

  if (
    currentDate() < openingHours.from ||
    currentDate() > openingHours.to ||
    !autoAssignIsActive
  ) {
    return (
      <Modal
        mainTitle={Translate({ content: 'availabilityNow.modalTitle' })}
        isVisible={isVisible}
        className="tv-availability-now-modal__container--empty"
        onClose={onClose}
      >
        <div className="tv-availability-now-modal__content--empty">
          <p className="tv-availability-now-modal__text">
            Det är ej möjligt att använda verktyget TolkaDirekt vid denna
            tidpunkt.
          </p>
          <br />

          <p className="tv-availability-now-modal__text">
            Öppettiderna är i dagsläget
            <b>
              {'  '}
              {`${openingHours.from.format('HH:mm')}-${openingHours.to.format(
                'HH:mm',
              )}.`}
            </b>
          </p>

          <div className="tv-availability-now-modal__actions">
            <Button
              label={Translate({ content: 'availabilityNow.cancel' })}
              className="tv-availability-now-modal__actions-button"
              palette="outline"
              onClick={onClose}
            />
          </div>
        </div>
      </Modal>
    );
  }
  // if (!autoAssignSet || !autoAssignIsActive) {
  //   return (
  //     <Modal
  //       mainTitle={Translate({ content: 'availabilityNow.title' })}
  //       isVisible={isVisible}
  //       className="tv-availability-now-modal__container--empty"
  //       onClose={onClose}
  //     >
  //       <div className="tv-availability-now-modal__content--empty">
  //         <p className="tv-availability-now-modal__text">
  //           Det är ej möjligt att använda verktyget TolkaDirekt vid denna
  //           tidpunkt.
  //         </p>
  //         <br />
  //         <p className="tv-availability-now-modal__text">
  //           Öppettiderna är i dagsläget
  //           <b>
  //             {'  '}
  //             mån-fre {'  '}
  //             {`${openingHours.from.format('HH:mm')}-${openingHours.to.format(
  //               'HH:mm',
  //             )}`}
  //           </b>
  //         </p>
  //         <div className="tv-availability-now-modal__actions">
  //           <Button
  //             label={Translate({ content: 'availabilityNow.cancel' })}
  //             className="tv-availability-now-modal__actions-button"
  //             palette="outline"
  //             onClick={onClose}
  //           />
  //         </div>
  //       </div>
  //     </Modal>
  //   );
  // }

  return (
    <Modal
      mainTitle={Translate({ content: 'availabilityNow.modalTitle' })}
      isVisible={isVisible}
      className="tv-availability-now-modal__container"
      onClose={onClose}
    >
      <>
        <div className="tv-availability-now-modal__content">
          <p className="tv-availability-now-modal__text">
            Akut tolk är en telefontolkning med automattillsättning utan
            förfrågan. Du gör dig tillgänglig för akuta uppdrag från och med nu
            och fram till den sluttid du väljer.
          </p>
          <p className="tv-availability-now-modal__text mt-2">
            När du blir automattillsatt på ett uppdrag får du en notis och kan
            se uppdraget i portal och app.
          </p>
          <p className="tv-availability-now-modal__text mt-2">
            Du kan när du önskar stänga av din tillgänglighet för Akut tolk,
            dock inte när du är tillsatt på ett uppdrag.
          </p>
          {/* <br /> */}
          {/* <p className="tv-availability-now-modal__text">
            TolkaDirekt telefontolkning, öppettider{' '}
            <b>
              {' '}
              {openingHours.from.format('DD MMMM')}{' '}
              {openingHours.from.format('HH:mm')}
            </b>{' '}
            <b>
              {' '}
              {`- ${openingHours.to.format('HH:mm')} ${openingHours.to.format(
                'DD MMMM',
              )}`}
            </b>
          </p> */}
          <hr className="tv-availability-now-modal__seperator" />

          <div className="tv-availability-now-modal__form">
            <div className="tv-availability-now-modal__field_group">
              <p className="tv-availability-now-modal__field__label">
                Jag är tillgänglig nu fram till
              </p>
              <TimePicker
                className="tv-availability-now-modal__field"
                defaultValue={availableTo}
                onChange={timePickerHandler}
                minuteStep={15}
                maxTime={endTimeTo}
                timeHandler={timeHandler}
                checkAvailability
                label="Jag är tillgänglig nu fram till"
              />
            </div>
            {availableTo < startTime && (
              <div className="tv-availability-now-modal__error">
                *Välj en framtida tidsram
              </div>
            )}
            {/* {openingHours.to < availableTo.subtract(1, 'minute') && (
              <div className="tv-availability-now-modal__error">
                *Välj en sluttid som är inom öppettiderna
              </div>
            )} */}
            <p
              className="tv-availability-now-modal__text-new"
              style={{ marginBottom: '0px' }}
            >
              *Minst 30 min tillgänglig tid.
            </p>
            <p
              style={{
                fontSize: '14px',
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              *Max tillgänglig tid 24 timmar i taget.
            </p>
          </div>

          <div className="tv-availability-now-modal__actions">
            <Button
              label={Translate({ content: 'availabilityNow.cancel' })}
              className="tv-availability-now-modal__actions-button"
              palette="outline"
              onClick={onClose}
            />
            <Button
              label={Translate({ content: 'availabilityNow.submit' })}
              className="tv-availability-now-modal__actions-button"
              onClick={onSubmit}
              disabled={isError}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};

AvailabilityNowModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isVisible: PropTypes.bool,
  selectedFromDate: PropTypes.shape({}),
  selectedToDate: PropTypes.shape({}),
  openingHours: PropTypes.shape({
    from: PropTypes.shape({}),
    to: PropTypes.shape({}),
  }),
};

AvailabilityNowModal.defaultProps = {
  onClose: () => {},
  onConfirm: () => {},
  isVisible: false,
  selectedFromDate: new Date(),
  selectedToDate: new Date(),
  openingHours: {
    from: currentDate().startOf('day').add(7, 'hours').add(30, 'minutes'),
    to: currentDate().startOf('day').add(19, 'hours'),
  },
};

export default AvailabilityNowModal;
