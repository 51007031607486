/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const isItemInSelectedList = (item, list) => {
  const filtered = list.filter(x => x.key === item.key);
  return filtered.length > 0;
};

const MultiSelectDropdown = ({
  options,
  onSelect,
  defaultOption,
  placeholder,
  selectList,
}) => {
  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    setSelected(defaultOption);
  }, []);

  useEffect(() => {
    if (selectList.length > 0) setSelected(selectList);
  }, [selectList]);

  const handleToogle = () => {
    setIsOpen(!isOpen);
  };

  const handleBlur = event => {
    const { currentTarget } = event;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setIsOpen(false);
      }
    }, 250);
  };

  return (
    <>
      <div
        role="button"
        tabIndex="-1"
        className="tv-multi-select__container"
        onBlur={handleBlur}
        ref={selectRef}
      >
        <label>Min tillgänglighet</label>
        <div className="tv-multi-select__display-container">
          <SelectorDisplay
            selected={selected}
            placeholder={placeholder}
            toogle={handleToogle}
          />
          <SelctorDropDownToogle toogle={handleToogle} isOpen={isOpen} />
          <ul
            className={`tv-multi-select__drop-down-container ${
              isOpen && 'tv-multi-select__drop-down--open'
            }`}
          >
            {options.map(item => (
              <OptionItem
                key={item.key}
                selectList={selectList}
                item={item}
                onSelect={onSelect}
                setIsOpen={setIsOpen}
              />
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

MultiSelectDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      displayText: PropTypes.string.isRequired,
    }),
  ),
  defaultOption: PropTypes.shape({
    displayText: PropTypes.string,
  }),
  selectList: PropTypes.arrayOf(
    PropTypes.shape({
      displayText: PropTypes.string,
    }),
  ),
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
};

MultiSelectDropdown.defaultProps = {
  options: [],
  defaultOption: null,
  selectList: [],
  onSelect: () => {},
  placeholder: '',
};

export default MultiSelectDropdown;

const OptionItem = ({ selectList, item, onSelect, setIsOpen }) => (
  <li
    className={`tv-multi-select__drop-down-item ${
      selectList &&
      isItemInSelectedList(item, selectList) &&
      'tv-multi-select__drop-down-item--selected'
    }`}
    key={item.key}
    onClick={() => {
      onSelect(item);
      setTimeout(() => {
        setIsOpen(false);
      }, 500);
    }}
  >
    {selectList && isItemInSelectedList(item, selectList) && (
      <span className="material-icons">check</span>
    )}{' '}
    <span>{item.displayText}</span>
  </li>
);

const SelectorDisplay = ({ placeholder, toogle }) => (
  <span
    className="tv-multi-select__display tv-multi-select__display--default"
    onClick={toogle}
  >
    {placeholder}
  </span>
);

const SelctorDropDownToogle = ({ toogle, isOpen }) => (
  <div
    className="tv-multi-select__display-icon material-icons"
    onClick={toogle}
  >
    {!isOpen ? 'arrow_drop_down' : 'arrow_drop_down'}
  </div>
);
