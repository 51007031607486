import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Logo } from 'components';

const LogoLink = ({ to }) => (
  <div>
    <NavLink to={to}>
      <Logo />
    </NavLink>
  </div>
);

LogoLink.propTypes = {
  to: PropTypes.string,
};
LogoLink.defaultProps = {
  to: '',
};

export default LogoLink;
