import React from 'react';
import PropTypes from 'prop-types';
import { Button, AvailablitySlot, Translate } from 'components';

import './style.scss';

function DefaultAvailablity({
  onCopyTimeToAll,
  onAddAvailability,
  onDelete,
  onChange,
  onSave,
  data,
}) {
  return (
    <div className="tv-default-availablity__container">
      <div className="tv-default-availablity__title">
        {Translate({ content: 'defaultAvailablity.title' })}
      </div>
      <p className="tv-default-availablity__content">
        Här hanterar du din Schemalagda tillgänglighet. Schemalagd
        tillgänglighet passar dig som arbetar i större utsträckning för
        Tolkförmedling Väst.
      </p>
      <p className="tv-default-availablity__content">
        Du lägger in den tid du önskar arbetar en eller flera dagar i veckan,
        alltså den tid som du är tillgänglig för uppdrag. Exempelvis mån-fre
        07:00-18:00. Den tid du lägger in här gäller sedan tillsvidare som din
        Schemalagda tillgänglighet.
      </p>
      <p className="tv-default-availablity__content">
        Kortare pass där du är upptagen hanterar du sedan i 
        <a href='/' onClick={() => localStorage.setItem('manageAvailability', true)}> Tillgänglig/Upptagen.</a>
      </p>
      <div className="tv-default-availablity__wrapper">
        <div className="tv-default-availablity__title-2">
          {Translate({ content: 'defaultAvailablity.question' })}
        </div>
        <div className="tv-default-availablity__time-slots">
          <div className="tv-default-availablity__label">
            <span className="tv-default-availablity__label-from">
              {Translate({ content: 'defaultAvailablity.from' })}
            </span>
            <span className="tv-default-availablity__label-to">
              {Translate({ content: 'defaultAvailablity.to' })}
            </span>
          </div>

          {data.map(item => (
            <AvailablitySlot
              key={item.keyId}
              onChange={onChange}
              onDelete={day => onDelete(day, item.SettingIdentifier)}
              onAddAvailability={onAddAvailability}
              dayName={item.name}
              slotStart={item.startTime}
              slotEnd={item.endTime}
              showCopyTimeToAll={item.showCopyTimeToAll}
              onCopyTimeToAll={onCopyTimeToAll}
              text={item.text}
              state={item.state}
            />
          ))}
        </div>
      </div>
      <div className="tv-default-availablity__button-wrapper">
        <Button
          onClick={onSave}
          label={Translate({ content: 'defaultAvailablity.save' })}
          className="tv-default-availablity__save"
        />
      </div>
    </div>
  );
}

DefaultAvailablity.propTypes = {
  onCopyTimeToAll: PropTypes.func,
  onAddAvailability: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};
DefaultAvailablity.defaultProps = {
  onCopyTimeToAll: () => {},
  onAddAvailability: () => {},
  onDelete: () => {},
  onChange: () => {},
  onSave: () => {},
  data: [],
};

export default DefaultAvailablity;
