/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { currentDate, dayJsDuration } from 'utils/dateTimeUtils';

function convertToMin(expireTime) {
  if (!expireTime) return null;

  return dayJsDuration(expireTime.diff(currentDate()));
}
function SidebarAssignmentListItem({
  height,
  yCoordinate,
  diffInMinutes,
  zIndex,
  detail,
  onClick,
  index,
  fromWeekItem,
}) {
  const timeRangeString = `${detail.timeFrom.format(
    'HH:mm',
  )} - ${detail.timeTo.format('HH:mm')}`;
  const timeExpiryRef = useRef(null);
  const assignmentElemetRef = useRef(null);
  useEffect(() => {
    let timeExpiryInterval = null;
    const timeExpiryElement = timeExpiryRef.current;
    const assignmentElement = assignmentElemetRef.current;
    const initialInterval = convertToMin(detail.expiryTime);
    if (initialInterval && initialInterval.asSeconds() <= 0) {
      assignmentElement.style.display = 'none';
    }
    if (detail.expiryTime && timeExpiryElement) {
      timeExpiryElement.innerText = initialInterval.format('HH:mm:ss');

      timeExpiryInterval = setInterval(() => {
        const newInterval = convertToMin(detail.expiryTime);
        if (timeExpiryRef?.current?.innerText) {
          timeExpiryRef.current.innerText = newInterval.format('HH:mm:ss');
        }
        if (newInterval.asMinutes() <= 0) {
          assignmentElement.style.display = 'none';
        }
      }, 1000);
    }
    return () => {
      clearInterval(timeExpiryInterval);
    };
  }, []);

  return (
    <div
      style={{
        height,
        top: yCoordinate,
        padding: diffInMinutes <= 15 && '5px 8px',
        zIndex,
      }}
      className={`tv-sidebar-assignment-list-item__container tv-sidebar-assignment-list-item__container--${detail.status}`}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={fromWeekItem ? -1 : 0}
      ref={assignmentElemetRef}
    >
      <div className="tv-sidebar-assignment-list-item__header">
        <div className="tv-sidebar-assignment-list-item__header__name">
          {detail.client}
        </div>
        {diffInMinutes <= 15 && detail.status !== 'inquiry' && (
          <div className="tv-sidebar-assignment-list-item__header__time">
            {timeRangeString}
          </div>
        )}
        {detail.status === 'inquiry' && (
          <div
            className="tv-sidebar-assignment-list-item__header__counter-time"
            ref={timeExpiryRef}
          >
            00:00
          </div>
        )}
      </div>
      {diffInMinutes > 15 && (
        <div className="tv-sidebar-assignment-list-item__sub-container">
          <div className="tv-sidebar-assignment-list-item__sub-item">
            <i className="material-icons tv-sidebar-assignment-list-item__sub-item__icon">
              watch_later
            </i>
            <span className="tv-sidebar-assignment-list-item__sub-item__value--text">
              {timeRangeString}
            </span>
          </div>
          {detail.status !== 'inquiry' ? (
            <div className="tv-sidebar-assignment-list-item__sub-item">
              <i className="material-icons tv-sidebar-assignment-list-item__sub-item__icon">
                person
              </i>
              <span className="tv-sidebar-assignment-list-item__sub-item__value--text">
                {detail.contactPerson}
              </span>
            </div>
          ) : (
            <div className="tv-sidebar-assignment-list-item__sub-item">
              <i className="material-icons tv-sidebar-assignment-list-item__sub-item__icon">
                payment
              </i>
              <span className="tv-sidebar-assignment-list-item__sub-item__value--text">
                {`${detail.payment}kr`}
              </span>
            </div>
          )}

          {diffInMinutes >= 45 && (
            <>
              <div className="tv-sidebar-assignment-list-item__sub-item">
                <i className="material-icons tv-sidebar-assignment-list-item__sub-item__icon">
                  {detail.typeIcon}
                </i>
                <span
                  className="tv-sidebar-assignment-list-item__sub-item__value--text"
                  style={
                    detail.contactDetailHighlight
                      ? {
                          textDecoration: 'underline',
                          textTransform: 'lowercase',
                        }
                      : {}
                  }
                >
                  {detail.contactDetail}
                </span>
              </div>
              <div className="tv-sidebar-assignment-list-item__sub-item">
                <i className="material-icons tv-sidebar-assignment-list-item__sub-item__icon">
                  work
                </i>
                <span className="tv-sidebar-assignment-list-item__sub-item__value--text">
                  {detail.status}
                </span>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

SidebarAssignmentListItem.propTypes = {
  height: PropTypes.string,
  yCoordinate: PropTypes.string,
  diffInMinutes: PropTypes.number,
  detail: PropTypes.shape({}),
  onClick: PropTypes.func,
  zIndex: PropTypes.number,
  fromWeekItem: PropTypes.bool,
};

SidebarAssignmentListItem.defaultProps = {
  height: '0px',
  yCoordinate: '0px',
  zIndex: 2,
  diffInMinutes: 0,
  detail: null,
  onClick: () => {},
  fromWeekItem: false,
};

export default SidebarAssignmentListItem;
