import React from 'react';
import PropTypes from 'prop-types';
import { TranslationListPageContainer } from 'containers';

import './style.scss';

const TranslationListPage = props => (
  <div className="tv-assignment-list__container">
    <div className="tv-assignment-list__wrapper">
      <TranslationListPageContainer />
    </div>
  </div>
);

TranslationListPage.propTypes = {};

export default TranslationListPage;
