import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Checkbox } from 'components';
import { modalTypes } from 'constant';

import './style.scss';

const AssignmentRequestModal = ({
  isVisible,
  onOverlayClick,
  onClick,
  onClose,
  title,
  modalType,
  children,
  acceptanceButtonLabel,
  rejectButtonLabel,
}) => (
  <Modal
    className="tv-assignment-request-modal__container"
    hideHeader
    onClose={onClose}
    onOverlayClick={onOverlayClick}
    isVisible={isVisible}
  >
    <div className="tv-assignment-request-modal__title">{title}</div>
    {children}
    <div className="tv-assignment-request-modal__actions">
      <Button
        onClick={onClose}
        className="tv-assignment-request-modal__button"
        palette="outline"
        label={rejectButtonLabel}
      />
      <Button
        onClick={() => onClick(modalType)}
        className="tv-assignment-request-modal__button"
        label={acceptanceButtonLabel}
      />
    </div>
  </Modal>
);

AssignmentRequestModal.propTypes = {
  isVisible: PropTypes.bool,
  onOverlayClick: PropTypes.func,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  modalType: PropTypes.string,
  title: PropTypes.string,
  acceptanceButtonLabel: PropTypes.string,
  rejectButtonLabel: PropTypes.string,
  children: PropTypes.node,
};
AssignmentRequestModal.defaultProps = {
  isVisible: false,
  title: 'Godkänn allmänna villkor',
  modalType: modalTypes.APPROVE,
  children: (
    <p>
      Genom att acceptera uppdraget godkänner du våra allmänna villkor och
      riktlinjer för tolkuppdrag
    </p>
  ),
  acceptanceButtonLabel: 'Acceptera uppdraget',
  rejectButtonLabel: 'Avbryt',
  onOverlayClick: () => {},
  onClose: () => {},
  onClick: () => {},
};

export default AssignmentRequestModal;
