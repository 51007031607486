import React, { useRef, useEffect } from 'react';
import './style.scss';
import PropTypes from 'prop-types';

const SkipLinks = ({
  firstLinkFocused,
  secondLinkFocused,
  handleEnterKeyPress,
  handleTopNavigation,
  handleMainContentNavigation,
}) => {
  const firstLinkRef = useRef(null);
  const secondLinkRef = useRef(null);

  useEffect(() => {
    if (firstLinkFocused) {
      firstLinkRef.current.focus();
    } else if (secondLinkFocused) {
      secondLinkRef.current.focus();
    }
  }, [firstLinkFocused, secondLinkFocused]);

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (firstLinkFocused) {
        handleEnterKeyPress();
        handleTopNavigation(true);
        handleMainContentNavigation(false);
      } else if (secondLinkFocused) {
        handleEnterKeyPress();
        handleTopNavigation(false);
        handleMainContentNavigation(true);
      }
    }
  };

  const handleEnterPress = event => {
    if (firstLinkFocused) {
      handleEnterKeyPress();
    } else if (secondLinkFocused) {
      handleEnterKeyPress();
    }
  };

  return (
    <div className="skip-links-container">
      <button
        ref={firstLinkRef}
        onClick={handleEnterPress}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        className="skip-link"
        type="button"
      >
        Gå till navigering
      </button>
      <button
        ref={secondLinkRef}
        onClick={handleEnterPress}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        className="skip-link"
        type="button"
      >
        Gå till huvudinnehåll
      </button>
    </div>
  );
};

SkipLinks.propTypes = {
  firstLinkFocused: PropTypes.bool,
  secondLinkFocused: PropTypes.bool,
  handleEnterKeyPress: PropTypes.func,
  handleTopNavigation: PropTypes.func,
  handleMainContentNavigation: PropTypes.func,
};

SkipLinks.defaultProps = {
  firstLinkFocused: false,
  secondLinkFocused: false,
  handleEnterKeyPress: () => {},
  handleTopNavigation: () => {},
  handleMainContentNavigation: () => {},
};

export default SkipLinks;
