import { assignmentsListViews } from 'constant';
import dayjs from 'dayjs';

export const initialState = {};

export const initialResourceState = {
  calendar: {
    startDate: dayjs().startOf(assignmentsListViews.MONTH.name),
    endDate: dayjs()
      .endOf(assignmentsListViews.MONTH.name)
      .add(1, assignmentsListViews.MONTH.name),
    viewFormat: assignmentsListViews.MONTH.name,
  },
  assignmentList: {
    startDate: dayjs().subtract(2, 'weeks').startOf('day'),
    endDate: dayjs().add(2, 'weeks').endOf('day'),
    searchText: '',
  },
  hiddenAssignment: null,
};

export const getResourceState = (state = initialResourceState) => state;

export const getWorkAssignmentResources = (state, resource = 'calendar') =>
  getResourceState(state)[resource] || initialResourceState[resource];

export const getWorkAssignmentResourcesDetail = (state, resource, detail) =>
  getWorkAssignmentResources(state, resource)[detail];

export const getWorkAssignmentResourcesDateRange = (state, resource) => ({
  start: getWorkAssignmentResources(state, resource).startDate,
  end: getWorkAssignmentResources(state, resource).endDate,
});

export const getHiddenAssignment = (state = initialResourceState) =>
  state.hiddenAssignment;
