/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import('./style.scss');

const Checkbox = ({
  text,
  onClick,
  className,
  controlClassName,
  labelClassName,
  disabled,
  checked,
  inputName,
  ariaLabel,
}) => (
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  <label
    htmlFor={inputName}
    className={`tv-checkbox-container ${className} ${
      disabled && 'tv-checkbox-container--disabled'
    }`}
    onClick={onClick}
  >
    <input
      name={inputName}
      disabled={disabled}
      type="checkbox"
      checked={checked}
      onChange={() => {}}
      aria-label={ariaLabel}
      onKeyDown={e => (e.key === ' ' || e.key === 'Enter' ? onClick(e) : null)}
    />
    <span
      className={`fa tv-checkbox-checkmark tv-checkbox__control ${controlClassName}`}
    />
    <div className={`tv-checkbox__label ${labelClassName}`}>{text}</div>
  </label>
);
Checkbox.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  controlClassName: PropTypes.string,
  inputName: PropTypes.string,
  ariaLabel: PropTypes.string,
};

Checkbox.defaultProps = {
  text: '',
  onClick: () => {},
  disabled: false,
  checked: false,
  className: '',
  labelClassName: '',
  controlClassName: '',
  inputName: '',
  ariaLabel: 'checkbox',
};

export default Checkbox;
