/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  useCalendar,
  filterEventsForTheDateRange,
  filterAvailabilityPerDay,
  extractSemesterDay,
  filterMessageAndTranslationEventsForTheDateRange,
} from 'context/CalendarContext';
import { WeekDay, CalendarTimeLine } from 'components';
import './style.scss';
import { currentDate } from 'utils/dateTimeUtils';
import { isIE } from 'utils/browserUtils';
import { scrollElementTop, truncateTextName } from 'utils/miscUtil';

export default function WeekCalendar() {
  const {
    calendarDateRange: week,
    list,
    assignmentRequestList,
    onWorkAssignmentClick,
    onAvailabilityExceptionClick,
    onActiveSemesterAllocationClick,
    isAvailabilityFilterApplied,
    selectedCalendarType,
    activeAssignment,
    availabilityList,
    semesterAllocationList,
    onMessageServiceClick,
  } = useCalendar();

  const [workAssignmentsList, setworkAssignmentsList] = useState([]);

  const weekCalendarRef = useRef();
  let autoScrollRef = useRef(null);

  useEffect(() => {
    setworkAssignmentsList([...(list || []), ...(assignmentRequestList || [])]);
  }, [list, assignmentRequestList]);

  useEffect(() => {
    const weekCalendarElement = weekCalendarRef?.current;
    if (activeAssignment?.OrderNumber) {
      autoScrollRef = setTimeout(() => {
        const assignmentElement = weekCalendarElement.querySelector(
          `div[data-assignment-id='${activeAssignment.OrderNumber}']`,
        );
        scrollElementTop(
          assignmentElement,
          assignmentElement?.parentElement?.parentElement,
        );
      }, 500);
    } else if (weekCalendarElement) {
      const assignmentElement = weekCalendarElement.querySelector(
        '.tv-week-view-assignment-item__container',
      );
      const currentTimeElement = weekCalendarElement.querySelector(
        '.tv-calendar-timeline__curent-time',
      );
      autoScrollRef = setTimeout(() => {
        if (currentTimeElement) {
          scrollElementTop(
            currentTimeElement,
            currentTimeElement?.parentElement?.parentElement,
          );
        } else if (assignmentElement) {
          scrollElementTop(
            assignmentElement,
            assignmentElement?.parentElement?.parentElement,
          );
        }
      }, 500);
    }
    return () => {
      clearTimeout(autoScrollRef);
    };
  }, [week]);

  const shorterTextName = (fname, sname) => {
    const EXPECT_LENGTH = 18;
    return truncateTextName(fname, sname, EXPECT_LENGTH);
  };

  return (
    <div className="tv-week-calendar__container container-wrapper" ref={weekCalendarRef}>
      <DaysOfWeekHeader week={week} />
      <MessageAndTranslationOrdersHeader
        week={week}
        list={workAssignmentsList}
        onWorkAssignmentClick={onWorkAssignmentClick}
        shorterTextName={shorterTextName}
      />

      <div className="tv-week-calendar__weekdays">
        <CalendarTimeLine />
        {[...Array(7).keys()].map(colIndex => {
          const date = week.start.add(colIndex, 'day');

          return (
            <WeekDay
              key={date.format('[weekday-]YYYYMMDD')}
              eventsForDay={filterEventsForTheDateRange(
                workAssignmentsList,
                date.startOf('day'),
                date.endOf('day'),
              )}
              isAvailabilityFilterApplied={isAvailabilityFilterApplied}
              onAvailabilityExceptionClick={onAvailabilityExceptionClick}
              onActiveSemesterAllocationClick={onActiveSemesterAllocationClick}
              selectedCalendarType={selectedCalendarType}
              activeAssignment={activeAssignment}
              onWorkAssignmentClick={onWorkAssignmentClick}
              availabilityList={filterAvailabilityPerDay(
                availabilityList,
                date,
              )}
              date={date}
              semesterDay={extractSemesterDay(semesterAllocationList, date)}
            />
          );
        })}
      </div>
    </div>
  );
}

function DaysOfWeekHeader({ week }) {
  return (
    <div
      className={`tv-week-calendar__header ${
        isIE ? 'tv-week-calendar__header-ie-fix' : null
      }`}
    >
      <div className="tv-week-calendar-filling" />
      {[...Array(7).keys()].map(colIndex => {
        const date = week.start.add(colIndex, 'day');
        const dateString = date.locale('sv').format('ddd DD');
        return (
          <div
            className={`tv-week-calendar__header-item ${
              currentDate().isSame(date, 'day') &&
              'tv-week-calendar__header-item--today'
            }`}
            key={`week-calender-${dateString}`}
          >
            {dateString}
          </div>
        );
      })}
    </div>
  );
}

function MessageAndTranslationOrdersHeader({
  week,
  list,
  onWorkAssignmentClick,
  shorterTextName,
}) {
  return (
    <div
      className={`tv-week-calendar__message-header ${
        isIE ? 'tv-week-calendar__header-ie-fix' : null
      }`}
    >
      <div className="tv-week-calendar-filling">
        <i className="material-icons messeage-service__main-icon">chat</i>
      </div>

      {[...Array(7).keys()].map(colIndex => {
        const date = week.start.add(colIndex, 'day');
        const dateString = date.locale('sv').format('ddd DD');

        const events = filterMessageAndTranslationEventsForTheDateRange(
          list,
          date.startOf('day'),
          date.endOf('day'),
        );

        return (
          <>
            <div
              className={`tv-week-calendar__header-item ${currentDate().isSame(
                date,
                'day',
              )}`}
              key={`week-calender-${dateString}`}
            >
              {events.map(i => (
                <div
                  role="button"
                  onKeyDown={e =>
                    e.key === 'Enter' ? onWorkAssignmentClick(i) : null
                  }
                  tabIndex="0"
                  className={`tv-week-calendar__message-service-item tv-week-calendar__message-service-item-${i.WorkAssignmentConsolidatedStatusName}`}
                  onClick={() => onWorkAssignmentClick(i)}
                >
                  <i className="material-icons messeage-service-icon mr-1">
                    {i.IsMessageService === true && 'chat'}
                    {i.IsTranslation === true && 'translate'}
                  </i>
                  <div className="tv-week-calendar__message-service-item__single">
                    {shorterTextName(
                      i.OrderNumber,
                      i.ContactPerson.ContactPersonName,
                    )}
                  </div>
                  <span
                    className={`tooltiptext tooltiptext-${i.WorkAssignmentConsolidatedStatusName}`}
                  >
                    {i.WorkAssignmentConsolidatedStatusName}
                  </span>
                </div>
              ))}
            </div>
          </>
        );
      })}
    </div>
  );
}
