import { put, call, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';

export function* createResource(api, { params }, { resource, thunk }) {
  try {
    const detail = yield call([api, api.post], `${resource}`, params);
    yield put(
      actions.resourceCreateSuccess(resource, detail, { data: params }, thunk),
    );
  } catch (e) {
    yield put(
      actions.resourceCreateFailure(resource, e, { data: params }, thunk),
    );
  }
}

export function* readResourceList(
  api,
  { params },
  { resource, resourceKey, thunk, isRefresh },
) {
  try {
    const response = yield call([api, api.post], `${resource}`, params);

    yield put(
      actions.resourceListReadSuccess(
        resource,
        resourceKey,
        response?.data,
        { params },
        thunk,
        isRefresh,
      ),
    );
  } catch (e) {
    yield put(actions.resourceListReadFailure(resource, e, { params }, thunk));
  }
}

export function* readResourceDetail(api, { params }, { resource, thunk }) {
  try {
    const response = yield call([api, api.post], `${resource}`, params);
    yield put(
      actions.resourceDetailReadSuccess(
        resource,
        response?.data,
        { data: params },
        thunk,
      ),
    );
  } catch (e) {
    yield put(
      actions.resourceDetailReadFailure(resource, e, { data: params }, thunk),
    );
  }
}

export function* updateResource(api, { needle, data }, { resource, thunk }) {
  try {
    const detail = yield call([api, api.put], `/${resource}/${needle}`, data);
    yield put(
      actions.resourceUpdateSuccess(resource, detail, { needle, data }, thunk),
    );
  } catch (e) {
    yield put(
      actions.resourceUpdateFailure(resource, e, { needle, data }, thunk),
    );
  }
}

export function* deleteResource(api, { params }, { resource, thunk }) {
  try {
    yield call([api, api.post], `${resource}`, params);
    yield put(actions.resourceDeleteSuccess(resource, { data: params }, thunk));
  } catch (e) {
    yield put(
      actions.resourceDeleteFailure(resource, e, { data: params }, thunk),
    );
  }
}

export function* createResourceAutoAssignOrderAvailability(
  api,
  { params },
  { resource, thunk },
) {
  try {
    const detail = yield call([api, api.post], `${resource}`, params);
    yield put(
      actions.resourceAutoAssignOrderAvailabilitySuccess(
        resource,
        { data: params },
        detail,
        thunk,
      ),
    );
  } catch (e) {
    yield put(
      actions.resourceAutoAssignOrderAvailabilityFailure(
        resource,
        { data: params },
        thunk,
      ),
    );
  }
}

export function* DeleteResourceAutoAssignOrderAvailability(
  api,
  { params },
  { resource, thunk },
) {
  try {
    const detail = yield call([api, api.post], `${resource}`, params);
    yield put(
      actions.resourceAutoAssignOrderAvailabilityDeleteRequestSuccess(
        resource,
        { data: params },
        detail,
        thunk,
      ),
    );
  } catch (e) {
    yield put(
      actions.resourceAutoAssignOrderAvailabilityDeleteRequestFailure(
        resource,
        { data: params },
        thunk,
      ),
    );
  }
}

export function* watchResourceCreateRequest(api, { payload, meta }) {
  yield call(createResource, api, payload, meta);
}

export function* watchResourceListReadRequest(api, { payload, meta }) {
  yield call(readResourceList, api, payload, meta);
}

export function* watchResourceDetailReadRequest(api, { payload, meta }) {
  yield call(readResourceDetail, api, payload, meta);
}

export function* watchResourceUpdateRequest(api, { payload, meta }) {
  yield call(updateResource, api, payload, meta);
}

export function* watchResourceDeleteRequest(api, { payload, meta }) {
  yield call(deleteResource, api, payload, meta);
}

export function* watchResourceAutoAssignOrderAvailabilityRequest(
  api,
  { payload, meta },
) {
  yield call(createResourceAutoAssignOrderAvailability, api, payload, meta);
}

export function* watchResourceAutoAssignOrderAvailabilityDeleteRequest(
  api,
  { payload, meta },
) {
  yield call(DeleteResourceAutoAssignOrderAvailability, api, payload, meta);
}

export default function* ({ api }) {
  yield takeEvery(
    actions.RESOURCE_CREATE_REQUEST,
    watchResourceCreateRequest,
    api,
  );
  yield takeEvery(
    actions.RESOURCE_LIST_READ_REQUEST,
    watchResourceListReadRequest,
    api,
  );
  yield takeEvery(
    actions.RESOURCE_DETAIL_READ_REQUEST,
    watchResourceDetailReadRequest,
    api,
  );
  yield takeEvery(
    actions.RESOURCE_UPDATE_REQUEST,
    watchResourceUpdateRequest,
    api,
  );
  yield takeEvery(
    actions.RESOURCE_DELETE_REQUEST,
    watchResourceDeleteRequest,
    api,
  );
  yield takeEvery(
    actions.RESOURCE_ORDER_AVAILABILITY_REQUEST,
    watchResourceAutoAssignOrderAvailabilityRequest,
    api,
  );
  yield takeEvery(
    actions.RESOURCE_ORDER_AVAILABILITY_DELETE_REQUEST,
    watchResourceAutoAssignOrderAvailabilityDeleteRequest,
    api,
  );
}
