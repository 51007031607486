/* eslint-disable no-console */

const tokenMiddleware = store => next => action => {
  const { meta, payload } = action;

  if (meta && meta.tokenRequired) {
    const state = store.getState();
    const returnAction = {
      ...action,
      payload: {
        ...payload,
        params: { ...payload.params, Token: state.authenticate.token },
      },
    };
    return next(returnAction);
  }
  return next(action);
};

export default tokenMiddleware;
