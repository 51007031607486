/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'components';
import { parseHourMinuteField } from 'utils/dateTimeUtils';
import { isNumFieldValidate } from 'utils/miscUtil';

function HoursMinutesInput({ onBlur, value, name, className }) {
  const [stateValue, setStateValue] = useState(value);
  useEffect(() => {
    setStateValue(value);
    return () => {
      setStateValue(undefined);
    };
  }, [value]);
  return (
    <>
      <input
        label=""
        type="text"
        name={name}
        autoComplete="off"
        maxLength="5"
        minLength="5"
        placeholder="00:00(tim:min)"
        defaultValue={undefined}
        value={stateValue}
        onChange={event => {
          setStateValue(event?.target?.value.replace(/[^0-9.:]/g, ''));
        }}
        onBlur={event => {
          const logvalue = parseHourMinuteField(event?.target?.value || '');
          onBlur(logvalue);
        }}
        onKeyPress={isNumFieldValidate}
        className={`tv-field__input ${className}`}
      />
    </>
  );
}

HoursMinutesInput.propTypes = {};

export default HoursMinutesInput;
